/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 29/06/2023
 * @descriptionThe `UserContact` component is a form used to manage user contact information. It includes fields for data type, data value, and description. Users can input or edit this information, and upon submission, the data is processed through the `onSubmit` function. The form supports validation for required fields and offers a select input for data type selection. Additionally, there is an option to save the changes made in the form.
 */
import FormInput from "../FormInput/FormInput";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { dataTypesConst } from "../../consts/dataTypes.const";
import { dirtyValuesUtil } from "../../utils/dirtyValues.util";

export default function UserContact ({ data, readOnly = false, onSubmit }) {
    const {
        control,
        formState: { errors, dirtyFields },
        handleSubmit
    } = useForm({
        defaultValues: data
    });
    const { t } = useTranslation();


    const onHandleSubmit = (dataForm) => {
        let dataToSubmit = dirtyValuesUtil(dirtyFields, dataForm)
        //if we have file we need to transform it to base64
        onSubmit(dataToSubmit)
    }

    return <form onSubmit={handleSubmit(onHandleSubmit)}>
        <fieldset>
            <legend>{t("Basic data")}</legend>

            <div className={"py-4"}>
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="type" label={"Data type"} control={control}
                                   errors={errors}
                                   rules={{ required: true }} options={dataTypesConst} type={"select"}
                        />
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="value" label={"Data"} control={control} errors={errors}
                                   rules={{ required: true }}/>
                    </div>

                    <div className="col-12">
                        <FormInput readOnly={readOnly} name="description" label={"Description"} control={control}
                                   errors={errors}
                                   rules={{ required: true }} type={"textarea"}/>
                    </div>


                </div>
            </div>


        </fieldset>

        {!readOnly && <div className={"d-flex justify-content-end mt-8"}>
            <Button label={t("Save")}/>
        </div>}
    </form>
}
