/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 31/05/2023
 * @description The `InsuranceFile` component is responsible for managing files related to insurance. It allows users to edit file details such as branch, data type, and title, as well as attach PDF files. Users can submit changes, and the component handles the transformation of file data to base64 format if needed. The form includes validation rules for required fields. The component fetches policy types from an API endpoint and renders them as options in a select input field. Additionally, it utilizes the `FilePondImageEditor` component to handle file uploads. The form submission button is displayed only if the component is not in read-only mode.
 */
import FormInput from "../FormInput/FormInput";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {dirtyValuesUtil} from "../../utils/dirtyValues.util";
import {useEffect, useState} from "react";
import {API} from "../../queries/api";
import {checkAndTransformDirtyFileUtil} from "../../utils/checkAndTransformDirtyFile.util";
import {FilePondImageEditor} from "../FilePondImageEditor/FilePondImageEditor";
import {generalConditionsConst} from "../../consts/generalConditions.const";

const fileKey = "fileLocation"

export default function InsuranceFile({data, readOnly = false, onSubmit}) {
    const [files, setFiles] = useState(data[fileKey])

    const {
        control,
        formState: {errors, dirtyFields},
        handleSubmit,
    } = useForm(data && Object.keys(data).length !== 0 ? {
        defaultValues: {
            ...data,
            policyTypeId: data.policyTypeId.toString()
        }
    } : {});
    const [policyTypes, setPolicyTypes] = useState()

    const {t} = useTranslation();

    const onHandleSubmit = (dataForm) => {
        let dataToSubmit = dirtyValuesUtil(dirtyFields, dataForm)
        dataToSubmit = checkAndTransformDirtyFileUtil(dataForm[fileKey], files, dataToSubmit, fileKey);
        //if we have file we need to transform it to base64
        onSubmit(dataToSubmit.policyTypeId ? {
            ...dataToSubmit,
            policyTypeId: Number(dataToSubmit.policyTypeId)
        } : dataToSubmit)

    }


    useEffect(() => {
        async function getPolicyTypes() {
            try {
                const {data} = await API("policy/policy-type/list");
                setPolicyTypes(data.map(item => ({label: item.name, value: item.id.toString()})))
            } catch (e) {
                console.log(e)
            }
        }

        getPolicyTypes();
    }, []);


    return <form onSubmit={handleSubmit(onHandleSubmit)}>
        <fieldset>
            <legend>{t("File edition")}</legend>

            <div className={"py-4"}>
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="policyTypeId" label={"Branch"} control={control}
                                   errors={errors}
                                   rules={{required: true}} options={policyTypes} type={"select"}
                        />
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="type" label={"Data type"} control={control}
                                   errors={errors}
                                   rules={{required: true}} options={generalConditionsConst} type={"select"}
                        />
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="title" label={"Title"} control={control} errors={errors}
                                   rules={{required: true}}/>
                    </div>
                </div>
            </div>


        </fieldset>

        <fieldset>
            <legend>{t("Attach PDF")}</legend>

            <div className={"py-4"}>
                <div className="row">
                    <div className="col-12">
                        <FilePondImageEditor files={files} setFiles={setFiles} fileKey={fileKey} readOnly={readOnly}
                                             acceptedFileTypes={["application/pdf"]}/>
                    </div>
                </div>
            </div>
        </fieldset>

        {!readOnly && <div className={"d-flex justify-content-end mt-8"}>
            <Button label={t("Save")}/>
        </div>}
    </form>
}
