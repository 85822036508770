/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 29/09/2023
 * @description The `ProposalObjectiveStudy` component is used to handle the objective study section of a proposal. Users can upload files, provide comments, and save their input. If files are uploaded, they are transformed to base64 format before submission. Upon submission, the proposal is updated, and the status is set to "OFFER_PENDING". The component utilizes form validation and internationalization (i18n) for labels.
 */
import {FilePondImageEditor} from "../FilePondImageEditor/FilePondImageEditor";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {dirtyValuesUtil} from "../../utils/dirtyValues.util";
import {checkAndTransformDirtyFileUtil} from "../../utils/checkAndTransformDirtyFile.util";
import FormInput from "../FormInput/FormInput";
import {Button} from "primereact/button";
import {API} from "../../queries/api";
import {toast} from "react-toastify";
import ToastContent from "../ToastContent/ToastContent";

const fileKey = "fileLocation"
export default function ProposalObjectiveStudy({data, proposal, readOnly, setProposal}) {
    const [files, setFiles] = useState(data ? data[fileKey] : null)

    const {
        control,
        formState: {errors, dirtyFields},
        handleSubmit,
    } = useForm({
        defaultValues: {
            ...data
        }
    });

    const onHandleSubmit = async (dataForm) => {
        let dataToSubmit = dirtyValuesUtil(dirtyFields, dataForm)
        //if we have file we need to transform it to base64
        dataToSubmit = checkAndTransformDirtyFileUtil(dataForm[fileKey], files, dataToSubmit, fileKey, "uploadFiles", true);
        await onSubmitProposalObjectiveStudy(dataToSubmit)
    }


    const onSubmitProposalObjectiveStudy = async (dataForm) => {
        try {
            await API.put(`policy/proposal/update/${proposal.id}`, dataForm);
            setProposal({...proposal, status: "OFFER_PENDING"})
            toast.info(<ToastContent title={t("Proposal updated successfully")}
            />)
        } catch (e) {
            console.error(e)
        }

    }

    const {t} = useTranslation()
    return <form onSubmit={handleSubmit(onHandleSubmit)}>
        <fieldset>
            <legend>{t("Basic data")}</legend>

            <div className={"py-4"}>
                <div className="row">
                    <div className="col-12">
                        <FilePondImageEditor files={files} setFiles={setFiles} fileKey={fileKey} readOnly={readOnly}
                                             acceptedFileTypes={["application/pdf"]}/>
                    </div>
                    <div className="col-12">
                        <FormInput readOnly={readOnly} name="comment" label={"Comments"} control={control}
                                   errors={errors} type={"textarea"} rules={{required: true}}/>
                    </div>
                </div>
            </div>
        </fieldset>


        {!readOnly && <div className={"d-flex justify-content-end mt-8"}>
            <Button label={t("Save")}/>
        </div>}
    </form>
}
