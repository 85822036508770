import {Dropdown} from "primereact/dropdown";
import React from "react";
import {useTranslation} from "react-i18next";

export default function FilterDropdownTemplate({filter, options}) {
    const {t} = useTranslation();
    return <Dropdown value={filter && filter.value ? filter.value : null} options={options}
                     onChange={(e) => {
                         filter.filterApplyCallback(e.value)
                     }}
                     placeholder={t("Select")}
                     itemTemplate={(option)=> t(option.label)}
                     valueTemplate={(option)=> option ? t(option.label) : t("Select")}
                     className="p-column-filter" showClear/>
}
