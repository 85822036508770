/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 10/05/2023
 * @description The `InsuranceCustomerServicesTable` component manages a table of customer services related to insurance. It allows users to view, edit, and remove customer service entries. The table is paginated and supports filtering and sorting. Users can create new customer service entries through a button in the table header. The component fetches data from an API based on lazy loading for better performance. Each row in the table displays the ID, phone number, and email of a customer service entry, along with action buttons for viewing, editing, and removing entries.
 */
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {useTranslation} from "react-i18next";
import ActionsOverlayMenu from "../ActionsOverlayMenu/ActionsOverlayMenu";
import {Link, useNavigate} from "react-router-dom";
import {Button} from "primereact/button";
import {API} from "../../queries/api";
import {deleteTableRowUtil} from "../../utils/deleteTableRow.util";
import {useEffect, useState} from "react";
import {createFilterParamsUtil} from "../../utils/createFilterParams.util";
import {checkAndReturnCorrectLazyTableValues} from "../../utils/checkAndReturnCorrectLazyTableValues.util";

export default function InsuranceCustomerServicesTable({insuranceId}) {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [customerServices, setCustomerServices] = useState([])

    const [lazyTable, setLazyTable] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
    });

    const onLazy = (event) => {
        const newLazyTable = checkAndReturnCorrectLazyTableValues(lazyTable, event);
        setLazyTable(newLazyTable);
        getLazyData(newLazyTable);
    };

    const getLazyData = async (event) => {
        const {data: {data: customerServices, count}} = await API(
            "company/customer-service/list/" + createFilterParamsUtil(event) + "&filter[companyId]=" + insuranceId
        );
        setLazyTable({...event, totalRecords: count})
        setCustomerServices(customerServices.map((d) => ({
            ...d,
            effectiveDate: new Date(d.effectiveDate),
            expirationDate: new Date(d.expirationDate),
        })));
    };

    useEffect(() => {
        getLazyData(lazyTable);
    }, []);


    const InsuranceCustomerServicesActions = (rowData) => {
        const items = [
            {
                label: t("See"),
                icon: <span className="me-4 material-symbols-outlined">visibility</span>,
                command: () => navigate("./customer-service/" + rowData.id)

            }, {
                label: t("Edit"),
                icon: <span className="me-4 material-symbols-outlined">edit</span>,
                command: () => navigate("./customer-service/" + rowData.id + "/edit")
            }, {
                label: t("Remove"),
                icon: <span className="me-4 material-symbols-outlined">delete</span>,
                command: () => deleteTableRowUtil(rowData.id, "company/contact/delete/" + rowData.id, customerServices, setCustomerServices),
            }
        ];
        return <ActionsOverlayMenu items={items}/>
    }

    const tableHeader = () => <div className={"d-flex justify-content-end"}>
        <Link to={"./customer-service/new"}>
            <Button label={t("Create insurance customer")}></Button>
        </Link>
    </div>

    return <div>
        <DataTable    emptyMessage={t("No results found")}
                      header={tableHeader}
                      value={customerServices}
                      filterDisplay="row"
                      dataKey="id"
                      paginator
                      totalRecords={lazyTable.totalRecords}
                      rows={lazyTable.rows}
                      onPage={onLazy}
                      onFilter={onLazy}
                      onSort={onLazy}
                      lazy
                      first={lazyTable.first}
                      sortOrder={lazyTable.sortOrder}>>
            <Column field="id"  filter filterMatchMode="contains" filterPlaceholder={t("Search")}
                    header={t("ID")}/>
            <Column field="phone"  filter filterMatchMode="contains" filterPlaceholder={t("Search")}
                    header={t("Phone")} />
            <Column field="email"  filter filterMatchMode="contains" filterPlaceholder={t("Search")}
                    header={t("Email")}/>


            <Column header={t("Actions")} body={InsuranceCustomerServicesActions}/>
        </DataTable>
    </div>
}
