
/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 03/11/2023
 * @description This `AddressSearch` component is designed to allow users to search for addresses based on different criteria, such as town or road name. Here's a breakdown of its functionality:
 *
 * - It receives props like `type`, `value`, `onChange`, `readOnly`, `disabled`, `provinceId`, `roadNumber`, `label`, and `municipalityId`.
 * - The component maintains state for showing text, showing list of search results, and the selected address.
 * - The `onSearch` function is triggered when the user types in the search input. It fetches data based on the search criteria and updates the showing list accordingly.
 * - The `selectItem` function is called when a user selects an item from the search results. It updates the selected address, showing text, and showing list accordingly.
 * - The `useEffect` hook is used to fetch additional details of the selected address when the component mounts.
 * - Finally, the component renders a `CommonSearch` component, passing down relevant props for rendering the search input and list of results.
 *
 * Overall, this component facilitates the search and selection of addresses based on user input, providing a dynamic and interactive user experience.
 */
import {useEffect, useState} from "react";
import {API} from "../../queries/api";
import CommonSearch from "../CommonSearch/CommonSearch";

export default function AddressSearch({
                                          type,
                                          value,
                                          onChange,
                                          readOnly,
                                          disabled,
                                          provinceId,
                                          roadNumber,
                                          label,
                                          municipalityId
                                      }) {
    const [showingText, setShowingText] = useState("");
    const [showingList, setShowingList] = useState([]);
    const [address, setAddress] = useState();

    const onSearch = async (e) => {
        const targetValue = e.target.value;
        setShowingText(targetValue)
        let newData;
        if (targetValue !== "") {
            if (type === "address_town") {
                const {data} = await API(
                    `data/location/municipality/list/${provinceId}?search=` + targetValue
                );
                newData = data;
            } else if (type === "address_road_name") {
                const {data} = await API(
                    `data/location/road/list/${provinceId}/${municipalityId}/${targetValue}`
                );
                newData = data.filter(item => item.type);
            }
            setShowingList(newData)

        } else {
            setShowingList([])
        }
    }


    const selectItem = (item) => {
        onChange(item);
        setAddress({...address, [type]: item.id})
        setShowingText(item.name)
        setShowingList([])
    }


    useEffect(() => {
        async function getDetail() {
            if (value) {
                if (type === "address_town") {
                    const {data} = await API(
                        `data/location/municipality/list/${provinceId}`
                    );
                    const itemFound = data.find(item => item.id === value);
                    if (itemFound) {
                        setShowingText(itemFound.name)
                    }
                } else if (type === "address_road_name") {
                    const {data} = await API(
                        `data/location/address/list/${provinceId}/${municipalityId}/${value}/${roadNumber}`
                    );
                    const roadName = data[0].address.roadName
                    if (roadName) {
                        setShowingText(roadName)
                    }
                }
            }
        }

        getDetail();
    }, [])

    return <CommonSearch showingList={showingList} label={label} onSearch={onSearch}
                         showingText={showingText} selectItem={selectItem}
                         disabled={disabled} readOnly={readOnly}/>

}
