/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 31/05/2023
 * @description The `Policy` component renders a form for managing insurance policies. Users can input basic policy data such as company, policy type, policy number, annual premium, frequency, payment method, reference, effective dates, and attach a PDF document. If the policy is not new, it also allows users to manage risk-related data through tabs. The form supports validation, read-only mode, and submission of data.
 */
import FormInput from "../FormInput/FormInput";
import {Button} from "primereact/button";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {API} from "../../queries/api";
import {paymentFrequencyConst} from "../../consts/paymentFrequency.const";
import {InputNumber} from "primereact/inputnumber";
import {paymentChannelsConst} from "../../consts/paymentChannels.const";
import {referencesConst} from "../../consts/references.const";
import {checkAndTransformDirtyFileUtil} from "../../utils/checkAndTransformDirtyFile.util";
import {dirtyValuesUtil} from "../../utils/dirtyValues.util";
import DynamicData from "../DynamicData/DynamicData";
import {TabPanel, TabView} from "primereact/tabview";
import {transformArrayToObjUtil} from "../../utils/transformArrayToObjUtil";
import {FilePondImageEditor} from "../FilePondImageEditor/FilePondImageEditor";
import {hashFeature} from "../../utils/primereactTab.util";
import {useNavigate} from "react-router-dom";

const fileKey = "fileLocation"

/**
 *
 * @param data
 * @param readOnly used to show field like input or only just show data
 * @returns {JSX.Element}
 * @constructor
 */
export default function Policy({
                                   data, readOnly = false, onSubmit = () => {
    }, isNew
                               }) {
    const [files, setFiles] = useState(data[fileKey])
    const [policyTypes, setPolicyTypes] = useState([])
    const [fractionalPremium, setFractionalPremium] = useState()
    const {t} = useTranslation();
    const {
        control,
        formState: {errors, dirtyFields},
        handleSubmit,
        watch
    } = useForm({
        defaultValues: {
            ...data,
            initialEffectiveDate: data.initialEffectiveDate ? new Date(data.initialEffectiveDate) : new Date(),
            effectiveDate: data.effectiveDate ? new Date(data.effectiveDate) : new Date(),
            expirationDate: data.expirationDate ? new Date(data.expirationDate) : new Date()
        }
    });
    const [activeIndex, setActiveIndex] = useState()
    const navigate = useNavigate()

    const {
        control: controlRisk,
        setValue,
        formState: {errors: errorsRisk},
        handleSubmit: handleSubmitRisk,
        watch: watchRisk,
        reset: resetRisk
    } = useForm(data && data.risks ? {
        defaultValues: {

            ...transformArrayToObjUtil([...data.risks])
        }
    } : {});

    const onHandleSubmit = (dataForm) => {
        handleSubmitRisk(async (dataRisk) => {
                try {
                    //check we have data

                    let dataToSubmit = dirtyValuesUtil(dirtyFields, dataForm)
                    //if we have file we need to transform it to base64
                    dataToSubmit = checkAndTransformDirtyFileUtil(dataForm[fileKey], files, dataToSubmit, fileKey);
                    if (dataToSubmit.companyId) {
                        dataToSubmit.companyId = Number(dataToSubmit.companyId) // Must be numeric
                    }
                    onSubmit(dataToSubmit, dataRisk, watch("policyTypeId"))
                } catch
                    (e) {
                    console.error(e)
                }

            }
        )
        ()

    }


    const calcFractionalPremium = () => {
        const frequency = watch("frequency");
        const annualPayment = watch("annualPayment");
        if (frequency && annualPayment) {
            const fractional = paymentFrequencyConst.find(paymentFrequency => paymentFrequency.value === frequency).fractional;
            setFractionalPremium(annualPayment / fractional)
        }
    }
    const goToProposal = () => {
        navigate("/proposals/" + data.proposalId)
    }

    useEffect(calcFractionalPremium, [watch("annualPayment"), watch("frequency")])

    useEffect(() => {
        async function getPolicyTypes() {
            const {data} = await API("policy/policy-type/list");
            setPolicyTypes(data.map(item => ({label: item.name, value: item.id})))
        }

        getPolicyTypes();
    }, [])

    const resetRiskForm = () => {
        resetRisk(createResetStructure(watchRisk()))
    }

    return <form onSubmit={handleSubmit(onHandleSubmit)}>
        <fieldset>
            <legend>{t("Basic data")}</legend>

            <div className={"py-4"}>
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="companyId" label={"Insurance"} control={control}
                                   errors={errors} type={"search"} service={"company/company"}
                                   rules={{required: true}}/>
                    </div>
                    {data.proposalId && <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="proposalId" label={"Original proposal"}
                                   control={control} type="onClick" onClick={goToProposal}
                                   errors={errors}/>
                    </div>}
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="policyTypeId" label={"Branch"} control={control}
                                   disabled={watch("id")}
                                   errors={errors} type={"select"} options={policyTypes} onChange={resetRiskForm}
                                   rules={{required: true}}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="policyNumber" label={"Policy number"} control={control}
                                   errors={errors}
                                   rules={{required: true}}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="annualPayment" label={"Annual premium (€)"}
                                   control={control} errors={errors} type={"number"}
                                   rules={{required: true}}/>
                    </div>


                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="frequency" label={"Frequency"} control={control}
                                   errors={errors}
                                   rules={{required: true}} type={"select"} options={paymentFrequencyConst}/>
                    </div>


                    <div className="col-12 col-lg-4">
                        <span className="p-float-label">
                             <InputNumber value={fractionalPremium}
                                          disabled={true}
                                          readOnly={readOnly}
                                          useGrouping={false}/>
                             <label>{t("Fractional premium")}</label>
                        </span>

                    </div>
                </div>


                <div className={"row"}>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="paymentMethod" label={"Payment channel"}
                                   control={control}
                                   errors={errors} type={"select"} options={paymentChannelsConst}
                                   rules={{required: true}}/>
                    </div>
                </div>
                <div className={"row"}>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="referenceType" label={"Reference"} control={control}
                                   errors={errors} type={"select"} options={referencesConst}
                                   rules={{required: true}}/>
                    </div>


                    <div className="col-8">
                        <FormInput readOnly={readOnly} name="referenceValue" label={"Reference value"} control={control}
                                   errors={errors}
                                   rules={{required: true}}/>
                    </div>


                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="initialEffectiveDate" label={"Initial effective date"}
                                   control={control} errors={errors} type={"calendar"}
                                   rules={{required: true}}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="effectiveDate" label={"Effective date"} control={control}
                                   errors={errors} type={"calendar"}
                                   rules={{required: true}}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="expirationDate" label={"Expiration"} control={control}
                                   errors={errors} type={"calendar"}
                                   rules={{required: true}}/>
                    </div>
                </div>



                <div className={"row"}>
                    <div className="col-12 col-lg-12">
                        <FilePondImageEditor files={files} setFiles={setFiles} fileKey={fileKey} readOnly={readOnly}
                                             acceptedFileTypes={["application/pdf"]} label={t("Policy PDF")}/>
                    </div>
                </div>


            </div>
        </fieldset>


        {!isNew && <div className="mt-8">
            <TabView {...hashFeature(activeIndex, setActiveIndex)}>
                <TabPanel header={t("RISK")}>
                    <DynamicData control={controlRisk} errors={errorsRisk} readOnly={readOnly}
                                 policyTypeId={watch("policyTypeId")}
                                 formData={watchRisk()} setValue={setValue}/>
                </TabPanel>
            </TabView>
        </div>}


        {!readOnly && <div className={"d-flex justify-content-end mt-8"}>
            <Button label={t("Save")}/>
        </div>}
    </form>

}

const createResetStructure = (formData) => {
    const structure = {};
    for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
            structure[key] = ""
        }
    }
    return structure
}
