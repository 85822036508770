export const createFilterParamsUtil = (event, filterKey="filter", limit = 10) => {
    if (event) {
        let params = `?limit=${limit}&offset=${event.first}`;

        if (event.filters) {
            const filters = event.filters;
            for (const key in filters) {
                if (Object.hasOwnProperty.call(filters, key)) {
                    const element = filters[key];
                    if (element.value) {
                        params += `&${element.matchMode === "equals" ? "filter" : filterKey}[${key}]=${element.value}`
                    }
                }
            }
        }

        return params;
    } else {
        return ""
    }

}
