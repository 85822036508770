/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 18/08/2023
 * @description The `CommonSearch` component provides a search input field with a floating label. It allows users to search for items and displays a list of results beneath the input field. Users can select an item from the list. The component supports customization of various attributes such as ID, class name, disabled state, and read-only state. Additionally, it supports internationalization for the label text.
 */
import {InputText} from "primereact/inputtext";
import "./CommonSearch.scss"
import {useTranslation} from "react-i18next";
import {createShowingText} from "../InlineSearch/InlineSearch";

export default function CommonSearch({
                                         showingList,
                                         id,
                                         onSearch,
                                         className,
                                         disabled,
                                         readOnly,
                                         selectItem,
                                         showingText,
                                         label, rules = {},
                                         keys = ["name"],
                                     }) {
    const {t} = useTranslation();
    return <div className={"c-common-search"}>
          <span className="p-float-label">
              <InputText readOnly={readOnly} id={id} value={showingText}
                         disabled={disabled}
                         className={className}
                         onChange={onSearch}/>
              <span className="c-common-search__icon material-symbols-outlined">search</span>

              <label htmlFor={id}>{t(label)} {rules.required && !readOnly ? "*" : ""}</label>

        </span>
        {showingList && showingList.length > 0 && <ul className={"c-common-search__list"}>
            {showingList.map(showingItem => <li className={"c-common-search__item"}
                                                onClick={() => selectItem(showingItem)}>{createShowingText(showingItem, keys)}</li>)}
        </ul>}</div>
}
