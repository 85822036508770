/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 31/05/2023
 * @descriptionThe This component manages insurance file details, facilitating creation and editing. It fetches data based on parameters from the URL, updates the file through API calls, and renders the `InsuranceFile` component accordingly.
 */
import InsuranceFile from "../../components/InsuranceFile/InsuranceFile";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {API} from "../../queries/api";


export default function InsurancesDetailFilePage() {

    const [file, setFile] = useState();
    const {id, idFile, action} = useParams();
    const navigate = useNavigate();


    const onSubmit = async (data) => {
        if (idFile === "new") {
            await API.post("company/company-file/create", {...data, companyId: Number(id)});
        } else {
            await API.put("company/company-file/update/" + idFile, {...data, companyId: Number(id)});
        }
        navigate("/insurances/" + id)
    }

    const getFile = async (idFile) => {
        if (idFile && idFile !== "new") {
            const {
                data
            } = await API("company/company-file/view/" + idFile);
            setFile(data);
        } else {
            setFile({});
        }
    };

    useEffect(() => {
        getFile(idFile);
    }, []);
    return <div className={"b-box"}>
        {file &&
            <InsuranceFile readOnly={!(idFile === "new" || action === "edit")}
                           data={file}
                           onSubmit={onSubmit}/>}
    </div>
}
