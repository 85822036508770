export const closeClaimConst = [
    {
        label: "COMPENSATION",
        value: "COMPENSATION"
    }, {
        label: "NOTIFICATION",
        value: "NOTIFICATION"
    }, {
        label: "REFUSE",
        value: "REFUSE"
    }, {
        label: "REPAIR",
        value: "REPAIR"
    }, {
        label: "REPAIR_AND_COMPENSATION",
        value: "REPAIR_AND_COMPENSATION"
    }
]



