export const proposalsTypes = {
    "proposal_new": "0",
    "proposal_exporting_error": "0",
    "proposal_exporting_failed": "0",
    "proposal_study_pending": "0",
    "proposal_offer_pending": "1",
    "proposal_accepted": "2",
    "proposal_policy_pending": "4",
    "proposal_finished": "0",

}
