/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 18/05/2023
 * @descriptionThe The `ChangePasswordPage` component allows users to change their password. It provides fields for entering the old password, new password, and confirming the new password. The form includes validation rules to ensure all fields are required. Users can submit the form to save the changes.
 */
import FormInput from "../../components/FormInput/FormInput";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";

const defaultValues = {
    beforePassword: "",
    password: "",
    confirmPassword: "",
}
export default function ChangePasswordPage () {
    const { t } = useTranslation()
    const {
        control,
        formState: { errors },
    } = useForm({ defaultValues });
    return (<div className={"b-box"}>
        <form>
            <fieldset>
                <legend>{t("Change password")}</legend>

                <div className={"py-4"}>
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <FormInput name="beforePassword" label={"Old password"} control={control}
                                       errors={errors} type={"password"}
                                       rules={{ required: true }}/>
                        </div>
                        <div className="col-12 col-lg-4">
                            <FormInput name="password" label={"New Password"} control={control}
                                       errors={errors} type={"password"}
                                       rules={{ required: true }}/>
                        </div>

                        <div className="col-12 col-lg-4">
                            <FormInput name="confirmPassword" label={"Confirm new Password"} control={control}
                                       errors={errors} type={"password"}
                                       rules={{ required: true }}/>
                        </div>
                    </div>
                </div>
            </fieldset>
            <div className={"d-flex justify-content-end mt-8"}>
                <Button label={t("Save")}/>
            </div>
        </form>
    </div>)
}
