/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 29/06/2023
 * @descriptionThe This component manages insurance customer service details, allowing creation and editing. It fetches data based on parameters from the URL, updates the service through API calls, and renders the `InsuranceCustomerService` component accordingly.
 */
import InsuranceCustomerService from "../../components/InsuranceCustomerService/InsuranceCustomerService";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {API} from "../../queries/api";

export default function InsurancesDetailCustomerServicePage() {
    const [customerService, setCustomerService] = useState();
    const {id, idCustomerService, action} = useParams();
    const navigate = useNavigate();


    const onSubmit = async (data) => {
        if (idCustomerService === "new") {
            await API.post("company/customer-service/create", {...data, companyId: Number(id)});
        } else {
            await API.put("company/customer-service/update/" + idCustomerService, {...data, companyId: Number(id)});
        }
        navigate("/insurances/" + id)
    }

    const getCustomerService = async (idCustomerService) => {
        if (idCustomerService && idCustomerService !== "new") {
            const {
                data
            } = await API("company/customer-service/view/" + idCustomerService);
            setCustomerService(data);
        } else {
            setCustomerService({});
        }
    };

    useEffect(() => {
        getCustomerService(idCustomerService);
    }, []);
    return <div className={"b-box"}>
        {customerService &&
            <InsuranceCustomerService readOnly={!(idCustomerService === "new" || action === "edit")}
                                      data={customerService}
                                      onSubmit={onSubmit}/>}
    </div>
}
