/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 10/10/2023
 * @descriptionThe This `UserProposalsTable` component manages proposals for a specific user. It utilizes lazy loading for efficient data retrieval, supporting pagination and sorting. It fetches proposals with additional information and renders a `ProposalsTable` component with the fetched data and lazy loading functionality.
 */
import { useEffect, useState } from "react";
import { API } from "../../queries/api";
import { createFilterParamsUtil } from "../../utils/createFilterParams.util";
import ProposalsTable from "../ProposalsTable/ProposalsTable";
import {checkAndReturnCorrectLazyTableValues} from "../../utils/checkAndReturnCorrectLazyTableValues.util";

export default function UserProposalsTable ({ userId }) {
    const [proposals, setProposals] = useState([])

    const [lazyTable, setLazyTable] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
    });

    const onLazy = (event) => {
        const newLazyTable = checkAndReturnCorrectLazyTableValues(lazyTable, event);
        setLazyTable(newLazyTable);
        getLazyData(newLazyTable);
    };

    const getLazyData = async (event) => {
        const { data: { data: proposals, count } } = await API(
            "policy/proposal/list" + createFilterParamsUtil(event) + "&extraFields=policyType&filter[userId]=" + userId
        );
        setLazyTable({ ...event, totalRecords: count })
        setProposals(proposals);
    };

    useEffect(() => {
        getLazyData(lazyTable);
    }, []);


    return <ProposalsTable data={proposals} onLazy={onLazy} lazyTable={lazyTable} isBranchNeeded={true}/>

}
