/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 04/01/2024
 * @description The `BodyTemplateCheckbox` component renders a checkbox icon based on the `isOk` prop. If `isOk` is true, it shows a checkmark icon; otherwise, it displays a close icon.
 *
 * The `verifiedRowFilterTemplate` function returns a TriStateCheckbox component for filtering rows. It takes options and sets the value based on the filter state, triggering the filterApplyCallback on change.
 */
import {TriStateCheckbox} from "primereact/tristatecheckbox";

export const BodyTemplateCheckbox = ({isOk}) => {
        return isOk ? (
            <span className="material-symbols-outlined">done</span>
        ) : (
            <span className="material-symbols-outlined">close</span>
        );
}

export const verifiedRowFilterTemplate = (options) => {
        return (
            <TriStateCheckbox
                value={options.value}
                onChange={(e) => options.filterApplyCallback(e.value)}
            />
        );
};
