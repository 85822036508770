export const dataTypesConst = [
    {
        label: "EMAIL",
        value: "EMAIL"
    },   {
        label: "PHONE",
        value: "PHONE"
    },  {
        label: "MOBILE",
        value: "MOBILE"
    },
]
