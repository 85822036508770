/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 29/06/2023
 * @descriptionThe The `UsersDetailAddressPage` component manages user addresses, allowing creation and editing. It fetches address data based on the user and address IDs. The `parseAddress` function converts address data between frontend and backend formats. The component provides a user-friendly form for address management.
 */
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {API} from "../../queries/api";
import UserAddress from "../../components/UserAddress/UserAddress";


export default function UsersDetailAddressPage() {
    const [userAddress, setUserAddress] = useState();
    const {id, idAddress, action} = useParams();

    const {t} = useTranslation();

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        if (idAddress === "new") {
            await API.post("user/address/create", {
                ...parseAddress(data),
                userId: id,
            });
        } else {
            await API.put("user/address/update/" + idAddress, {...data, userId: id});
        }
        navigate("/users/" + id)


    }

    const getUserAddress = async (idAddress) => {
        if (idAddress && idAddress !== "new") {
            const {
                data
            } = await API("user/address/view/" + idAddress);
            setUserAddress(data);
        } else {
            setUserAddress({});
        }
    };


    useEffect(() => {
        getUserAddress(idAddress);
    }, []);

    return (
        <>
            {userAddress && <>
                <div className={"b-box"}>
                    <h2 className={"mb-8"}>
                        {idAddress === "new" && t("Creation of")} {t("User address")} {userAddress.name}
                    </h2>

                    <UserAddress readOnly={!(idAddress === "new" || action === "edit")} data={userAddress}
                                 onSubmit={onSubmit}/>
                </div>

            </>}
        </>
    );
}
export const parseAddress = (addressUnParsed, isSwitched = false) => {
    return isSwitched ? {
        address_road_number: addressUnParsed.buildNumber,
        address_road_type: addressUnParsed.streetType,
        address: addressUnParsed.street,
        province: addressUnParsed.province,
        address_postal: addressUnParsed.postalCode,
        address_floor: addressUnParsed.floor,
        address_door: addressUnParsed.door,
        address_town: addressUnParsed.city ? Number(addressUnParsed.city) : undefined,
        active: true
    } : {
        buildNumber: addressUnParsed.address_road_number,
        streetType: addressUnParsed.address_road_type,
        street: addressUnParsed.address,
        title: addressUnParsed.address,
        province: addressUnParsed.province,
        postalCode: addressUnParsed.address_postal,
        floor: addressUnParsed.address_floor,
        door: addressUnParsed.address_door,
        city: Number(addressUnParsed.address_town),
        active: true
    }
}
