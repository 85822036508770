/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 28/06/2023
 * @descriptionThe The `BackofficeUsersPage` component displays a list of backoffice users with functionalities for creating, viewing, editing, and deleting users. It utilizes pagination, sorting, and filtering for efficient navigation through the user list. The component fetches data asynchronously using lazy loading to enhance performance. Additionally, it provides actions such as viewing, editing, and deleting users, along with a filter dropdown for selecting user roles.
 */
import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";
import ActionsOverlayMenu from "../../components/ActionsOverlayMenu/ActionsOverlayMenu";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { API } from "../../queries/api";
import { createFilterParamsUtil } from "../../utils/createFilterParams.util";
import {deleteTableRowUtil} from "../../utils/deleteTableRow.util";
import {checkAndReturnCorrectLazyTableValues} from "../../utils/checkAndReturnCorrectLazyTableValues.util";
import FilterDropdownTemplate from "../../templates/FilterDropdownTemplate";

export default function BackofficeUsersPage () {
    const [backofficeUsers, setBackofficeUsers] = useState([])
    const [roles,setRoles] = useState([])
    const { t } = useTranslation()
    const navigate = useNavigate();


    const [lazyTable, setLazyTable] = useState({
        first: 0,
        rows: 50,
        page: 1,
        sortField: null,
        sortOrder: null,
    });


    const onLazy = (event) => {
        const newLazyTable = checkAndReturnCorrectLazyTableValues(lazyTable, event);
        setLazyTable(newLazyTable);
        getLazyData(newLazyTable);
    };

    const getLazyData = async (event) => {
        const { data: { data: backofficeUsers, count } } = await API(
            "user-bo/user/list" + createFilterParamsUtil(event, "filter", 50)
        );
        setLazyTable({ ...event, totalRecords: count })
        // JHCORE: Este filtro es provisorio para evitar que un agente pueda borrar por error un usuario importante para el sistema.
        setBackofficeUsers(backofficeUsers);
    };


    const tableHeader = () => <div className={"d-flex justify-content-end"}>
        <Link to={"./new"}>
            <Button label={t("Create backoffice user")}></Button>
        </Link>
    </div>


    const InsurancesActions = (rowData) => {
        const isTasAutomationUser = rowData.firstName == 'Task Automation';
        const items = !isTasAutomationUser ? [
            {
                label: t("See"),
                icon: <span className="me-4 material-symbols-outlined">visibility</span>,
                command: () => navigate("/backoffice-users/" + rowData.id)

            }, {
                label: t("Edit"),
                icon: <span className="me-4 material-symbols-outlined">edit</span>,
                command: () => navigate("/backoffice-users/" + rowData.id + "/edit")
            }, {
                label: t("Remove"),
                icon: <span className="me-4 material-symbols-outlined">delete</span>,
                command: () => deleteTableRowUtil(rowData.id, "user-bo/user/delete/" + rowData.id, backofficeUsers, setBackofficeUsers),
            }
        ]: [];
        return <ActionsOverlayMenu disabled={isTasAutomationUser} items={items}/>
    }

    useEffect(() => {
        const getRoles = async () => {
            const { data:{data:roles} } = await API(
                "user-bo/user/roles"
            );
            setRoles(roles.map(role => ({label:role.name, value:role.name})))
        }
        getLazyData(lazyTable);
        getRoles()
    }, []);

    return (<div>
        <div className={"b-box"}>

            <h2>{t("Backoffice users list")} ({backofficeUsers.length})</h2>
            <DataTable emptyMessage={t("No results found")}
                onPage={onLazy}
                onFilter={onLazy}
                onSort={onLazy}
                lazy
                first={lazyTable.first}
                totalRecords={lazyTable.totalRecords}
                sortOrder={lazyTable.sortOrder} header={tableHeader} value={backofficeUsers} filterDisplay="row"
                dataKey="id" paginator rows={20}>
                <Column field="id"  filter filterMatchMode="contains" filterPlaceholder={t("Search")}
                        header={t("ID")}/>
                <Column field="email"  filter filterMatchMode="contains" filterPlaceholder={t("Search")}
                        header={t("Email")}/>
                <Column field="name"  filter filterMatchMode="contains" filterPlaceholder={t("Search")}
                        body={(rowData) => rowData.firstName + " " + rowData.lastName}
                        header={t("Name")}/>
                <Column field="rol"  filter filterMatchMode="equals" filterPlaceholder={t("Search")}
                        filterField="role"
                        filterElement={(filter) => <FilterDropdownTemplate filter={filter} options={roles}/>}
                        header={t("Role")} />
                <Column header={t("Actions")} body={InsurancesActions}/>
            </DataTable>
        </div>

    </div>)
}

