export const contactAreasConst = (t) => [
    {
        label: t("ADMINISTRATION"),
        value: "ADMINISTRATION"
    },   {
        label: t("COMMERCIAL"),
        value: "COMMERCIAL"
    },   {
        label: t("OTHERS"),
        value: "OTHERS"
    },   {
        label: t("PRODUCTION"),
        value: "PRODUCTION"
    },   {
        label: t("CLAIMS"),
        value: "CLAIMS"
    }
]
