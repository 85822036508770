export const formTypesConst = {
    numeric: null,
    string: null,
    date: "calendar",
    boolean: "checkbox",
    selector: "select",
    multiselector: "multiselect",
    autocomplete: "autocomplete",
    "car_brand": "car_brand",
    "car_model": "car_model",
    "car_version": "select",
    "motorbike_brand": "motorbike_brand",
    "motorbike_model": "motorbike_model",
    "motorbike_version": "select"
}
