/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 04/07/2023
 * @description The `FilePondImageEditor` component integrates FilePond with FilerobotImageEditor for image editing. It allows uploading and editing images, providing features like cropping, rotating, and adding annotations. Users can open the editor by clicking on the image thumbnail. It supports read-only mode, accepts specified file types, and handles required fields.
 */
import FilerobotImageEditor from "filerobot-image-editor";
import filePonUtil from "../../utils/filePond.util";
import {FilePond} from "react-filepond";
import {useTranslation} from "react-i18next";
import React, {useRef, useState} from "react";
import {Dialog} from "primereact/dialog"; // Load library from NPM
import "./FilePondImageEditor.scss"

export const FilePondImageEditor = ({
                                        files,
                                        setFiles,
                                        fileKey,
                                        readOnly,
                                        acceptedFileTypes = ["image/jpeg", "image/png"],
                                        label,
                                        disabled,
                                        required = true,
    maxFiles= 1
                                    }) => {
    const {t} = useTranslation();
    const [isShowingDialog, setIsShowingDialog] = useState(false)
    const filePondRef = useRef();
    const editor = {
        // Called by FilePond to edit the image
        // - should open your image editor
        // - receives file object and image edit instructions
        open: (file, instructions) => {
            setIsShowingDialog(true)
            const {TABS, TOOLS} = FilerobotImageEditor;
            getBase64(file)


            function getBase64(file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    const config = {
                        source: reader.result,
                        onSave: (editedImageObject, designState) => {
                            setIsShowingDialog(false)
                            setFiles({
                                source: editedImageObject.imageBase64,
                                options: {
                                    type: "local",
                                }
                            })
                        },
                        annotationsCommon: {
                            fill: '#ff0000',
                        },
                        Text: {text: 'Filerobot...'},
                        Rotate: {angle: 90, componentType: 'buttons'},
                        translations: {
                            profile: 'Profile',
                            coverPhoto: 'Cover photo',
                            facebook: 'Facebook',
                            socialMedia: 'Social Media',
                            fbProfileSize: '180x180px',
                            fbCoverPhotoSize: '820x312px',
                        },
                        Crop: {
                            presetsItems: [
                                {
                                    titleKey: 'classicTv',
                                    descriptionKey: '4:3',
                                    ratio: 4 / 3,
                                    // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
                                },
                                {
                                    titleKey: 'cinemascope',
                                    descriptionKey: '21:9',
                                    ratio: 21 / 9,
                                    // icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
                                },
                            ],
                            presetsFolders: [
                                {
                                    titleKey: 'socialMedia', // will be translated into Social Media as backend contains this translation key
                                    // icon: Social, // optional, Social is a React Function component. Possible (React Function component, string or HTML Element)
                                    groups: [
                                        {
                                            titleKey: 'facebook',
                                            items: [
                                                {
                                                    titleKey: 'profile',
                                                    width: 180,
                                                    height: 180,
                                                    descriptionKey: 'fbProfileSize',
                                                },
                                                {
                                                    titleKey: 'coverPhoto',
                                                    width: 820,
                                                    height: 312,
                                                    descriptionKey: 'fbCoverPhotoSize',
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        tabsIds: [TABS.ADJUST], // or ['Adjust', 'Annotate', 'Watermark']
                        defaultTabId: TABS.ADJUST, // or 'Annotate'
                        defaultToolId: TOOLS.TEXT, // or 'Text'
                    };
                    // Assuming we have a div with id="editor_container"
                    const filerobotImageEditor = new FilerobotImageEditor(
                        filePondRef.current,
                        config,
                    );

                    filerobotImageEditor.render({
                        onClose: (closingReason) => {
                            console.log('Closing reason', closingReason);
                            setIsShowingDialog(false)
                            filerobotImageEditor.terminate();
                        },
                    });
                };
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                };
            }


        },
    };
    const labelTemplate = () => (label ? label : t("File")) + (required ? " *" : "")
    return <div className={"c-file-pond-image-editor"}>

        {(readOnly && files && files.length === 1 ?
            <a className="c-file-pond-image-editor__open-file ms-3" href={files[0].source} target="_blank"><span
                className="d-inline-block u-font-size--2xl material-symbols-outlined">open_in_new</span></a> : "")}
        <Dialog visible={isShowingDialog} style={{width: '50vw'}} closable={false}>
            <div ref={filePondRef}></div>
        </Dialog>

        <FilePond
            {...filePonUtil(fileKey, files, setFiles, t)}
            allowMultiple={maxFiles > 1}
            maxFiles={maxFiles}
            disabled={readOnly}
            labelIdle={labelTemplate()}
            acceptedFileTypes={acceptedFileTypes}
            imageResizeTargetWidth={200}
            imageResizeTargetHeight={144}
            imageResizeUpscale={false}
            imageResizeMode={"contain"}
            // imageEditEditor={editor}
            required={required}
        />
    </div>

}
