/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 03/11/2023
 * @description The `AddressForm` component is used to render a form for entering address information. It consists of various input fields such as province, postal code, municipality, road name, portal number, floor, and door. The component fetches dynamic data such as provinces and address details based on user input.
 *
 * Here's a brief explanation of the component:
 *
 * - It receives props like `control`, `setValue`, `readOnly`, `errors`, and `formData`.
 * - It fetches provinces and address details based on user input using `useEffect`.
 * - It renders different input fields based on the user's selection and dynamically updates options for floors and doors.
 * - The `getFloorsAndDoors` function processes the fetched address data to extract floor and door options.
 *
 * Overall, the component facilitates the collection of address information with dynamic updates based on user input.
 */
import React, {useEffect, useState} from "react";
import {Controller} from "react-hook-form";
import FormInput from "../FormInput/FormInput";
import {API} from "../../queries/api";
import {useTranslation} from "react-i18next";
import AddressSearch from "../AddressSearch/AddressSearch";

let buildingData;
export default function AddressForm({control, setValue,readOnly, errors, formData}) {
    const [provinces, setProvinces] = useState([]);
    const {t} = useTranslation()
    const [floorsOptions, setFloorsOptions] = useState([]);
    const [doorsOptions, setDoorsOptions] = useState([]);

    useEffect(() => {
        const getProvinces = async () => {
            const {data} = await API(
                `data/location/province/list`
            );
            setProvinces(data.map(item => ({label: item.name, value: item.id})))
        }

        getProvinces()
    }, []);

    useEffect(() => {
        const getAddressInfo = async () => {
            if (formData["province"] && formData["address_town_search"] && formData["address_road_name"] && formData["address_road_number"]) {
                const {data} = await API(
                    `data/location/address/list/${formData["province"]}/${formData["address_town_search"]}/${formData["address_road_name"]}/${formData["address_road_number"]}`
                );
                buildingData = data;
                let addressData;
                if (data && data.length > 1) {
                    addressData = getFloorsAndDoors(data)
                } else {
                    setValue("address_floor", null)
                    setValue("address_door", null)
                    addressData = {floors: null, doors: null}
                }
                setFloorsOptions(addressData.floors)
                setDoorsOptions(addressData.doors)
            }

        }
        getAddressInfo()
    }, [formData["province"], formData["address_town_search"], formData["address_road_name"], formData["address_road_number"]]);

    useEffect(() => {
        const addressFloor = formData["address_floor"];
        const addressDoor = formData["address_door"];
        if ( (addressFloor || addressFloor === null) && (addressDoor || addressDoor === null) && buildingData) {
            const addressInfoData = addressDoor === null ? buildingData[0] :buildingData.find(floorData => floorData.address.floor === addressFloor && floorData.address.door === addressDoor)
            const addressData = addressInfoData ? addressInfoData.address : null;
            if (addressData && addressData.roadType) {
                setValue("address_cadastral_reference", addressData.cadastralReference)
                setValue("address_road_type", addressData.roadType.id)
                setValue("address_town", addressData.town.id)
                const completeAddressName = `${addressData.roadType.name.length > 0 ? addressData.roadType.name + " " : ""}${addressData.roadName} ${addressData.roadNumber}, ${addressData.floor} ${addressData.door}, ${formData["address_postal"]}${addressData.town ? ` ${addressData.town.name} (${addressData.town.province.name}` : ""})`
                setValue("address", completeAddressName)
            }
        }
    }, [formData["address_floor"], formData["address_door"]]);


    return <>
        <div className={"col-12 col-lg-4"}>
            <FormInput control={control} errors={errors} label={t("Select your province")} name={"province"} readOnly={readOnly}
                       rules={{required: true}} type={"select"} options={provinces}/>
        </div>

        {formData["province"] && <div className={"col-12 col-lg-4 mt-3 mt-lg-0"}>
            <FormInput control={control} errors={errors} label={t("Postal code")} name={"address_postal"} readOnly={readOnly}
                       rules={{required: true}}/>

        </div>}


        {formData["address_postal"] && <div className={"col-12 col-lg-4 mt-3 mt-lg-0"}>
            <Controller
                control={control}
                name={"address_town_search"}
                render={({field: {onChange, onBlur, value}}) => (
                    <AddressSearch readOnly={readOnly}
                        value={value}
                        label={t("Search municipality")}
                        provinceId={formData["province"]}
                        rules={{required: true}}
                        onChange={e => {
                            onChange(e.id)
                        }} type={"address_town"}/>
                )}/>
        </div>}

        {formData["address_town_search"] && <div className={"col-12 col-lg-4 mt-3 mt-lg-0"}>
            <Controller
                control={control}
                name={"address_road_name"}
                render={({field: {onChange, onBlur, value}}) => (
                    <AddressSearch readOnly={readOnly}
                        value={value}
                        label={t("Search address")}
                        provinceId={formData["province"]}
                        municipalityId={formData["address_town_search"]}
                        roadNumber={formData["address_road_number"]}
                        rules={{required: true}}
                        onChange={e => {
                            onChange(e.id)
                        }} type={"address_road_name"}/>
                )}/>
        </div>}

        {formData["address_road_name"] && <div className={"col-12 col-lg-4 mt-3 mt-lg-0"}>
            <FormInput control={control} errors={errors} label={t("Portal number")} name={"address_road_number"} readOnly={readOnly}
                       rules={{required: true}}/>
        </div>}


        {formData["address_road_number"]&& floorsOptions  && <div className={"col-12 col-lg-4 mt-3 mt-lg-0"}>
            <FormInput control={control} errors={errors} label={t("Select your floor")} readOnly={readOnly}
                       name={"address_floor"}
                       rules={{required: true}} type={"select"} options={floorsOptions}/>
        </div>}

        {formData["address_floor"]  && doorsOptions && <div className={"col-12 col-lg-4 mt-3 mt-lg-0"}>
            <FormInput control={control} errors={errors} label={t("Select your door")} readOnly={readOnly}
                       name={"address_door"}
                       rules={{required: true}} type={"select"}
                       options={doorsOptions.filter(door => door.floor === formData["address_floor"])}/>
        </div>}

    </>
}

const getFloorsAndDoors = (floors) => {
    const floorsOptions = []
    const doorsOptions = []
    for (const floor of floors) {
        if (floor) {
            const address = floor.address;
            const addressFloor = address.floor;
            const addressDoor = address.door;
            const floorFound = floorsOptions.find(floorOption => floorOption.value === floor.address.floor)
            if (!floorFound) {
                floorsOptions.push({label: addressFloor, value: addressFloor})
            }
            doorsOptions.push({label: addressDoor, value: addressDoor, floor: addressFloor})
        }
    }

    return {floors: floorsOptions, doors: doorsOptions}
}
