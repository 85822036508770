/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 17/01/2024
 * @description The `Task` component is used for managing tasks or notes. It provides forms for creating, editing, and viewing task or note data. Users can input details such as title, user information, alarm, status, and comments. Additionally, it allows uploading files related to the task or note. The component includes functionality for postponing or finishing tasks and displaying dialogs for these actions.
 */
import FormInput from "../FormInput/FormInput";
import {Button} from "primereact/button";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {checkAndTransformDirtyFileUtil} from "../../utils/checkAndTransformDirtyFile.util";
import {dirtyValuesUtil} from "../../utils/dirtyValues.util";
import {Link, useNavigate} from "react-router-dom";
import {FilePondImageEditor} from "../FilePondImageEditor/FilePondImageEditor";
import {Dialog} from "primereact/dialog";
import PostponeAlarm from "../PostponeAlarm/PostponeAlarm";

const fileKey = "fileLocation"

/**
 *
 * @param data
 * @param policyTypeId
 * @param user
 * @param type Can be null or note. If type is note the form is different
 * @param readOnly used to show field like input or only just show data
 * @param onSubmit
 * @param isNew
 * @returns {JSX.Element}
 * @constructor
 */
export default function Task({
                                 data, readOnly = false, isNote, onSubmit = () => {
    }, isNew, user, onPostpone = () => {
    }, onFinish = () => {
    }
                             }) {
    const [files, setFiles] = useState(null)
    const {t} = useTranslation();
    const navigate = useNavigate()
    const [selectedDialog, setSelectedDialog] = useState("")

    const {
        control,
        formState: {errors, dirtyFields},
        handleSubmit,
    } = useForm({
        defaultValues: {
            ...data,
            ...user,
            statusTask: data.status,
            user: Object.keys(user).length !== 0 ? user.name + (user.firstSurname ? " " + user.firstSurname : "") + (user.secondSurname ? " " + user.secondSurname : "") : "",
            alarmAt: data.alarmAt ? new Date(data.alarmAt) : null,
        }
    });

    const onHandleSubmit = (dataForm) => {
        try {
            //check we have data
            let dataToSubmit = dirtyValuesUtil(dirtyFields, dataForm)
            dataToSubmit.userId = Object.keys(user).length !== 0 ? user.id : dataToSubmit.userId
            //if we have file we need to transform it to base64
            dataToSubmit = checkAndTransformDirtyFileUtil(dataForm[fileKey], files, dataToSubmit, fileKey, "taskFiles", true);
            onSubmit(dataToSubmit)
        } catch
            (e) {
            console.error(e)
        }


    }

    const goToUser = () => {
        navigate("/users/" + user.id)
    }

    return <>
        <form onSubmit={handleSubmit(onHandleSubmit)}>

            {!isNew && <fieldset>
                <legend>{t("User data")}</legend>

                <div className={"py-4"}>
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <FormInput readOnly={readOnly} name="user" label={"User"}
                                       control={control} type="onClick" onClick={goToUser}
                                       errors={errors}/>
                        </div>


                        <div className="col-12 col-lg-4">
                            <FormInput readOnly={readOnly} name="nif" label={"NIF"}
                                       control={control}
                                       errors={errors}/>
                        </div>

                        <div className="col-12 col-lg-4">
                            <FormInput readOnly={readOnly} name="email" label={"Email"}
                                       control={control}
                                       errors={errors}/>
                        </div>

                        <div className="col-12 col-lg-4">
                            <FormInput readOnly={readOnly} name="mobile" label={"Mobile"}
                                       control={control}
                                       errors={errors}/>
                        </div>

                        <div className="col-12 col-lg-4">
                            <FormInput readOnly={readOnly} name="allowCall" label={"User allow calls?"}
                                       control={control} type={"checkbox"}
                                       errors={errors}/>
                        </div>

                        <div className="col-12 col-lg-4">
                            <FormInput readOnly={readOnly} name="status" label={"Status"}
                                       control={control}
                                       errors={errors}/>
                        </div>

                        <div className="col-12 col-lg-4">
                            <FormInput readOnly={readOnly} name="numAttempts" label={"Contact attempts"}
                                       control={control}
                                       errors={errors}/>
                        </div>

                    </div>

                </div>
            </fieldset>}

            <fieldset>
                <legend>{isNote ? t("Note data") : t("Task data")}</legend>

                <div className={"py-4"}>
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <FormInput readOnly={readOnly} name="title" label={"Title"}
                                       control={control} rules={{required: true}}
                                       errors={errors}/>
                        </div>

                        {!isNote && <div className="col-12 col-lg-4">

                            <FormInput readOnly={readOnly} name="assignedToId" label={"Admin"} control={control}
                                       errors={errors} type={"search"} service={"user-bo/user"} rules={{required: true}}
                                       keys={["firstName", "lastName"]}/>
                        </div>}

                        {Object.keys(user).length === 0 && <div className="col-12 col-lg-4">

                            <FormInput readOnly={readOnly} name="userId" label={"User"} control={control}
                                       config={{searchKey: "search"}}
                                       errors={errors} type={"search"} service={"user/user"} rules={{required: true}}
                                       keys={["name", "firstSurname", "secondSurname", "nif"]}/>
                        </div>}


                        {!isNote && <div className="col-12 col-lg-4">
                            <FormInput readOnly={readOnly} name="alarmAt" label={"Alarm"}
                                       control={control} type={"calendar"} config={{hourFormat: "24"}}
                                       errors={errors}/>
                        </div>}

                        {!isNew && <div className="col-12 col-lg-4">
                            <FormInput readOnly={readOnly} name="statusTask" label={"Status"}
                                       config={{isTranslationNecessary: true}}
                                       control={control}
                                       errors={errors}/>
                        </div>}


                    </div>

                </div>
            </fieldset>


            {readOnly && data.taskFiles && data.taskFiles.length > 0 && <fieldset>
                <legend>{t("Files")}</legend>
                <div className={"py-4"}>
                    {data.taskFiles.map((file, index) => <p className={"b-box b-box--sec"}><Link target="_blank"
                                                                                                 to={process.env.REACT_APP_IMAGE_URL + file.fileLocation}>
                        {t("See image")} {index + 1}
                    </Link></p>)}
                </div>
            </fieldset>
            }

            {!readOnly && <fieldset>
                <legend>{t("Files")}</legend>


                <div className={"py-4"}>
                    <div className="row">
                        <div className="col-12">
                            <FilePondImageEditor maxFiles={5} files={files} setFiles={setFiles}
                                                 fileKey={fileKey}
                                                 readOnly={false}
                                                 required={false}
                                                 acceptedFileTypes={["application/pdf", "image/jpeg", "image/png"]}/>
                        </div>
                    </div>
                </div>
            </fieldset>}

            {data.comments && !isNote && <fieldset>
                <legend>{t("Comments")}</legend>
                {data.comments.length === 0 && <p className={"b-box b-box--sec"}>{t("No comments")}</p>}
                {data.comments.map((comment, index) => <p className={"b-box b-box--sec"}
                                                          key={index}>{comment.text}</p>)}
            </fieldset>}

            { (!isNote || (isNote && isNew)) && <div className={"d-flex mt-8 " + (isNew ? "justify-content-end" : "justify-content-between")}>
                {!isNew && <div>
                    <Button onClick={() => setSelectedDialog("postpone")} className={"me-6"} type="button"
                            label={t("Postpone")}/>
                    <Button onClick={() => setSelectedDialog("finish")} className={"me-6"} type="button"
                            label={t("Finish")}/>
                </div>}
                {isNew && <div>
                    <Button label={t("Save")}/>
                </div>}

            </div>}


        </form>
        <Dialog header={selectedDialog === "postpone" ? t("Postpone alarm") : t("Finish alarm")}
                visible={selectedDialog !== ""}
                onHide={() => setSelectedDialog("")}>
            <PostponeAlarm
                onSubmit={(fData) => selectedDialog === "postpone" ? onPostpone(fData) : onFinish({text: fData.text})}
                type={selectedDialog}/>
        </Dialog>
    </>
}
