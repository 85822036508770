
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
// import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";

// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css'

import FilePondPluginFileEncode from 'filepond-plugin-file-encode';

// Register the plugins

// Import the plugin code
// Import the plugin styles
import { registerPlugin } from "react-filepond";

registerPlugin(
    // FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginImageEdit,
    FilePondPluginFileValidateSize,
    FilePondPluginFileValidateType,
    FilePondPluginFileEncode
);


export default function filePonUtil(name, value, onChange, t) {
    return {
        name,
        allowFileSizeValidation: true,
        maxFileSize: "5MB",
        labelMaxFileSizeExceeded: t("File is too large"),
        labelMaxFileSize: t("Max file is 5MB"),
        files: createFiles(value),
         onupdatefiles: onChange,

        server: {
            load: (source, load) => {
                if (typeof source === "string") {
                    const myRequest = new Request(source);
                    fetch(myRequest).then(function (response) {
                        response.blob().then(function (myBlob) {
                            load(myBlob);
                        });
                    });
                }
            },
        },
    };
}

function createFiles(value) {
    if (!value || value.length === 0) {
        return [];
    } else if (Array.isArray(value)) {
        return value.map((item) => ({
            source: createUrl(item),
            options: {
                type: "local",
            },
        }));
    } else {
        return [
            {
                source: createUrl(value),
                options: {
                    type: "local",
                },
            },
        ];
    }
}


export function createUrl(value) {
    return value && typeof value === "string" ? process.env.REACT_APP_IMAGE_URL + value + "?ignoreCache=1": value.source ? value.source : "";
}
