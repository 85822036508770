export const fileTypesConst = [
    {
        label: "DRIVING_LICENSE",
        value: "DRIVING_LICENSE"
    },   {
        label: "CIF_FRONT",
        value: "CIF_FRONT"
    },  {
        label: "CIF_BACK",
        value: "CIF_BACK"
    },  {
        label: "OTHER",
        value: "OTHER"
    },
]
