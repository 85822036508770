/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 09/02/2024
 * @description The `CoveragesComparativeTable` component displays a comparative table of coverages for different budgets. It dynamically generates columns based on the provided budgets and their respective coverages. Users can toggle descriptions for each coverage. The component supports filtering and sorting functionalities.
 *
 * Here's a brief summary of its functionality:
 * - Displays coverages for different budgets in a tabular format.
 * - Allows users to toggle descriptions for each coverage.
 * - Supports filtering and sorting of data.
 * - Provides a customizable header template for each budget.
 *
 * Additionally, the component utilizes various hooks from React and React libraries such as `useState`, `useEffect`, `useTranslation`, and components from `primereact` library like `DataTable`, `Column`, and `InputText`.
 */
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {API} from "../../queries/api";
import {BodyTemplateCheckbox, verifiedRowFilterTemplate} from "../BodyTemplateCheckbox/BodyTemplateCheckbox";

export default function CoveragesComparativeTable({budgets, policyTypeId}) {
    const {t} = useTranslation();
    const [formattedData, setFormattedData] = useState([]);
    const [descriptionsIds, setDescriptionsIds] = useState([]);

    const toggleDescriptionIds = (index) => {
        const foundDescriptionId = descriptionsIds.findIndex((id) => id === id);
        if (foundDescriptionId === -1) {
            setDescriptionsIds([...descriptionsIds, index])
        } else {
            const copyDescriptionsId = [...descriptionsIds];
            copyDescriptionsId.splice(foundDescriptionId, 1);
            setDescriptionsIds(copyDescriptionsId)
        }
    }
    const headerTemplate = (budget) => <div>
        {budget.company.logo ?
            <img width="100" src={process.env.REACT_APP_IMAGE_URL + budget.company.logo}
                 alt={t("Company")}/> : budget.company.name}
        <p>{budget.name} - {budget.modalityName}</p>
        <p>{budget.firstBill + budget.otherBills + "€"}</p>
    </div>


    useEffect(() => {
        const getCoverages = async (policyTypeId) => {
            try {
                const {data: {data: coverages}} = await API("policy/policy-type/coverage/list/" + policyTypeId);
                setFormattedData(formatCoverages(coverages, budgets))
            } catch (e) {
                console.log(e)
            }
        }
        getCoverages(policyTypeId)

    }, []);
    return <DataTable emptyMessage={t("No results found")}
                      className={"p-datatable--light"}
                      value={formattedData}
                      filterDisplay="row"
                      dataKey="id"
                      scrollable

    >
        <Column field="name" frozen
                body={({name, isWithDescription}, {rowIndex}) => nameTemplate(name, isWithDescription,rowIndex, toggleDescriptionIds, descriptionsIds)}/>
        {budgets.map(budget => <Column field={budget.id.toString()}  filter filterType={"boolean"}
                                       filterMatchMode={"equals"}
                                       filterPlaceholder={t("Search")}
                                       style={{maxWidth: "300px"}}
                                       filterElement={verifiedRowFilterTemplate}
                                       body={(rowData, {rowIndex}) => <>
                                           <BodyTemplateCheckbox isOk={rowData[budget.id.toString()]}/>
                                           {descriptionsIds.includes(rowIndex) &&
                                               <p>{rowData[budget.id.toString() + "-description"] ? rowData[budget.id.toString() + "-description"] : t("No description")}</p>}
                                       </>}
                                       header={() => headerTemplate(budget)}/>)}
    </DataTable>
}

const formatCoverages = (coverages, budgets) => {
    const formattedCoverages = []
    for (const coverage of coverages) {
        const newRow = {name: coverage.name};
        for (const budget of budgets) {
            const budgetId = budget.id.toString();
            if (budget.budgetCoverages) {
                const foundBudgetCoverage = budget.budgetCoverages.find(budgetCoverage => budgetCoverage.coverageId === coverage.id)
                newRow[budgetId] = !!foundBudgetCoverage;
                if (foundBudgetCoverage && foundBudgetCoverage.description !== "") {
                    newRow[budgetId + "-description"] = foundBudgetCoverage.description
                    newRow.isWithDescription = true
                }
            } else {
                newRow[budgetId] = false;
            }
        }
        formattedCoverages.push(newRow);

    }

    return formattedCoverages;
}
const nameTemplate = (name,isWithDescription, rowIndex, toggleDescriptionIds, descriptionsIds) => {
    return <p >{name}
        {isWithDescription && <button onClick={() => toggleDescriptionIds(rowIndex)} type="button" className="p-row-toggler p-link ms-3" style={ descriptionsIds.includes(rowIndex) ? {transform:"rotate(270deg)"} : {transform:"rotate(90deg"}}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className="p-icon p-row-toggler-icon" aria-hidden="true">
                <path
                    d="M4.38708 13C4.28408 13.0005 4.18203 12.9804 4.08691 12.9409C3.99178 12.9014 3.9055 12.8433 3.83313 12.7701C3.68634 12.6231 3.60388 12.4238 3.60388 12.2161C3.60388 12.0084 3.68634 11.8091 3.83313 11.6622L8.50507 6.99022L3.83313 2.31827C3.69467 2.16968 3.61928 1.97313 3.62287 1.77005C3.62645 1.56698 3.70872 1.37322 3.85234 1.22959C3.99596 1.08597 4.18972 1.00371 4.3928 1.00012C4.59588 0.996539 4.79242 1.07192 4.94102 1.21039L10.1669 6.43628C10.3137 6.58325 10.3962 6.78249 10.3962 6.99022C10.3962 7.19795 10.3137 7.39718 10.1669 7.54416L4.94102 12.7701C4.86865 12.8433 4.78237 12.9014 4.68724 12.9409C4.59212 12.9804 4.49007 13.0005 4.38708 13Z"
                    fill="currentColor"></path>
            </svg>
        </button>}
        </p>
}
