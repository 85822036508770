/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 24/04/2023
 * @description The `ActionsOverlayMenu` component renders a button with a label, triggering a dropdown menu of actions when clicked. It utilizes a `TieredMenu` from PrimeReact library to display the actions. The component accepts an array of items for the menu and an optional label for the button. It also uses the `useTranslation` hook to localize the label text. The `ref` is used to toggle the menu when the button is clicked.
 */
import { Button } from "primereact/button";
import { useRef } from "react";
import { TieredMenu } from "primereact/tieredmenu";
import { useTranslation } from "react-i18next";

export default function ActionsOverlayMenu ({ items, label = "Actions", disabled = false }) {
    const {t} = useTranslation()
    const menu = useRef(null);

    return <div>
        <TieredMenu model={items} popup ref={menu} breakpoint="767px"/>
        <Button disabled={disabled} label={t(label)} icon={<span className="me-2 material-symbols-outlined">settings</span>}
                onClick={(e) => menu.current.toggle(e)}/>
    </div>
}
