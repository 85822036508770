/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 11/10/2023
 * @description `ButtonsList` renders a row of buttons aligned to the right. Each button, based on provided data, includes text, an icon, and an onClick function. If disabled, it appears grayed out. The component maps over data to generate buttons dynamically.
 */
import { Button } from "primereact/button";

export default function ButtonsList({data}){
    return <div className={"d-flex justify-content-end"}>
        {data.map((item, index) => <div className={item.isDisabled ? "u-disabled ms-3" : "ms-3"} onClick={item.onClick} key={index} c>
            <Button className={"p-button--s"} label={item.text} icon={<span className="me-2 material-symbols-outlined">{item.iconName}</span>}></Button>
        </div>)}
    </div>
}
