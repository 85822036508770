/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 10/10/2023
 * @descriptionThe The `ClaimsPage` component displays a list of claims, allowing users to paginate through the data and view details. It fetches claims data lazily and renders it in a table format. Users can't create new claims from this page. The component provides pagination and lazy loading functionality to efficiently handle large datasets.
 */
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {API} from "../../queries/api";
import {createFilterParamsUtil} from "../../utils/createFilterParams.util";
import ClaimsTable from "../../components/ClaimsTable/ClaimsTable";
import {checkAndReturnCorrectLazyTableValues} from "../../utils/checkAndReturnCorrectLazyTableValues.util";

export default function ClaimsPage() {
    const [claims, setClaims] = useState([]);
    const [lazyTable, setLazyTable] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
    });

    const {t} = useTranslation();

    const getLazyData = async (event) => {
        const {data: {data: claims, count}} = await API(
            "policy/claim/list" + createFilterParamsUtil(event) + "&extraFields=policyType"
        );
        setLazyTable({...event, totalRecords: count})

        setClaims(claims.map((d) => ({
            ...d,
            effectiveDate: new Date(d.effectiveDate),
            expirationDate: new Date(d.expirationDate),
            initialEffectiveDate: new Date(d.initialEffectiveDate),
        })));
    };

    const onLazy = (event) => {
        const newLazyTable = checkAndReturnCorrectLazyTableValues(lazyTable, event);
        setLazyTable(newLazyTable);
        getLazyData(newLazyTable);
    };


    useEffect(() => {
        getLazyData(lazyTable);
    }, []);
    return (
        <div>

            <div className={"b-box"}>
                <h2>
                    {t("Claims list")} ({lazyTable.totalRecords})
                </h2>
                <ClaimsTable data={claims} onLazy={onLazy} lazyTable={lazyTable} isCreationDisabled={true}/>
            </div>
        </div>
    );
}
