/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 03/01/2024
 * @description The `ProposalBudgetsHireForm` component is responsible for managing the form related to hiring proposals. Users can input address details, select existing addresses or create new ones, provide IBAN information, and choose whether to authorize another party. Upon submission, the form data is sent for further processing. The component integrates form control functionalities, error handling, and dynamic rendering based on user interactions and data availability.
 */
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import FormInput from "../FormInput/FormInput";
import {useTranslation} from "react-i18next";
import {API} from "../../queries/api";
import AddressForm from "../AddressForm/AddressForm";
import {Button} from "primereact/button";
import {toast} from "react-toastify";
import ToastContent from "../ToastContent/ToastContent";
import {parseAddress} from "../../pages/UsersDetailAddressPage/UsersDetailAddressPage";

export default function ProposalBudgetsHireForm({data, proposal, readOnly, setProposal}) {
    const {t} = useTranslation()
    const {
        control,
        setValue,
        formState: {errors},
        handleSubmit,
        watch
    } = useForm({
        defaultValues: {
            newAddress: false,
            anotherOwner: false, ...data,
            iban: proposal.iban,
            effectiveDate: new Date(proposal.effectiveDate)
        }
    });

    const {
        control: controlAddress,
        setValue: setValueAddress,
        formState: {errors: errorsValue},
        watch: watchAddress,
    } = useForm();


    const [addresses, setAddresses] = useState([])


    const onSubmit = async (fData) => {
        try {

            if (watch("newAddress")) {
                const {data: createdAddress} = await API.post("user/address/create", {
                    ...parseAddress(watchAddress()),
                    userId: proposal.userId,
                });
                await API.put("policy/proposal/paymentMethodUpdate/" + proposal.id, {
                    iban: fData.iban,
                    effectiveDate: fData.effectiveDate,
                    addressId: createdAddress.id,
                    anotherOwner: !!fData.anotherOwner
                });

            } else {
                await API.put("policy/proposal/paymentMethodUpdate/" + proposal.id, {
                    iban: fData.iban,
                    effectiveDate: fData.effectiveDate,
                    addressId: fData.addressId,
                    anotherOwner: !!fData.anotherOwner
                });
            }
            setProposal({...proposal, status: "POLICY_PENDING"})
            toast.info(<ToastContent title={t("Data saved successfully")}
            />)
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        const getAddresses = async () => {
            const {data: {data}} = await API("user/address/list?filter[userId]=" + proposal.userId);
            setAddresses(data.map(address => ({label: address.street, value: address.id})))
        }

        getAddresses()
        if (proposal.addressId) {
            setValue("addressId", proposal.addressId)
        }
    }, []);


    return <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
            <legend>{t("Address")}</legend>

            {!readOnly && <div className={"py-4"}>
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="newAddress"
                                   label={"Use created address"} control={control} value={false}
                                   errors={errors}
                                   type={"radio"}
                        />
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="newAddress" value={true}
                                   label={"Create new address"} control={control}
                                   errors={errors}
                                   type={"radio"}
                        />
                    </div>

                </div>
            </div>}
            <div className={"py-4"}>
                {watch("newAddress") === false && <div className="row">

                    <div className="col-12 col-lg-12">
                        <FormInput readOnly={readOnly} name="addressId" label={"Address"} control={control}
                                   errors={errors} type={"select"} options={addresses}
                                   rules={{required: true}}/>
                    </div>

                </div>}

                {watch("newAddress") === true && <div className={"row"}>
                    <AddressForm control={controlAddress} setValue={setValueAddress} errors={errorsValue}
                                 formData={watchAddress()}
                                 readOnly={false}/>
                </div>}
            </div>
        </fieldset>
        <fieldset>
            <legend>{t("IBAN")}</legend>

            <div className={"py-4"}>
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <FormInput name="iban" label={"IBAN"} control={control}
                                   errors={errors} readOnly={readOnly}
                                   rules={{required: true}}/>
                    </div>

                    <div className="col-12 col-lg-4">


                        <FormInput name="effectiveDate" label={"Effective date"}
                                   control={control}
                                   type={"calendar"} readOnly={readOnly}
                                   errors={errors} rules={{required: true}}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="anotherOwner"
                                   label={"Send authorization for another taker?"} control={control}
                                   errors={errors}
                                   type={"checkbox"}
                        />
                    </div>
                </div>
            </div>


            {!readOnly && <div className={"d-flex justify-content-end mt-8"}>
                <Button label={t("Save")}/>
            </div>}
        </fieldset>

    </form>
}

