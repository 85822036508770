import { isValidDateUtil } from "./isDateUtil";

export const transformArrayToObjUtil = (array) => {
    const newObj = {}
    if (array) {
        for (const item of array) {
            newObj[item.key] = isValidDateUtil(item.value) ? new Date(item.value) : item.value
        }
    }
    return newObj
}
