export const transformDynamicData = (obj) => {
    const transformedData = []
    for (const key in obj) {
        if (obj.hasOwnProperty(key) && (obj[key] !== "" && obj[key] !== null && obj[key] !== undefined)) {
            const value = typeof obj[key] === "number" || typeof obj[key] === "boolean" ? obj[key].toString() : obj[key] instanceof Date ? obj[key].toJSON().substring(0,10) : obj[key] // cause of codescopic we
            // need to parse all numbers and booleans to string
            transformedData.push({ key, value })
        }
    }
    return transformedData;
}
