/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 28/06/2023
 * @description This `BackofficeUser` component is a form for managing user data in a backoffice environment. Here's a summary of its features:
 *
 * - It utilizes the `useForm` hook from `react-hook-form` for managing form state and validation.
 * - The form includes fields for first name, last name, email, phone number, role, gender, password (if it's a new user), photo (if the role is "Manager"), experience, education, and a brief about section.
 * - The `onSubmit` function is called when the form is submitted, and it handles the form data submission, including handling file uploads and password updates.
 * - The component fetches user roles from the backend and sets them as options for the role select input.
 * - If the selected role is "Manager", it displays a file upload component for uploading a photo.
 * - The form input fields handle read-only mode based on the `readOnly` prop.
 * - Form validation rules are defined for required fields and password pattern validation.
 * - The form submission button is displayed only when the form is not in read-only mode.
 *
 * Overall, this component provides a comprehensive form for managing user data, including user details, roles, and additional information, with support for read-only mode and form submission.
 */
import FormInput from "../FormInput/FormInput";
import {Button} from "primereact/button";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {gendersConst} from "../../consts/genders.const";
import {useEffect, useState} from "react";
import {API} from "../../queries/api";
import {formErrorValidationsConst} from "../../consts/formErrorValidations.const";
import {FilePondImageEditor} from "../FilePondImageEditor/FilePondImageEditor";
import {dirtyValuesUtil} from "../../utils/dirtyValues.util";
import {checkAndTransformDirtyFileUtil} from "../../utils/checkAndTransformDirtyFile.util";

const fileKey = "fileLocation"
/**
 *
 * @param data
 * @param readOnly used to show field like input or only just show data
 * @param isNew this parameter show new form fields like password
 * @param onSubmit
 * @returns {JSX.Element}
 * @constructor
 */
export default function BackofficeUser({data, readOnly = false, isNew = false, onSubmit}) {
    const {t} = useTranslation();
    const [files, setFiles] = useState(data["image"])
    const [roles, setRoles] = useState([])
    const {
        control,
        formState: {errors, dirtyFields},
        handleSubmit,
        watch,
    } = useForm({defaultValues: data});


    const onHandleSubmit = (dataForm) => {
        let dataToSubmit = dirtyValuesUtil(dirtyFields, dataForm)
        // this endpoint is different to others
        const file = checkAndTransformDirtyFileUtil(dataForm[fileKey], files, dataToSubmit, fileKey);
        if (file && file.uploadFiles) {
            dataToSubmit.image = file.uploadFiles.fileLocation
            delete dataToSubmit.uploadFiles
        }
        if (dataToSubmit.password === "") {
            delete dataToSubmit.password
        }
        //if we have file we need to transform it to base64
        onSubmit(dataToSubmit)
    }

    useEffect(() => {
        async function getRoles() {
            const {data: {data}} = await API("user-bo/user/roles");
            setRoles(data.map(el => ({label: el.name, value: el.name})))
        }

        getRoles();
    }, [])

    return <form onSubmit={handleSubmit(onHandleSubmit)}>
        <fieldset>
            <legend>{t("User data")}</legend>

            <div className={"py-4"}>
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="firstName" label={"First name"} control={control}
                                   errors={errors}
                                   rules={{required: true}}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="lastName" label={"Last name"} control={control}
                                   errors={errors}
                                   rules={{required: true}}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="email" label={"Email"} control={control} type={"email"}
                                   rules={{required: true}}
                                   errors={errors}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="telephone" label={"Phone"} control={control}
                                   type={"number"}
                                   rules={{required: true}}
                                   errors={errors}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="rol" label={"Role"} control={control}
                                   errors={errors}
                                   rules={{required: true}} options={roles} type={"select"}
                        />
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="gender" label={"Gender"} control={control}
                                   errors={errors}
                                   rules={{required: true}} options={gendersConst(t)} type={"select"}
                        />
                    </div>

                    {!readOnly && <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="password" label={"Password"} control={control}
                                   rules={{required: isNew, pattern: formErrorValidationsConst("password", t)}}
                                   errors={errors} type={"password"}/>
                    </div>}

                    {(watch("rol") === "Manager" || watch("rol") === "Group" ) && <fieldset>
                        <div className={"py-4"}>
                            <div className="row">
                                <div className="col-12">
                                    <FilePondImageEditor files={files} setFiles={setFiles} fileKey={fileKey}
                                                         required={watch("rol") === "Manager"}
                                                         readOnly={readOnly}
                                                         label={t("Photo")/* + " (" + t("Only when role is Manager") + ")"*/}/>
                                </div>
                            </div>
                        </div>
                    </fieldset>}


                    <div className="col-12">
                        <FormInput readOnly={readOnly} name="experience" label={"Experience"} control={control}
                                   errors={errors} type={"textarea"} rules={{required: watch("rol") === "Manager"}}/>
                    </div>
                    <div className="col-12">
                        <FormInput readOnly={readOnly} name="education" label={"Education"} control={control}
                                   errors={errors} type={"textarea"} rules={{required: watch("rol") === "Manager"}}/>
                    </div>
                    <div className="col-12">
                        <FormInput readOnly={readOnly} name="about" label={"About me"} control={control}
                                   errors={errors} type={"textarea"} rules={{required: watch("rol") === "Manager"}}/>
                    </div>


                </div>
            </div>
        </fieldset>


        {!readOnly && <div className={"d-flex justify-content-end mt-8"}>
            <Button label={t("Save")}/>
        </div>}
    </form>

}
