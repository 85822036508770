/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 04/01/2024
 * @description The `PolicyBillsTable` component displays a table of bills associated with a particular insurance policy. It fetches bill data based on the policy number and renders it in a paginated table format. Users can view bill details such as bill number, amount, due date, and status. The component includes functionality to fetch data lazily, ensuring efficient handling of large datasets.
 */
import {useEffect, useState} from "react";
import {API} from "../../queries/api";
import BillsTable from "../BillsTable/BillsTable";
import {checkAndReturnCorrectLazyTableValues} from "../../utils/checkAndReturnCorrectLazyTableValues.util";

export default function PolicyBillsTable({policy}) {
    const [bills, setBills] = useState([])

    const [lazyTable, setLazyTable] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
    });
    const onLazy = (event) => {
        const newLazyTable = checkAndReturnCorrectLazyTableValues(lazyTable, event);
        setLazyTable(newLazyTable);
        getLazyData(newLazyTable);
    };

    const getLazyData = async (event) => {
        if(policy.policyNumber){
        const {data} = await API(
            "policy/bill/list/" + policy.policyNumber
        );
        setBills(data);
        }
    };

    useEffect(() => {
        getLazyData(lazyTable);
    }, []);

    const tableHeader = () => (
        <div className={"d-flex justify-content-end"}>

        </div>
    );


    return <BillsTable data={bills} policy={policy} onLazy={onLazy} lazyTable={lazyTable}
                       isCreationDisabled={false} tableHeader={tableHeader}/>

}
