export const paymentChannelsConst = [
    {
        label: "BANK",
        value: "BANK"
    },   {
        label: "PARTNER",
        value: "PARTNER"
    },  {
        label: "COMPANY",
        value: "COMPANY"
    },  {
        label: "BROKERAGE",
        value: "BROKERAGE"
    },  {
        label: "CREDIT_ACCOUNT",
        value: "CREDIT_ACCOUNT"
    },  {
        label: "REFUND",
        value: "REFUND"
    },  {
        label: "OFFICE",
        value: "OFFICE"
    },  {
        label: "FEES",
        value: "FEES"
    },  {
        label: "TRANSFER",
        value: "TRANSFER"
    }
]
