/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 26/04/2023
 * @description The `FormInput` component handles various input types including text, email, password, number, textarea, select, multiselect, checkbox, radio, and search. It supports internationalization, validation rules, and dynamic behavior like opening email links in default client. Additionally, it provides feedback for validation errors and customizable input options.
 */
import {InputText} from "primereact/inputtext";
import {Controller} from 'react-hook-form';
import {classNames} from 'primereact/utils';
import {Dropdown} from "primereact/dropdown";
import {useTranslation} from "react-i18next";
import {MultiSelect} from "primereact/multiselect";
import {Checkbox} from "primereact/checkbox";
import {Calendar} from "primereact/calendar";
import {Password} from "primereact/password";
import {InputTextarea} from "primereact/inputtextarea";
import {InputNumber} from 'primereact/inputnumber';
import {useCallback} from "react";
import InlineSearch from "../InlineSearch/InlineSearch";
import VehicleBrandSearch from "../VehicleBrandSearch/VehicleBrandSearch";
import {RadioButton} from "primereact/radiobutton";

/**
 *
 * @param name
 * @param label
 * @param rules
 * @param errors
 * @param control
 * @param options
 * @param config Configuration from specific type
 * @param type We have a lot of types:
 * checkbox
 * text
 * number
 * password
 * textarea
 * select
 * multiselect
 * autocomplete: is similar to a select but with a search with autocomplete. Need service prop to work
 * car_brand: is a specific and complex autocomplete
 * email: is a text input but is readOnly = true state will be blue and if you click it a default email app will be
 * opened
 * onClick: same than email without opening default email app. This is useful to use with onClick prop to do
 * something on a click when readOnly = true
 * @param value value for radio
 * @param disabled
 * @param readOnly
 * @param service This is only used with InlineSearch to do searching. Is endpoint name
 * @param onChange
 * @param onClick
 * @param feedback
 * @param keys Only for search. Is to show user a text of different keys
 * @returns {JSX.Element}
 * @constructor
 */
export default function FormInput({
                                      name,
                                      label,
                                      rules = {},
                                      errors,
                                      control,
                                      options,
                                      config = {},
                                      type,
                                      value,
                                      disabled,
                                      readOnly,
                                      service,
                                      onChange = () => {
                                      },
                                      onClick = () => {
                                      },
                                      feedback = true,
                                      keys = ["name"]
                                  }) {
    const {t} = useTranslation();
    rules = type === "email" ? {
        ...rules, pattern: {
            value: /\S+@\S+\.\S+/,
            message: t("Introduce a valid email")
        }
    } : rules;
    const getFormErrorMessage = useCallback((name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> :
            <small className="p-error"/>;
    }, [errors]);

    const selectItemTemplate = useCallback((option, options) => {
        const localOption = option && option.label ? option.label : option && option.value ? option.value : options && option ? options.find(localOption => localOption.value === option).label : option;
        return (
            <span>{t(localOption)}</span>
        );
    }, [t]);
    return <Controller
        name={name}
        control={control}
        rules={rules}
        render={({field, fieldState}) => (
            <>
                {options && type === "multiselect" &&
                    <span className="p-float-label">
                        <MultiSelect id={field.name}
                                     value={field ? typeof field.value === "string" ? field.value.split(",") : field.value : ""}
                                     options={options}
                                     disabled={disabled}
                                     onChange={(e) => field.onChange(e.value.join(","))}
                                     placeholder={t("Select")}
                                     className={classNames({"p-multiselect-read-only": readOnly})}
                                     itemTemplate={selectItemTemplate}
                                     selectedItemTemplate={(option) => selectItemTemplate(option, options)}
                        />
                            <label htmlFor={field.name}>{t(label)} {rules.required && !readOnly ? "*" : ""}</label>
                    </span>
                }

                {(type === "select" || type === "autocomplete") &&
                    <span className="p-float-label">
                        <Dropdown
                            id={field.name}
                            value={field ? field.value : ""}
                            filter={type === "autocomplete"}
                            placeholder={t("Select")} options={options ? options : []} disabled={disabled}
                            control={control}
                            emptyMessage={t("No results")}
                            onChange={(e) => {
                                field.onChange(e.value);
                                onChange(e.value)
                            }}
                            className={classNames({'p-invalid': fieldState.error, "p-dropdown-read-only": readOnly})}
                            itemTemplate={selectItemTemplate}
                            valueTemplate={(option) => selectItemTemplate(option, options)}
                        />
                                                            <label
                                                                htmlFor={field.name}>{t(label)} {rules.required && !readOnly ? "*" : ""}</label>
                    </span>
                }
                {type === "checkbox" && < >

                    {!readOnly && <div className={"mt-9 d-flex align-items-center"} style={{height: "45px"}}>
                        <Checkbox inputId={field.name} checked={field.value} inputRef={field.ref}
                                  className={"me-4 " + classNames({
                                      'p-invalid mr-1': fieldState.error,
                                      "p-checkbox-read-only": readOnly
                                  })}
                                  onChange={(e) => field.onChange(e.checked)}/>
                        <label htmlFor={field.name}
                               className={classNames({'p-error': errors.checked})}>{t(label)} {rules.required && !readOnly ? "*" : ""}</label>
                    </div>}
                    {readOnly && <div className={"mt-7"}>
                        <label className={"ms-5 mb-5 d-block b-text b-text--label"}>{t(label)}</label>
                        <div className={"d-block"}>
                            {(field.value === "true" || field.value === true) &&
                                <span className="mx-5 material-symbols-outlined">done</span>}
                            {(field.value === "false" || field.value === false) &&
                                <span className="mx-5 material-symbols-outlined">close</span>}
                        </div>
                    </div>}
                </>}
                {type === "radio" && < >

                    <div className={"mt-9 d-flex align-items-center"} style={{height: "45px"}}>
                        <RadioButton inputId={field.name} checked={field.value === value} inputRef={field.ref}
                                     className={"me-4 " + classNames({
                                         'p-invalid mr-1': fieldState.error,
                                         "p-checkbox-read-only": readOnly
                                     })}
                                     value={value}
                                     onChange={(e) => field.onChange(e.value)}/>
                        <label htmlFor={field.name}
                               className={classNames({'p-error': errors.value})}>{t(label)} {rules.required && !readOnly ? "*" : ""}</label>
                    </div>

                </>}
                {type === "search" &&
                    <InlineSearch service={service} readOnly={readOnly} id={field.name} value={field.value}
                                  disabled={!readOnly && disabled}
                                  label={label}
                                  searchKey={config.searchKey ? config.searchKey : "name"}
                                  rules={rules}
                                  keys={keys}
                                  className={classNames({
                                      'p-invalid': fieldState.error,
                                      "b-inline-search__input": true
                                  })}
                                  onChange={field.onChange}/>}
                {(type === "car_brand" || type === "car_model" || type === "car_version" || type === "motorbike_brand" || type === "motorbike_model" || type === "motorbike_version") &&
                    <VehicleBrandSearch typeOfVehicle={type.substring(0, 3) === "car" ? "car" : "motorbike"}
                                        readOnly={readOnly} id={field.name} value={field.value}
                                        disabled={!readOnly && disabled}
                                        label={label}
                                        className={classNames({
                                            'p-invalid': fieldState.error,
                                            "b-inline-search__input": true
                                        })}
                                        type={type}
                                        onChange={field.onChange}/>}
                {!options && (!type || type === "email" || type === "onClick" || type === "password" || type === "textarea" || type === "number" || type === "decimal" || type === "calendar") && <>
                    <label htmlFor={field.name} className={classNames({'p-error': errors.value})}/>
                    <span className={classNames({
                        "p-float-label": true,
                        'u-cursor-pointer': (type === "email" || type === "onClick") && readOnly === true, // if is
                        // email will
                        // clickable and
                        // opened with default client email
                    })} onClick={() => {
                        defaultBehaviors(field.value, type, readOnly);
                        onClick()
                    }}>
                        {type === "calendar" && <Calendar
                            locale="es"
                            id={field.name}
                            readOnlyInput={readOnly}
                            disabled={!readOnly && disabled}
                            value={field.value}
                            className={classNames({'p-invalid': fieldState.error})}
                            onChange={field.onChange}
                            showTime={!!config.hourFormat}
                            hourFormat={config.hourFormat ? config.hourFormat : undefined}
                            dateFormat="dd/mm/yy"
                            placeholder="dd/mm/yyyy"
                            minDate={rules.minDate}
                            maxDate={rules.maxDate}
                        />}
                        {type === "password" && <Password readOnly={readOnly} id={field.name} value={field.value}
                                                          disabled={!readOnly && disabled} toggleMask
                                                          weakLabel={t("Weak")} mediumLabel={t("Medium")}
                                                          strongLabel={t("Strong")}
                                                          feedback={feedback}
                                                          className={classNames({'p-invalid': fieldState.error})}
                                                          onChange={(e) => field.onChange(e.target.value)}/>}

                        {(!type || type === "email" || type === "onClick") &&
                            <InputText readOnly={readOnly} id={field.name}
                                       value={config.isTranslationNecessary ? t(field.value) : field.value}
                                       disabled={!readOnly && disabled}
                                       className={classNames({
                                           'p-invalid': fieldState.error,
                                           'u-color-info': (type === "email" || type === "onClick") && readOnly === true, // if is email will
                                           // clickable and
                                           // opened with default client email
                                       })}
                                       onChange={(e) => field.onChange(e.target.value)}/>}
                        {(type === "number" || type === "decimal") &&
                            <InputNumber readOnly={readOnly} id={field.name} value={field.value}
                                         disabled={!readOnly && disabled}
                                         minFractionDigits={type === "decimal" ? 2: 0}
                                         maxFractionDigits={type === "decimal" ? 2: 0}
                                         onBlur={field.onBlur}
                                         useGrouping={false}
                                         className={classNames({'p-invalid': fieldState.error})}
                                         onChange={(e) => field.onChange(e.value)}/>}
                        {type === "textarea" && <InputTextarea readOnly={readOnly} id={field.name} value={field.value}
                                                               disabled={!readOnly && disabled} rows={5}
                                                               className={classNames({'p-invalid': fieldState.error})}
                                                               onChange={(e) => field.onChange(e.target.value)}/>}
                        <label htmlFor={field.name}>{t(label)} {rules.required && !readOnly ? "*" : ""}</label>
                            </span>
                    {getFormErrorMessage(field.name)}
                </>}

            </>
        )}
    />
}


const defaultBehaviors = (value, type, readOnly) => {
    if (readOnly === true && type === "email" && window) {
        window.location.href = "mailto:" + value;
    }
}
