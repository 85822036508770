/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 31/05/2023
 * @descriptionThe This component represents a page for viewing and managing insurance policies. It allows users to create new policies or edit existing ones. It displays information such as policy number and policy type. Users can also view related data such as claims, proposals, tickets, and history. The component includes tabs for easy navigation between different sections of policy-related information.
 */
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Policy from "../../components/Policy/Policy";
import {TabPanel, TabView} from "primereact/tabview";
import {useNavigate, useParams} from "react-router-dom";
import {API} from "../../queries/api";
import {transformDynamicData} from "../../utils/transformDynamicDataUtil";
import PolicyClaimsTable from "../../components/PolicyClaimsTable/PolicyClaimsTable";
import PolicyProposalsTable from "../../components/PolicyProposalsTable/PolicyProposalsTable";
import PolicyBillsTable from "../../components/PolicyBillsTable/PolicyBillsTable";
import PolicyTasksTable from "../../components/PolicyTasksTable/PolicyTasksTable";
import {hashFeature} from "../../utils/primereactTab.util";

export default function PoliciesDetailPage() {
    const [policy, setPolicy] = useState()
    const [policyInsurance, setPolicyInsurance] = useState()
    const [policyUser, setPolicyUser] = useState()
    const {t} = useTranslation();
    const {id, action, userId} = useParams();
    const [activeIndex, setActiveIndex] = useState()

    const navigate = useNavigate();

    const onSubmit = async (data, dataRiskForm, policyTypeId) => {
        if (id === "new") {
            await API.post("policy/policy/create", {...data, userId});
        } else {
            await API.put("policy/policy/update/" + id, data);
        }

        await saveRiskForm(policyTypeId, dataRiskForm);
        navigate("/policies")
    }


    const getPolicy = async (id) => {
        if (id && isFinite(id)) {
            const {
                data: policy
            } = await API("policy/policy/view/" + id + "?extraFields=risks,policyType");
            setPolicy(policy);
            await getInsurance(policy.companyId)
            await getUser(policy.userId)
        } else { //for edition
            setPolicy({policy});
        }
    };


    const getInsurance = async (id) => {
        const {
            data: policyInsurance
        } = await API("company/company/view/" + id);
        setPolicyInsurance(policyInsurance)
    };


    const getUser = async (id) => {
        const {
            data: policyUser
        } = await API("user/user/view/" + id);
        setPolicyUser(policyUser)
    };


    const saveRiskForm = async (policyTypeId, dataRiskForm) => {
        if (Object.keys(dataRiskForm).length !== 0) {
            const {data: resData} = await API.put(`policy/policy/risk/update/${policyTypeId}/${policy.id}`, transformDynamicData(dataRiskForm));
        }
    }

    useEffect(() => {
        getPolicy(id);
    }, []);

    return <>
        {policy && <>

            <div className={"b-box"}>
                <h2 className={"mb-8"}>{id === "new" && t("Creation of")} {t("Policy")} {policy.policyNumber} {id !== "new" && "(" + policy.id + ")"}</h2>

                <Policy data={policy} readOnly={!(id === "new" || action === "edit")} isNew={id === "new"}
                        onSubmit={onSubmit}
                />

            </div>

            {id !== "new" && !action && policyInsurance && policyUser && <div className="mt-8 b-box">

                <TabView {...hashFeature(activeIndex, setActiveIndex)}>
                    <TabPanel header={t("Claims")}>
                        <PolicyClaimsTable policy={policy} policyInsurance={policyInsurance} policyUser={policyUser}/>
                    </TabPanel>
                    <TabPanel header={t("Proposals")}>
                        <PolicyProposalsTable policyId={policy.id} policyInsurance={policyInsurance}
                                              policyUser={policyUser}
                                              policyTypeId={policy.policyTypeId}></PolicyProposalsTable>
                    </TabPanel>

                    <TabPanel header={t("Tickets")}>
                        <PolicyBillsTable policy={policy}></PolicyBillsTable>
                    </TabPanel>

                    <TabPanel header={t("History")}>
                        <PolicyTasksTable policy={policy}></PolicyTasksTable>
                    </TabPanel>

                </TabView>
            </div>}


        </>
        }
    </>
}

