/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 10/05/2023
 * @description The `InsuranceFilesTable` component manages insurance-related files. It allows users to view and delete files associated with a specific insurance company. The component fetches file data from an API endpoint based on the provided insurance ID. Users can navigate to view individual files or delete them directly from the table. Additionally, users can create new files using a button in the table header. The table supports pagination, sorting, and filtering of file data.
 */
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {useTranslation} from "react-i18next";
import ActionsOverlayMenu from "../ActionsOverlayMenu/ActionsOverlayMenu";
import {Link, useNavigate} from "react-router-dom";
import {Button} from "primereact/button";
import {useEffect, useState} from "react";
import {API} from "../../queries/api";
import {createFilterParamsUtil} from "../../utils/createFilterParams.util";
import {deleteTableRowUtil} from "../../utils/deleteTableRow.util";
import {checkAndReturnCorrectLazyTableValues} from "../../utils/checkAndReturnCorrectLazyTableValues.util";

export default function InsuranceFilesTable({insuranceId}) {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [files, setFiles] = useState([])

    const [lazyTable, setLazyTable] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
    });

    const onLazy = (event) => {
        const newLazyTable = checkAndReturnCorrectLazyTableValues(lazyTable, event);
        setLazyTable(newLazyTable);
        getLazyData(newLazyTable);
    };

    const getLazyData = async (event) => {
        const {data: {data: files, count}} = await API(
            "company/company-file/list/" + createFilterParamsUtil(event) + "&filter[companyId]=" + insuranceId
        );
        setLazyTable({...event, totalRecords: count})
        setFiles(files);
    };

    useEffect(() => {
        getLazyData(lazyTable);
    }, []);


    const InsuranceFilesActions = (rowData) => {
        const items = [
            {
                label: t("See"),
                icon: <span className="me-4 material-symbols-outlined">visibility</span>,
                command: () => navigate("./files/" + rowData.id)

            }
            , {
                label: t("Remove"),
                icon: <span className="me-4 material-symbols-outlined">delete</span>,
                command: () => deleteTableRowUtil(rowData.id, "company/company-file/delete/" + rowData.id, files, setFiles),
            }
        ];
        return <ActionsOverlayMenu items={items}/>
    }

    const tableHeader = () => <div className={"d-flex justify-content-end"}>
        <Link to={"./files/new"}>
            <Button label={t("Create file")}></Button>
        </Link>
    </div>

    return <div>
        <DataTable emptyMessage={t("No results found")}
                   header={tableHeader}
                   value={files}
                   filterDisplay="row"
                   dataKey="id"
                   paginator
                   totalRecords={lazyTable.totalRecords}
                   rows={lazyTable.rows}
                   onPage={onLazy}
                   onFilter={onLazy}
                   onSort={onLazy}
                   lazy
                   first={lazyTable.first}
                   sortOrder={lazyTable.sortOrder}>
            <Column field="id" filter filterMatchMode="contains" filterPlaceholder={t("Search")}
                    header={t("ID")}/>
            <Column field="title" filter filterMatchMode="contains" filterPlaceholder={t("Search")}
                    header={t("Title")}/>
            <Column field="policyTypeId" filter filterMatchMode="contains" filterPlaceholder={t("Search")}
                    header={t("Branch")}/>
            <Column field="type" filter filterMatchMode="contains" filterPlaceholder={t("Search")}
                    header={t("Type")} body={(rowData) => t(rowData.type)}/>

            <Column header={t("Actions")} body={InsuranceFilesActions}/>
        </DataTable>
    </div>
}
