export const paymentFrequencyConst = [
    {
        label: "ANNUAL",
        value: "ANNUAL",
        alt: "Anual",
        fractional: 1
    }, {
        label: "HALF_YEARLY",
        value: "HALF_YEARLY",
        alt: "Semestral",
        fractional: 2

    }, {
        label: "FOUR_MONTHLY",
        value: "FOUR_MONTHLY",
        alt:"Cuatrimestral",
        fractional: 3

    }, {
        label: "QUARTERLY",
        value: "QUARTERLY",
        alt:"Trimestral",
        fractional: 4

    }, {
        label: "BIMONTHLY",
        value: "BIMONTHLY",
        alt:"Bimensual",
        fractional: 6

    }, {
        label: "MONTHLY",
        value: "MONTHLY",
        alt:"Mensual",
        fractional: 12

    }, {
        label: "Fortnightly",
        value: "FORTNIGHTLY",
        alt:"Quincenal", //not provided by prudenzia
        fractional: 24

    }, {
        label: "WEEKLY",
        value: "WEEKLY",
        alt:"Semanal", //not provided by prudenzia
        fractional: 48

    }, {
        label: "UNIQUE",
        value: "UNIQUE",
        alt:"Única",
        fractional: 1

    }
]
