/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 11/10/2023
 * @description The `Claim` component renders a form for managing insurance claims. It displays user and policy data, along with basic claim information. Users can edit these details if not in read-only mode. It dynamically renders additional data based on policy type. Upon submission, it saves the form data.
 */
import FormInput from "../FormInput/FormInput";
import {Button} from "primereact/button";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {API} from "../../queries/api";
import {checkAndTransformDirtyFileUtil} from "../../utils/checkAndTransformDirtyFile.util";
import {dirtyValuesUtil} from "../../utils/dirtyValues.util";
import DynamicData from "../DynamicData/DynamicData";
import {transformArrayToObjUtil} from "../../utils/transformArrayToObjUtil";
import {useNavigate} from "react-router-dom";

const fileKey = "fileLocation"

/**
 *
 * @param data
 * @param policyTypeId
 * @param policyUser
 * @param readOnly used to show field like input or only just show data
 * @param onSubmit
 * @param isNew
 * @returns {JSX.Element}
 * @constructor
 */
export default function Claim({
                                  data, policy, policyUser, company, readOnly = false, onSubmit = () => {
    }, isNew
                              }) {
    const [files, setFiles] = useState(data[fileKey])
    const [policyTypes, setPolicyTypes] = useState([])
    const {t} = useTranslation();
    const navigate = useNavigate();


    const {
        control,
        formState: {errors, dirtyFields},
        handleSubmit,
    } = useForm({
        defaultValues: {
            ...data,
            initialEffectiveDate: new Date(data.initialEffectiveDate),
            effectiveDate: new Date(data.effectiveDate),
            expirationDate: new Date(data.expirationDate),
        }
    });

    const {
        control: controlRisk,
        setValue,
        formState: {errors: errorsRisk},
        handleSubmit: handleSubmitRisk,
        watch: watchRisk,
        reset: resetRisk
    } = useForm(data && data.risks ? {
        defaultValues: {

            ...transformArrayToObjUtil([...data.risks])
        }
    } : {});

    const {
        control: controlUser,
        formState: {errors: errorsUser},
    } = useForm(policyUser ? {
        defaultValues: {
            ...policyUser,
            fullName: (policyUser.name ? policyUser.name : "") + " " + (policyUser.firstSurname ? policyUser.firstSurname : "") + " " + (policyUser.secondSurname ? policyUser.secondSurname : "")
        }
    } : {});

    const {
        control: controlPolicy,
        formState: {errors: errorsPolicy},
    } = useForm(policy ? {
        defaultValues: {
            ...policy,
        }
    } : {});
    const {
        control: controlCompany,
        formState: {errors: errorsCompany},
    } = useForm(company ? {
        defaultValues: {
            ...company,
        }
    } : {});


    const {
        control: controlPolicyType,
        formState: {errors: errorsPolicyType},
    } = useForm(policy ? {
        defaultValues: {
            ...policy.policyType,
        }
    } : {});


    const onHandleSubmit = (dataForm) => {
        handleSubmitRisk(async (dataRisk) => {
                try {
                    //check we have data

                    let dataToSubmit = dirtyValuesUtil(dirtyFields, dataForm)
                    //if we have file we need to transform it to base64
                    dataToSubmit = checkAndTransformDirtyFileUtil(dataForm[fileKey], files, dataToSubmit, fileKey);
                    onSubmit({
                        ...dataToSubmit,
                    }, dataRisk, policy.policyTypeId)
                } catch
                    (e) {
                    console.error(e)
                }

            }
        )
        ()
    }


    useEffect(() => {
        async function getPolicyTypes() {
            const {data} = await API("policy/policy-type/list");
            setPolicyTypes(data.map(item => ({label: item.name, value: item.id})))
        }

        getPolicyTypes();
    }, [])

    const resetRiskForm = () => {
        resetRisk(createResetStructure(watchRisk()))
    }

    const goToUser = () => {
        navigate("/users/" + policyUser.id)
    }
    const goToPolicy = () => {
        navigate("/policies/" + policy.id)
    }
    return <form onSubmit={handleSubmit(onHandleSubmit)}>
        {policyUser && Object.keys(policyUser).length !== 0 && <fieldset>
            <legend>{t("User data")}</legend>

            <div className={"py-4"}>
                <div className="row">

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={true} name="fullName" label={"User"}
                                   control={controlUser} type={"onClick"}
                                   errors={errorsUser} onClick={goToUser}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={true} name="nif" label={"NIF"}
                                   control={controlUser}
                                   errors={errorsUser}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={true} name="email" label={"Email"}
                                   control={controlUser}
                                   errors={errorsUser}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={true} name="mobile" label={"Mobile"}
                                   control={controlUser}
                                   errors={errorsUser}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={true} name="allowCall" label={"Allow calls"}
                                   control={controlUser} type={"checkbox"}
                                   errors={errorsUser}/>
                    </div>

                </div>

            </div>
        </fieldset>}

        {policy && Object.keys(policy).length !== 0 && <fieldset>
            <legend>{t("Policy data")}</legend>

            <div className={"py-4"}>
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={true} name="policyNumber" label={"Policy number"}
                                   control={controlPolicy} onClick={goToPolicy} type={"onClick"}
                                   errors={errorsPolicy}
                                   rules={{required: true}}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={true} name="name" label={"Insurance"}
                                   control={controlCompany}
                                   errors={errorsCompany}
                                   rules={{required: true}}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={true} name="name" label={"Branch"}
                                   control={controlPolicyType}
                                   errors={errorsPolicyType}
                                   rules={{required: true}}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={true} name="referenceValue" label={"Reference"}
                                   control={controlPolicy}
                                   errors={errorsPolicy}
                                   rules={{required: true}}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={true} name="annualPayment" label={"Annual premium (€)"}
                                   control={controlPolicy}
                                   errors={errorsPolicy}
                                   rules={{required: true}}/>
                    </div>

                </div>

            </div>
        </fieldset>}

        <fieldset>
            <legend>{t("Basic data")}</legend>

            <div className={"py-4"}>
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="referenceNumber" label={"Reference number"}
                                   control={control}
                                   errors={errors}
                                   rules={{required: true}}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="companyAcceptance" label={"Require company" +
                            " acceptance"} control={control} errors={errors}
                                   type={"checkbox"}
                        />
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="companyNotification" label={"Notification company"}
                                   control={control} errors={errors}
                                   type={"checkbox"}
                        />
                    </div>
                </div>

            </div>
        </fieldset>


        <DynamicData control={controlRisk} errors={errorsRisk} readOnly={readOnly}
                     policyTypeId={policy.policyTypeId}
                     formData={watchRisk()} type={"CLAIM"} setValue={setValue}/>


        {!readOnly && !(data.status === "CANCELED" || data.status === "CLOSED") &&
            <div className={"d-flex justify-content-end mt-8"}>
                <Button label={t("Save")}/>
            </div>}
    </form>

}

const createResetStructure = (formData) => {
    const structure = {};
    for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
            structure[key] = ""
        }
    }
    return structure
}
