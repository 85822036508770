/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 15/10/2023
 * @description The `InsurancesTable` component is responsible for displaying a table of insurance data. It includes functionalities such as pagination, sorting, and filtering of insurance records. Users can view, edit, or delete individual insurance entries. Additionally, there's an option to create a new insurance entry.
 *
 * Here's a summary of its features:
 * - Displays insurance data in a table format.
 * - Supports pagination, sorting, and filtering of records.
 * - Provides actions for viewing, editing, and deleting insurance entries.
 * - Includes a filter for the "Mediation" field with a multi-select dropdown.
 * - Includes a checkbox column for indicating visibility on the app.
 *
 * Overall, the component provides a user-friendly interface for managing insurance records efficiently.
 */
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Link, useNavigate} from "react-router-dom";
import ActionsOverlayMenu from "../ActionsOverlayMenu/ActionsOverlayMenu";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";
import {BodyTemplateCheckbox} from "../BodyTemplateCheckbox/BodyTemplateCheckbox";
import {MultiSelect} from "primereact/multiselect";
import {mediationsConst} from "../../consts/mediations.const";

export default function InsurancesTable({data, onLazy, lazyTable, onDelete}) {
    const {t} = useTranslation();
    const navigate = useNavigate();


    const representativeRowFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={mediationsConst(t)}
                onChange={(e) => options.filterApplyCallback(e.value)}
                placeholder={t("Select")}
                className="p-column-filter"
                maxSelectedLabels={1}
            />
        );
    };


    const InsurancesActions = (rowData) => {
        const items = [
            {
                label: t("See"),
                icon: (
                    <span className="me-4 material-symbols-outlined">visibility</span>
                ),
                command: () => navigate("/insurances/" + rowData.id),
            },
            {
                label: t("Edit"),
                icon: <span className="me-4 material-symbols-outlined">edit</span>,
                command: () => navigate("/insurances/" + rowData.id + "/edit"),
            }, {
                label: t("Remove"),
                icon: <span className="me-4 material-symbols-outlined">delete</span>,
                command: () => onDelete(rowData.id),
            }

        ];
        return <ActionsOverlayMenu items={items}/>;
    };


    const tableHeader = () => (
        <div className={"d-flex justify-content-end"}>
            <Link to={"./new"}>
                <Button label={t("Create insurance")}></Button>
            </Link>
        </div>
    );

    return <DataTable emptyMessage={t("No results found")}
                      header={tableHeader}
                      value={data}
                      filterDisplay="row"
                      dataKey="id"
                      paginator
                      rows={lazyTable.rows}
                      totalRecords={lazyTable.totalRecords}
                      onPage={onLazy}
                      onFilter={onLazy}
                      onSort={onLazy}
                      lazy
                      first={lazyTable.first}
                      sortOrder={lazyTable.sortOrder}
    >
        <Column
            field="id"

            filter
            filterMatchMode="contains"
            filterPlaceholder={t("Search")}
            header={t("ID")}
        />
        <Column
            field="name"

            filter
            filterMatchMode="contains"
            filterPlaceholder={t("Search")}
            body={(rowData) => <Link to={"/insurances/" + rowData.id}>{rowData.name}</Link>}
            header={t("Name")}
        />
        <Column
            field="businessName"

            filter
            filterMatchMode="contains"
            filterPlaceholder={t("Search")}
            header={t("Business name")}
        />
        <Column
            field="fiscalName"

            filter
            filterMatchMode="contains"
            filterPlaceholder={t("Search")}
            header={t("Fiscal name")}
        />
        <Column
            field="nif"

            filter
            filterMatchMode="contains"
            filterPlaceholder={t("Search")}
            header={t("NIF")}
        />
        <Column
            field="mediateMode"
            filterMatchMode="in"
            filter
            body={(rowData) => t(rowData.mediateMode)}
            header={t("Mediation")}
            filterElement={representativeRowFilterTemplate}
        />
        <Column
            filterPlaceholder={t("Search")}
            header={t("Visible on app")}
            body={(rowData) => <BodyTemplateCheckbox isOk={rowData.visible}/>}
        />
        <Column header={t("Actions")} style={{width: "160px"}} body={InsurancesActions}/>
    </DataTable>
}
