import axios from "axios";
import {getCookieUtil} from "../utils/getCookie.util";
import {removeCookieUtil} from "../utils/removeCookieUtil";
import {updateUserUtil} from "../utils/updateUser.util";

export const APIHeaders = {
    // Accept: "application/json",
    // "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
    // "Accept-Language": {
    //   toString() {
    //     return i18next.language;
    //   },
    // },
    // Authorization: {
    //     toString () {
    //         return getToken();
    //     },
    // },
};

export const API = axios.create({
    baseURL: process.env.REACT_APP_BACK_URL,
    timeout: 1000000,
    headers: APIHeaders,
});


export const logout = () => {
    removeCookieUtil("token")
    removeCookieUtil("tokenTime")
    removeCookieUtil("refreshToken")
    removeCookieUtil("refreshTokenTime")
    window.location.pathname = '/login'
}

export const refreshToken = async () => {
    try {
        const refreshToken = getCookieUtil('refreshToken')
        const {data: res} = await API.post("user/refresh", {token: refreshToken});
        updateUserUtil(null, null, null, res.access_token, res.expires_in, res.refresh_token, res.refresh_expires_in)
        API.defaults.headers.Authorization = 'Bearer ' + res.access_token;
    } catch (err) {
        logout();
    }
}
