/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 16/10/2023
 * @descriptionThe The `VehicleBrandSearch` component facilitates searching for vehicle brands or models based on the specified type of vehicle. It dynamically fetches brand or model data from the API based on user input and displays the results in a dropdown list. Users can select an item from the list, which triggers the `onChange` function with the selected item's ID. The component also supports displaying the selected item's name and handles scenarios such as clearing the search input and setting initial values.
 */
import {useContext, useEffect, useState} from "react";
import {API} from "../../queries/api";
import CommonSearch from "../CommonSearch/CommonSearch";
import {VehicleContext} from "../DynamicData/DynamicData";

export default function VehicleBrandSearch({value, typeOfVehicle, type,label, id, onChange, readOnly, disabled, className}) {
    const [showingText, setShowingText] = useState("");
    const [showingList, setShowingList] = useState([]);
    const {vehicle, setVehicle} = useContext(VehicleContext);
    const onSearch = async (e) => {
        const targetValue = e.target.value;
        setShowingText(targetValue)
        let newData;
        if (targetValue !== "") {
            if (type === typeOfVehicle + "_brand") {
                const {data} = await API(
                    `data/${typeOfVehicle}/brand/list?search=` + targetValue
                );
                newData = data;
            } else if (type === typeOfVehicle + "_model") {
                const {data} = await API(
                    `data/${typeOfVehicle}/model/list/${vehicle[typeOfVehicle + "_brand"]}?search=` + targetValue
                );
                newData = data;
            }

            setShowingList(newData)
        } else {
            setShowingList([])
        }
    }

    const selectItem = (item) => {
        onChange(item.id);
        setVehicle({...vehicle, [type]: item.id})
        setShowingText(item.name)
        setShowingList([])
    }


    useEffect(() => {
        async function getDetail() {
            if (value) {
                if (type === typeOfVehicle + "_brand") {

                    const {data} = await API(
                        `data/${typeOfVehicle}/brand/list`
                    );
                    const itemFound = data.find(item => item.id === Number(value))
                    if (itemFound) {
                        setShowingText(itemFound.name)
                    }
                } else if (type === typeOfVehicle + "_model") {
                    const {data} = await API(
                        `data/${typeOfVehicle}/model/list/${vehicle[typeOfVehicle + "_brand"]}`
                    );
                    const itemFound = data.find(item => item.id === Number(value))
                    if (itemFound) {
                        setShowingText(itemFound.name)
                    }
                }
            }
        }

        getDetail();
    }, [])

    return <CommonSearch showingList={showingList} onSearch={onSearch} showingText={showingText} selectItem={selectItem} label={label}
                         className={className} disabled={disabled} id={id} readOnly={readOnly}/>

}
