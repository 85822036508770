/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 29/09/2023
 * @description The `ProposalBudget` component manages proposal budget details, including company, insurance files, bill frequency, amounts, PDF attachments, and coverages. It dynamically fetches data, validates input, and supports multiple languages. Users can save changes via a "Save" button.
 */
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import FormInput from "../FormInput/FormInput";
import {useEffect, useState} from "react";
import {API} from "../../queries/api";
import {paymentFrequencyConst} from "../../consts/paymentFrequency.const";
import {InputNumber} from "primereact/inputnumber";
import {FilePondImageEditor} from "../FilePondImageEditor/FilePondImageEditor";
import {dirtyValuesUtil} from "../../utils/dirtyValues.util";
import {checkAndTransformDirtyFileUtil} from "../../utils/checkAndTransformDirtyFile.util";
import {useParams} from "react-router-dom";
import {Button} from "primereact/button";

const fileKey = "fileLocation"

export default function ProposalBudget({data, readOnly, onSubmit}) {
    const [companyFiles, setCompanyFiles] = useState()
    const [companies, setCompanies] = useState()
    const [coverages, setCoverages] = useState()
    const [annualPrice, setAnnualPrice] = useState()
    const [files, setFiles] = useState(data[fileKey])
    const {id: idProposal} = useParams();
    const {t} = useTranslation()

    const {
        control,
        formState: {errors, dirtyFields},
        handleSubmit,
        watch
    } = useForm({
        defaultValues: data
    });

    const {
        control: controlCoverages,
        formState: {errors: errorsCoverages},
        watch: watchCoverages
    } = useForm({});

    const onHandleSubmit = async (dataForm) => {
        let dataToSubmit = dirtyValuesUtil(dirtyFields, dataForm)
        // if we have file we need to transform it to base64
        dataToSubmit = checkAndTransformDirtyFileUtil(dataForm[fileKey], files, dataToSubmit, fileKey);

        if (dataToSubmit.companyId) {
            dataToSubmit.companyId = Number(dataToSubmit.companyId)
        }

        if (dataToSubmit.companyFileId) {
            dataToSubmit.companyFileId = Number(dataToSubmit.companyFileId)
        }

        const coverages = transformCoverages(watchCoverages())
        onSubmit(dataToSubmit, coverages)
    }


    useEffect(() => {
        const frequency = watch("frequency");
        const firstBill = watch("firstBill");
        const otherBills = watch("otherBills");
        if (frequency) {
            const fractional = paymentFrequencyConst.find(paymentFrequency => paymentFrequency.value === frequency).fractional;
            if (fractional === 1 && firstBill) {
                setAnnualPrice(firstBill)
            } else if (firstBill && otherBills) {
                setAnnualPrice((otherBills * fractional) + firstBill)
            } else {
                setAnnualPrice(null)
            }
        }
    }, [watch("otherBills"), watch("firstBill"), watch("frequency")])


    useEffect(() => {
        const getCompanies = async () => {
            try {
                const {data} = await API("company/company/all");
                setCompanies(data.map(item => ({label: item.name, value: item.id.toString()})))
            } catch (e) {
                console.log(e)
            }
        }

        const getCoverages = async (policyTypeId) => {
            try {
                const {data: {data: coverages}} = await API("policy/policy-type/coverage/list/" + policyTypeId);
                setCoverages(coverages)
            } catch (e) {
                console.log(e)
            }
        }

        async function getProposal(id) {
            try {
                return (await API("policy/proposal/view/" + id)).data;
            } catch (e) {
                console.log(e)
            }
        }

        const init = async () => {
            await getCompanies();
            const proposal = await getProposal(idProposal)
            await getCoverages(proposal.policyTypeId)

        }

        init()


    }, [])


    useEffect(() => {
        async function getFiles() {
            try {
                const {data: {data}} = await API(`company/company-file/list?filter[type]=GENERAL_CONDITION&filter[companyId]=${watch("companyId")}`);
                setCompanyFiles(data.map(item => ({label: item.title, value: item.id.toString()})))
            } catch (e) {
                console.log(e)
            }
        }

        getFiles()

    }, [watch("companyId")]);

    return <form onSubmit={handleSubmit(onHandleSubmit)}>
        <fieldset>
            <legend>{t("Basic data")}</legend>

            <div className={"py-4"}>
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="companyId" label={"Insurances allowed"} control={control}
                                   errors={errors} type={"select"} options={companies} rules={{required: true}}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="companyFileId" label={"Insurance file"} control={control}
                                   disabled={!watch("companyId")}
                                   errors={errors} type={"select"} options={companyFiles}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="name" label={"Name"} control={control}
                                   errors={errors} rules={{required: true}}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="modalityName" label={"Modality"} control={control}
                                   errors={errors} rules={{required: true}}/>
                    </div>

                </div>
            </div>
        </fieldset>

        <fieldset>
            <legend>{t("Bills")}</legend>

            <div className={"py-4"}>
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="frequency" label={"Frequency"} control={control}
                                   errors={errors}
                                   rules={{required: true}} type={"select"} options={paymentFrequencyConst}/>
                    </div>


                    <div className="col-12 col-lg-4">
                        <span className="p-float-label">
                             <InputNumber value={annualPrice}
                                          disabled={true}
                                          readOnly={readOnly}
                                          useGrouping={false}/>
                             <label>{t("Fractional premium")}</label>
                        </span>

                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="firstBill" label={"First bill"}
                                   control={control} errors={errors} type={"decimal"}
                                   rules={{required: true}}/>
                    </div>

                    {(watch("frequency") !== "ANNUAL" && watch("frequency") !== "UNIQUE") &&
                        <div className="col-12 col-lg-4">
                            <FormInput readOnly={readOnly} name="otherBills" label={"Other bills"}
                                       control={control} errors={errors} type={"decimal"}
                                       rules={{required: true}}/>
                        </div>}

                </div>
            </div>
        </fieldset>


        <fieldset>
            <legend>{t("Attach PDF")}</legend>

            <div className={"py-4"}>
                <div className="row">
                    <div className="col-12">
                        <FilePondImageEditor files={files} setFiles={setFiles} fileKey={fileKey} readOnly={readOnly}
                                             acceptedFileTypes={["application/pdf"]}/>
                    </div>
                </div>
            </div>
        </fieldset>

        {coverages && <fieldset>
            <legend>{t("Coverages")}</legend>

            <div className={"py-4"}>
                <div className="row">
                    {coverages.map((coverage, index) => <div className="col-12 col-lg-4" key={index}>
                        <FormInput readOnly={readOnly} name={coverage.id.toString()} label={coverage.name}
                                   control={controlCoverages}
                                   errors={errorsCoverages}
                                   type={"checkbox"}/>
                        <FormInput readOnly={readOnly} name={coverage.id.toString() + "-description"}
                                   label={t("Description")}
                                   control={controlCoverages}
                                   errors={errorsCoverages}
                                   type={"textarea"}/>
                    </div>)}
                < /div>

            </div>
        </fieldset>}

        {!readOnly && <div className={"d-flex justify-content-end mt-8"}>
            <Button label={t("Save")}/>
        </div>}
    </form>
}

const transformCoverages = (coverages) => {
    const newCoverages = []
    for (const key in coverages) {
        const coverage = coverages[key]
        if (coverage === true) {
            const coverageDescription = coverages[key + "-description"];
            newCoverages.push({coverageId: Number(key), description: coverageDescription ? coverageDescription : ""})
        }

    }
    return newCoverages;
}
