export const getCookieUtil = (cookieName) => {
      let cookieValue = document.cookie.match(
        "(^|;)\\s*" + cookieName + "\\s*=\\s*([^;]+)"
      );
  
      cookieValue = cookieValue ? cookieValue.pop() : "";
      return cookieValue.length &&
        (cookieValue[0] === "{" || cookieValue[0] === "[")
        ? JSON.parse(cookieValue)
        : cookieValue;
  };