/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 29/09/2023
 * @description The `InsuranceFilesTable` component manages insurance-related files. It allows users to view and delete files associated with a specific insurance company. The component fetches file data from an API endpoint based on the provided insurance ID. Users can navigate to view individual files or delete them directly from the table. Additionally, users can create new files using a button in the table header. The table supports pagination, sorting, and filtering of file data.
 */
import "./Loading.scss"
import { useContext } from "react";
import { GlobalContext } from "../../App";

export default function Loading () {
    const { isLoading } = useContext(GlobalContext)
    return <div className={"c-loading" + (isLoading ? "" : " c-loading--hide")}>
        <span className="loader"></span>

    </div>
}
