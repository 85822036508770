export const mediationsConst = (t) => [
    {
        label: t("YES"),
        value: "YES"
    },   {
        label: t("NO_PERMANENTLY"),
        value: "NO_PERMANENTLY"
    },   {
        label: t("NO_TEMPORARY"),
        value: "NO_TEMPORARY"
    },   {
        label: t("UNKNOWN"),
        value: "UNKNOWN"
    }
]
