/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 14/07/2023
 * @description The `RequireAuth` component ensures that its children are only rendered if the user is authenticated. It checks for the presence of a token cookie and refreshes it if necessary using a backend endpoint. If the refresh is successful, it updates the user's authentication status. Otherwise, it redirects the user to the login page.
 */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "../../queries/api";
import { getCookieUtil } from "../../utils/getCookie.util";
import { updateUserUtil } from "../../utils/updateUser.util";
import { GlobalContext } from "../../App";

export default function RequireAuth ({ children }) {
    const {
        setUser, isLogged, setIsLogged, setIsLoading
    } = useContext(GlobalContext)
    const navigate = useNavigate();
    const [loadContent, setLoadContent] = useState(false);

    useEffect(() => {
        async function checkRefreshToken () {
            if (!getCookieUtil('token')) {

                const isLoggedOk = await putRefreshToken(setUser, setIsLogged);
                // Redirect them to the /login page, but save the current location they were
                // trying to go to when they were redirected. This allows us to send them
                // along to that page after they login, which is a nicer user experience
                // than dropping them off on the home page.
                if (isLoggedOk) {
                    setLoadContent(true)
                } else {
                    navigate("/login")
                }
            } else {
                setLoadContent(true)
            }
            setIsLoading(false)
        }

        checkRefreshToken();
    }, [])
    return loadContent && isLogged ? children : <></>;
}

const putRefreshToken = async (setUser, setIsLogged) => {
    const refreshToken = getCookieUtil("refreshToken")
    if (refreshToken) {
        const { data: resData } = await API.post("user-bo/refresh", { token: refreshToken });
        updateUserUtil(resData, setUser, setIsLogged, resData.access_token, resData.expires_in, resData.refresh_token, resData.refresh_expires_in)
        return true;
    } else {
        return false;
    }

}
