const dateFormat = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
};

const timeFormat = {hour: '2-digit', minute: '2-digit'}

export const formatDateUtil = (value, isTimeEnabled = false, t) => {
    let dateToReturn = value;
    if (value) {
        if (t && value === t("No date")) {
            return value;
        } else {
            if (typeof value === "string") {
                dateToReturn = new Date(value);
            }
            if (typeof dateToReturn === "object") {
                return dateToReturn.toLocaleDateString('es-ES', isTimeEnabled ? {...dateFormat, ...timeFormat} : dateFormat)
            } else {
                return null
            }
        }
    } else if (t) {
        return t("No date")
    }
}
