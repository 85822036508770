/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 29/06/2023
 * @descriptionThe The `UsersDetailFilePage` component manages user files, enabling creation and editing. It handles file data based on the user and file IDs. Upon submission, it sends a POST request to create a new file. The component renders a form for managing user files with user-friendly interactions.
 */
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {API} from "../../queries/api";
import UserFile from "../../components/UserFile/UserFile";


export default function UsersDetailFilePage() {
    const [userFile, setUserFile] = useState();
    const {id, idFile, action} = useParams();

    const {t} = useTranslation();

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        if (idFile === "new") {
            await API.post("user/file/create", {...data, userId: id});
        }

        navigate("/users/" + id)


    }

    const getUserContact = async (idFile) => {
        if (idFile && idFile !== "new") {
            const {
                data
            } = await API("user/file/view/" + idFile);
            setUserFile(data);
        } else {
            setUserFile({});
        }
    };


    useEffect(() => {
        getUserContact(idFile);
    }, []);

    return (
        <>
            {userFile && <>
                <div className={"b-box"}>
                    <h2 className={"mb-8"}>
                        {idFile === "new" && t("Creation of")} {t("User file")} {userFile.name}
                    </h2>

                    <UserFile readOnly={!(idFile === "new" || action === "edit")} data={userFile}
                              onSubmit={onSubmit}/>
                </div>

            </>}
        </>
    );
}
