/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 29/09/2023
 * @descriptionThe This component represents a page for creating or editing a proposal budget. It retrieves the necessary parameters from the URL using `useParams()`. The page includes a form for submitting the budget data, which is handled by the `onSubmit` function. Depending on whether the budget is new or being edited, the appropriate API endpoint is called to create or update the budget. The form fields are populated with the budget data retrieved from the API. The `readOnly` prop is used to control whether the form fields are editable based on the `idBudget` and `action` parameters.
 */
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import ProposalBudget from "../../components/ProposalBudget/ProposalBudget";
import {API} from "../../queries/api";
import {useEffect, useState} from "react";

export default function ProposalsDetailBudgetPage() {
    const {t} = useTranslation();
    const {id, idBudget, action} = useParams();
    const [proposalBudget, setProposalBudget] = useState();
    const navigate = useNavigate();


    const onSubmit = async (data, coverages) => {
        if (idBudget === "new") {
            const {data: budget} = await API.post("policy/budget/create", {...data, proposalId: Number(id)});
            if (coverages.length > 0) {
                await API.put("policy/budget/coverage/update/" + budget.id, coverages);
            }
        } else {
            await API.put("policy/budget/update/" + idBudget, data);
        }
        navigate("/proposals/" + id + "#1")
    }


    useEffect(() => {
        const getProposalBudget = async (idBudget) => {
            if (idBudget && idBudget !== "new") {
                const {
                    data
                } = await API("policy/budget/view/" + idBudget);
                setProposalBudget({...data, companyId: data.companyId ? data.companyId.toString() : null});
            } else {
                setProposalBudget({uploadFiles: {fileLocation: ""}});
            }
        };

        getProposalBudget(idBudget);
    }, []);


    return <div className={"b-box"}>
        <h2 className={"mb-8"}>{id === "new" && t("Creation of")} {t("Proposal budget")}</h2>

        {proposalBudget && <ProposalBudget readOnly={!(idBudget === "new" || action === "edit")} data={proposalBudget}
                                           onSubmit={onSubmit}/>}
    </div>
}
