/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 11/10/2023
 * @description The `ClaimActions` component displays buttons to either cancel or close a claim based on its current status. It manages the state of the action to be performed and renders a dialog for confirmation. Upon confirmation, it triggers the corresponding action and submits the form.
 */
import ButtonsList from "../ButtonsList/ButtonsList";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import ClaimActionsClose from "../ClaimActionsClose/ClaimActionsClose";
import ClaimActionsCancel from "../ClaimActionsCancel/ClaimActionsCancel";

export default function ClaimActions ({ claimId, status, onSubmit }) {
    const { t } = useTranslation();
    const [action, setAction] = useState()

    const buttonsList = [
        {
            text: t("Cancel"),
            onClick: () => setAction("cancel"),
            iconName: "block",
            isDisabled: status === "CANCELED" || status === "CLOSED"
        },
        {
            text: t("Close"),
            onClick: () => setAction("close"),
            iconName: "close",
            isDisabled: status === "CANCELED" || status === "CLOSED"
        },
    ]

    return <div>
        <ButtonsList data={buttonsList}/>
        <Dialog header={action === "close" ? t("Close claim") : t("Cancel claim")} visible={action}
                onHide={() => setAction(false)}>
            {action === "close" && <ClaimActionsClose claimId={claimId} onSubmit={() => {onSubmit();setAction(false)}}/>}
            {action === "cancel" && <ClaimActionsCancel claimId={claimId} onSubmit={() => {onSubmit();setAction(false)}}/>}
        </Dialog>
    </div>
}
