/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 04/07/2023
 * @descriptionThe The `UserDocumentsTable` component manages a table of user documents, allowing users to view, edit, and delete their documents. It fetches the user's documents from the API based on the provided `userId` and displays them in a tabular format using the `DataTable` component. Each document entry includes its ID, name, type, expiration date, front and back images, validity status, and current status. Users can perform actions such as viewing, editing, and removing documents via the corresponding buttons in the table. Additionally, users can create new documents through a button in the table header.
 */
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {useTranslation} from "react-i18next";
import ActionsOverlayMenu from "../ActionsOverlayMenu/ActionsOverlayMenu";
import {Link, useNavigate} from "react-router-dom";
import {Button} from "primereact/button";
import {API} from "../../queries/api";
import {useEffect, useState} from "react";
import {BodyTemplateCheckbox} from "../BodyTemplateCheckbox/BodyTemplateCheckbox";
import {createUrl} from "../../utils/filePond.util";

export default function UserDocumentsTable({userId}) {
    const [userDocuments, setUserDocuments] = useState([])

    const {t} = useTranslation();
    const navigate = useNavigate();


    const deleteUserDocument = async (id) => {
        try {
            await API.delete("user/identity-document/delete/" + id);
            localRemove(id)

            function localRemove() {
                const index = userDocuments.findIndex((userContact) => userContact.id === id);
                const copyUserDocuments = [...userDocuments];
                copyUserDocuments.splice(index, 1)
                setUserDocuments(copyUserDocuments)
            }
        } catch (e) {
            console.error(e);
        }
    }

    const getUserDocuments = async () => {
        const {
            data: {data}
        } = await API("user/identity-document/list?filter[userId]=" + userId);
        setUserDocuments(data);

    };


    const UserDocumentsActions = (rowData) => {
        const items = [
            {
                label: t("See"),
                icon: <span className="me-4 material-symbols-outlined">visibility</span>,
                command: () => navigate("./documents/" + rowData.id)

            },
            {
                label: t("Edit"),
                icon: <span className="me-4 material-symbols-outlined">edit</span>,
                command: () => navigate("./documents/" + rowData.id + "/edit")

            },
            {
                label: t("Remove"),
                icon: <span className="me-4 material-symbols-outlined">delete</span>,
                command: () => deleteUserDocument(rowData.id),
            }
        ];
        return <ActionsOverlayMenu items={items}/>
    }

    const tableHeader = () => <div className={"d-flex justify-content-end"}>
        <Link to={"./documents/new"}>
            <Button label={t("Create user document")}></Button>
        </Link>
    </div>


    useEffect(() => {
        getUserDocuments()
    }, []);

    return <div>
        <DataTable emptyMessage={t("No results found")} header={tableHeader} value={userDocuments} filterDisplay="row"
                   dataKey="id" paginator rows={20}>

            <Column field="id" filter filterPlaceholder={t("Search")}
                    header={t("ID")}/>
            <Column field="name" filter filterPlaceholder={t("Search")}
                    header={t("Name")}/>
            <Column field="type" filter filterPlaceholder={t("Search")}
                    header={t("Type")} body={rowData => t(rowData.type)}/>
            <Column field="expirationDate" filter filterPlaceholder={t("Search")}
                    header={t("Expiration date")}/>

            <Column header={t("Front image")}
                    body={(rowData) => <img src={createUrl(rowData.frontPicture)} alt={t("Front" +
                        " image")}/>}/>
            <Column header={t("Back image")}
                    body={(rowData) => <img src={createUrl(rowData.backPicture)} alt={t("Back image")}/>}/>

            <Column field="valid"
                    header={t("Valid")} body={(rowData) => <BodyTemplateCheckbox isOk={rowData.isCurrent}/>}/>
            <Column field="isCurrent"
                    header={t("Current")} body={(rowData) => <BodyTemplateCheckbox isOk={rowData.isCurrent}/>}/>


            <Column header={t("Actions")} body={UserDocumentsActions}/>
        </DataTable>
    </div>
}

