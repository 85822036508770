/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 10/05/2023
 * @description The component you've provided is another form component for editing contact information. It includes fields for first name, last name, contact area, department, position, phone extension, email, fax, phone number, mobile phone number, and URL.
 *
 * Users can interact with the form fields, and upon submission, the data is processed and submitted via the `onSubmit` function. If the form is not in read-only mode, a "Save" button is displayed at the bottom for submitting the form.
 *
 * This form follows a similar structure to the previous one, utilizing the `FormInput` component for each input field and integrating with the `useForm` hook from `react-hook-form`. The `useTranslation` hook is used for internationalization.
 */
import FormInput from "../FormInput/FormInput";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { contactAreasConst } from "../../consts/contactAreas.const";
import { dirtyValuesUtil } from "../../utils/dirtyValues.util";
import { formErrorValidationsConst } from "../../consts/formErrorValidations.const";

export default function InsuranceContact ({ data, readOnly = false, onSubmit }) {
    const {
        control,
        formState: { errors, dirtyFields },
        handleSubmit,
    } = useForm({ defaultValues: data });
    const { t } = useTranslation();


    const onHandleSubmit = (dataForm) => {
        let dataToSubmit = dirtyValuesUtil(dirtyFields, dataForm)
        //if we have file we need to transform it to base64
        onSubmit(dataToSubmit)
    }


    return <form onSubmit={handleSubmit(onHandleSubmit)}>
        <fieldset>
            <legend>{t("Contact edition")}</legend>

            <div className={"py-4"}>
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="firstName" label={"Name"} control={control} errors={errors}
                                   rules={{ required: true }}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="lastName" label={"Last name"} control={control}
                                   errors={errors}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="contactArea" label={"Contact area"} control={control}
                                   errors={errors}
                                   rules={{ required: true }} options={contactAreasConst(t)} type={"select"}
                        />
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="department" label={"Department"} control={control}
                                   errors={errors}/>
                    </div>
                    <div className="col-12 col-lg-8">
                        <FormInput readOnly={readOnly} name="position" label={"Position"} control={control}
                                   errors={errors}
                                   rules={{ required: true }}/>
                    </div>


                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="phoneExtension" label={"Phone extension"} control={control}
                                   type={"number"}
                                   errors={errors} rules={{ max: formErrorValidationsConst("extension", t), min: 1 }}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="email" label={"Email"} control={control}
                                   errors={errors} rules={{ required: true }}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="fax" label={"Fax"} control={control}
                                   errors={errors}/>
                    </div>

                    <div className="col-4 col-lg-2">
                        <FormInput readOnly={readOnly} disabled={true} name="phonePrefix" label={"Prefix"}
                                   control={control} type={"number"}
                                   errors={errors}
                                   type={"number"}
                        />
                    </div>
                    <div className="col-8 col-lg-4">
                        <FormInput readOnly={readOnly} name="phone" label={"Phone"} control={control}
                                   errors={errors} type={"number"}
                        />
                    </div>


                    <div className="col-4 col-lg-2">
                        <FormInput readOnly={readOnly} disabled={true} name="mobilePhonePrefix" label={"Prefix"}
                                   control={control}
                                   errors={errors} type={"number"}
                        />
                    </div>
                    <div className="col-8 col-lg-4">
                        <FormInput readOnly={readOnly} name="mobilePhone" label={"Mobile phone"} control={control}
                                   errors={errors} type={"number"}
                        />
                    </div>


                    <div className="col-12 col-lg-12">
                        <FormInput readOnly={readOnly} name="url" label={"Url"} control={control}
                                   errors={errors}/>
                    </div>
                </div>
            </div>


        </fieldset>

        {!readOnly && <div className={"d-flex justify-content-end mt-8"}>
            <Button label={t("Save")}/>
        </div>}
    </form>
}
