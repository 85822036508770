export const updateUserUtil = (user, setUser, setIsLogged, token, expiration, refreshToken, refreshTokenExpiration) => {
    document.cookie = "user=" + JSON.stringify(user) + ";path=/;max-age=" + expiration;
    setUser(user);

    if (token) {
        document.cookie = "token=" + token + ";path=/;max-age=" + expiration;
        setIsLogged(!!token);
    }

    if (refreshToken) {
        document.cookie = "refreshToken=" + refreshToken + ";path=/;max-age=" + refreshTokenExpiration;
    }
};
