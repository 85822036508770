/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 15/10/2023
 * @description The `ProposalBudgetsOffersTable` component manages a table displaying budget offers related to a proposal. It allows users to view and interact with various details of each offer, including company information, name, modality, price payment details, offer type, and actions such as confirming offers and removing them. Users can also send the proposal to the user and compare coverages. The component utilizes pagination, filtering, sorting, and lazy loading to enhance performance. Additionally, it integrates dialog and toolbar components for a seamless user experience.
 */
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {API} from "../../queries/api";
import {createFilterParamsUtil} from "../../utils/createFilterParams.util";
import ActionsOverlayMenu from "../ActionsOverlayMenu/ActionsOverlayMenu";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Tag} from "primereact/tag";
import {getCompaniesByBudgets} from "../ProposalBudgetsTable/ProposalBudgetsTable";
import {confirmDialog} from "primereact/confirmdialog";
import {toast} from "react-toastify";
import ToastContent from "../ToastContent/ToastContent";
import {Button} from "primereact/button";
import {checkAndReturnCorrectLazyTableValues} from "../../utils/checkAndReturnCorrectLazyTableValues.util";
import {Toolbar} from "primereact/toolbar";
import {Dialog} from "primereact/dialog";
import CoveragesComparativeTable from "../CoveragesComparativeTable/CoveragesComparativeTable";

export default function ProposalBudgetsOffersTable({proposal, setProposal}) {
    const {t} = useTranslation();
    const [isShowingComparativeModal, setIsShowingComparativeModal] = useState(false)

    const [proposalBudgets, setProposalBudgets] = useState([])

    const [lazyTable, setLazyTable] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
    });

    const onLazy = (event) => {
        const newLazyTable = checkAndReturnCorrectLazyTableValues(lazyTable, event);
        setLazyTable(newLazyTable);
        getLazyData(newLazyTable);
    };

    const getLazyData = async (event) => {
        const {data: proposalBudgets} = await API(
            "policy/budget/list/" + proposal.id + createFilterParamsUtil(event) + "&filter[isInOffer]=1&extraFields=budgetCoverages,budgetCoverages.coverage"
        );


        const proposalBudgetsWithCompanies = await getCompaniesByBudgets(proposalBudgets)
        setProposalBudgets(proposalBudgetsWithCompanies);
    };


    const onSendProposal = async () => {
        try {
            confirmDialog({
                message: t('Are you sure to send proposal to user?'),
                acceptLabel: t("Confirm"),
                rejectLabel: t("Cancel"),
                accept: async () => {
                    try {
                        await API.post(`policy/proposal/send/${proposal.id}`);
                        setProposal({...proposal, status: "USER_PENDING"})
                        toast.info(<ToastContent title={t("Proposal sent successfully")}
                        />)
                    } catch (e) {
                        console.error(e)
                    }
                },
            });

        } catch (e) {
            console.error(e)
        }

    }


    useEffect(() => {
        getLazyData(lazyTable);
    }, []);


    const InsuranceCustomerServicesActions = (rowData, info) => {
        const items = [
            {
                label: t("Mark like best price"),
                icon: <span className="me-4 material-symbols-outlined">payments</span>,
                command: async () => changeProposalBudgetTag(rowData.id, "BEST_PRICE", info.rowIndex)
            }, {
                label: t("Mark like best coverages"),
                icon: <span className="me-4 material-symbols-outlined">verified_user</span>,
                command: async () => changeProposalBudgetTag(rowData.id, "BEST_COVERAGE", info.rowIndex)
            }, {
                label: t("Mark like best price and coverages"),
                icon: <span className="me-4 material-symbols-outlined">stars</span>,
                command: async () => changeProposalBudgetTag(rowData.id, "BEST_RECOMMENDED", info.rowIndex)
            }, {
                label: t("See PDF"),
                icon: <span className="me-4 material-symbols-outlined">description</span>,
                command: () => window.open(process.env.REACT_APP_IMAGE_URL + rowData.fileLocation)
            }, {
                label: t("Remove offer"),
                icon: <span className="me-4 material-symbols-outlined">delete</span>,
                command: async () => {
                    try {
                        await API.put(
                            "policy/budget/update/" + rowData.id, {isInOffer: false})
                        toast.info(<ToastContent title={t("Offer removed")}/>)
                        const proposalBudgetsCopy = [...proposalBudgets];
                        const indexFound = proposalBudgetsCopy.findIndex(proposalBudget => proposalBudget.id === rowData.id)
                        proposalBudgetsCopy.splice(indexFound, 1)
                        setProposalBudgets(proposalBudgetsCopy)
                    } catch (e) {
                        console.error(e)
                    }
                },
            }
        ];
        return <ActionsOverlayMenu items={items}/>
    }

    const changeProposalBudgetTag = async (id, tag, index) => {
        const proposalBudgetsCopy = [...proposalBudgets].map(proposalBudget => proposalBudget.tag === tag ? ({
            ...proposalBudget,
            tag: null
        }) : proposalBudget)

        await API.put(
            "policy/budget/update/" + id, {tag})
        proposalBudgetsCopy[index].tag = tag

        setProposalBudgets(proposalBudgetsCopy)
    }

    const tagTemplate = ({tag}) => {
        if (tag === "BEST_PRICE") {
            return <Tag icon={<span className="me-4 material-symbols-outlined">payments</span>} severity="success"
                        value={t("Best price")}/>
        } else if (tag === "BEST_COVERAGE") {
            return <Tag icon={<span className="me-4 material-symbols-outlined">verified_user</span>} severity="success"
                        value={t("Best coverages")}/>
        } else if (tag === "BEST_RECOMMENDED") {
            return <Tag icon={<span className="me-4 material-symbols-outlined">stars</span>} severity="success"
                        value={"Best price and coverages"}/>
        }
    }


    const confirmProposalBudget = async (proposalBudget) => {
        try {
            confirmDialog({
                message: t('Are you sure?'),
                acceptLabel: t("Confirm"),
                rejectLabel: t("Cancel"),
                accept: async () => {
                    await API.post(`policy/proposal/accept/${proposal.id}/${proposalBudget.id}`);
                    setProposal({...proposal, status: "ACCEPTED"})
                    toast.info(<ToastContent title={t("Offer confirmed successfully")}
                    />)
                },
            });

        } catch (e) {
            console.error(e)
        }
    }

    const confirmTemplate = (proposalBudget) => {
        return <Button onClick={() => confirmProposalBudget(proposalBudget)}
                       label={t("Confirm")}></Button>
    }

    const priceTemplate = ({firstBill, otherBills}) => {
        return <table className={"b-table-offer"}>
            <tbody>
            {firstBill && <tr>
                <td>{t("Base price")}</td>
                <td>{firstBill}</td>
            </tr>}

            {otherBills && <tr>
                <td>{t("Complementary")}</td>
                <td>{otherBills}</td>
            </tr>}
            </tbody>
            <tfoot style={{borderTop: "1px solid"}}>
            <tr>
                <th>{t("Total")}</th>
                <th>{firstBill && otherBills ? firstBill + otherBills : firstBill ? firstBill : otherBills}€</th>
            </tr>
            </tfoot>
        </table>
    }

    const tableHeader = () => <div className={"d-flex justify-content-end"}>
        <Button onClick={onSendProposal}
                label={t("Send")} icon={<span className="me-2 material-symbols-outlined">mail</span>}></Button>
    </div>

    return <div>
        <DataTable emptyMessage={t("No results found")}
                   header={proposalBudgets.length < 2 || (proposal && proposal.status !== "OFFER_PENDING") || (!!proposalBudgets.find(proposalBudget => proposalBudget.tag) === false) ? null : tableHeader}
                   value={proposalBudgets}
                   filterDisplay="row"
                   dataKey="id"
                   paginator
                   totalRecords={lazyTable.totalRecords}
                   rows={lazyTable.rows}
                   onPage={onLazy}
                   onFilter={onLazy}
                   onSort={onLazy}
                   rowClassName={(rowData) => ({"p-row-selected": rowData.id === proposal.acceptedBudgetId})}
                   lazy
                   first={lazyTable.first}
                   sortOrder={lazyTable.sortOrder}>

            <Column filter filterMatchMode="contains" filterPlaceholder={t("Search")}
                    header={t("Company")}
                    body={({company}) => company.logo ?
                        <img width="100" src={process.env.REACT_APP_IMAGE_URL + company.logo}
                             alt={t("Company")}/> : company.name}/>
            <Column field="name" filter filterMatchMode="contains" filterPlaceholder={t("Search")}
                    header={t("Name")}/>
            <Column field="modalityName" filter filterMatchMode="contains" filterPlaceholder={t("Search")}
                    header={t("Modality")}/>
            <Column filter filterMatchMode="contains" filterPlaceholder={t("Search")}
                    header={t("Price payment")} body={priceTemplate}
            />
            <Column filter filterMatchMode="contains" filterPlaceholder={t("Search")}
                    header={t("Offer type")} body={tagTemplate}
            />

            {/*<Column*/}
            {/*    header={t("PDF")} body={seePdfTemplate}*/}
            {/*/>*/}

            {proposal.status === "USER_PENDING" &&
                <Column filter filterMatchMode="contains" filterPlaceholder={t("Search")}
                        header={t("Offer type")} body={confirmTemplate}
                />}


            {proposal.status === "OFFER_PENDING" &&
                <Column header={t("Actions")} body={InsuranceCustomerServicesActions}/>}
        </DataTable>

        <Toolbar className="mb-4" start={() => <Button
            label={t("Compare coverages")} onClick={() => setIsShowingComparativeModal(true)}></Button>}></Toolbar>
        <Dialog header={t("Coverages")} visible={isShowingComparativeModal}
                onHide={() => setIsShowingComparativeModal(false)}>
            <CoveragesComparativeTable budgets={proposalBudgets} policyTypeId={proposal.policyTypeId}/>
        </Dialog>
    </div>
}
