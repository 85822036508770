/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 11/10/2023
 * @description The `ClaimActionsCancel` component provides a form to input a reason for canceling a claim. Upon submission, it sends a PUT request to update the claim status to "CANCELED" and triggers the `onSubmit` callback.
 */
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {dirtyValuesUtil} from "../../utils/dirtyValues.util";
import {API} from "../../queries/api";
import FormInput from "../FormInput/FormInput";
import {Button} from "primereact/button";
import {cancelClaimConst} from "../../consts/cancelClaim.const";

export default function ClaimActionsCancel({claimId, onSubmit}) {
    const {t} = useTranslation();

    const {
        control,
        formState: {errors, dirtyFields},
        handleSubmit,
    } = useForm();

    const doSubmit = async (dataForm) => {
        let dataToSubmit = dirtyValuesUtil(dirtyFields, dataForm)
        await API.put(`policy/claim/update/${claimId}`, {...dataToSubmit, status: "CANCELED"})

        onSubmit()
    }


    return <form onSubmit={handleSubmit(doSubmit)}>
        <fieldset>
            <legend className={"u-hide-accessibility"}>{t("Close claim")}</legend>

            <div className={"py-4"}>
                <div className="row">

                    <div className="col-12">
                        <FormInput name="cancellationReason" label={"Reason"}
                                   control={control}
                                   type={"select"} options={cancelClaimConst}
                                   errors={errors} rules={{required: true}}/>
                    </div>


                </div>
            </div>
        </fieldset>

        <div className={"d-flex justify-content-end"}>
            <Button label={t("Cancel")}/>
        </div>
    </form>
}
