export const checkAndReturnCorrectLazyTableValues = (lazyTable, event) => {
    const newLazyTable = {...lazyTable};
    newLazyTable.filters = event.filters ? event.filters : newLazyTable.filters
    newLazyTable.sortField = event.sortField ? event.sortField : newLazyTable.sortField
    newLazyTable.first = event.first!== null ? event.first : newLazyTable.first
    newLazyTable.page = event.page !== null ? event.page : newLazyTable.page
    newLazyTable.rows = event.rows !== null? event.rows : newLazyTable.rows
    newLazyTable.totalRecords = event.totalRecords!== null ? event.totalRecords : newLazyTable.totalRecords
    newLazyTable.sortOrder = event.sortOrder ? event.sortOrder : newLazyTable.sortOrder
    newLazyTable.multiSortMeta = event.multiSortMeta && event.multiSortMeta.length > 0 ? event.multiSortMeta : newLazyTable.multiSortMeta
    return newLazyTable
}
