export const es = {
    "Dashboard": "Tablero",
    "Tasks": "Tareas",
    "Insurances": "Aseguradoras",
    "Users": "Usuarios",
    "Cross selling": "Venta cruzada",
    "Support": "Soporte",
    "Administration": "Administración",
    "Configuration": "Configuración",
    "My profile": "Mi perfil",
    "Log out": "Salir",
    "ID": "ID",
    "Name": "Nombre",
    "First name": "Nombre",
    "Fiscal name": "Nombre fiscal",
    "Business name": "Nombre comercial",
    "Mediation": "Mediación",
    "Mandatories": "Mandatos",
    "Actions": "Acciones",
    "Visible on app": "Visible en app",
    "Insurances list": "Listado de aseguradoras",
    "NIF": "NIF",
    "Search": "Buscar",
    "See": "Ver",
    "Edit": "Editar",
    "History changes": "Historico de cambios",
    "Any": "Cualquiera",
    "Select": "Selecciona",
    "Insurance": "Aseguradora",
    "Basic data": "Datos básicos",
    "DGS Code": "Código DGS",
    "Description": "Descripción",
    "Payment method": "Método de pago",
    "Specialty": "Especialidad",
    "Address": "Dirección",
    "Zip code": "Código postal",
    "City": "Ciudad",
    "Province": "Provincia",
    "Region": "Región",
    "Country": "País",
    "Edition of": "Edición de",
    "Contact data": "Datos de contacto",
    "Country prefix": "Prefijo del país",
    "Phone": "Teléfono",
    "Extension": "Extensión",
    "Mobile phone": "Móvil",
    "Email": "Correo electrónico",
    "Claim email": "Correo para siniestros",
    "Sale email": "Email de comercial",
    "Website": "Sitio web",
    "Claim phone": "Móvil para siniestros",
    "Configuration data": "Datos de configuración",
    "IBAN": "Cuenta bancaria (IBAN)",
    "CARD": "Tarjeta bancaria",
    "YES": "Si",
    "NO_PERMANENTLY": "No permanentemente",
    "NO_TEMPORARY": "No temporalmente",
    "UNKNOWN": "Desconocido",
    "Mediator code": "Código mediador",
    "Day": "Día",
    "Days": "Días",
    "All": "Todos",
    "Generate proposition of improvement on mediation policy": "Generar propuesta de mejora en pólizas mediadas",
    "Generate proposition of improvement on no mediation policy": "Generar propuesta de mejora en pólizas no mediadas",
    "Retain insurer emails": "Retener los emails de la aseguradora",
    "Order priority": "Prioridad de ordenación",
    "Very high": "Muy alto",
    "High": "Alto",
    "Medium": "Medio",
    "Low": "Bajo",
    "Very low": "Muy bajo",
    "Complementary": "Complementario",
    "Save": "Guardar",
    "Contact": "Contacto",
    "Contacts": "Contactos",
    "Customer service": "Atención al cliente",
    "Logos": "Logos",
    "Files": "Ficheros",
    "Position": "Puesto",
    "Type": "Tipo",
    "Department": "Departamento",
    "Url": "Url",
    "Fax": "Fax",
    "Create contact": "Crear contacto",
    "Remove": "Eliminar",
    "Last name": "Apellido",
    "Phone extension": "Extensión telefónica",
    "Contact area": "Area de contacto",
    "Commercial": "Comercial",
    "Claims": "Siniestros",
    "Others": "Otros",
    "Production": "Producción",
    "ADMINISTRATION": "Administración",
    "COMMERCIAL": "Comercial",
    "CLAIMS": "Siniestros",
    "OTHERS": "Otros",
    "PRODUCTION": "Producción",
    "Contact edition": "Edición de contacto",
    "Title": "Título",
    "Branch": "Ramo",
    "Create file": "Crear fichero",
    "GENERAL_CONDITION": "Condiciones generales",
    "PRODUCT_FILE": "Fichero de producto",
    "REQUEST": "Petición",
    "Customer service edition": "Edición de atención al cliente",
    "Role": "Rol",
    "Gender": "Género",
    "Male": "Hombre",
    "Female": "Mujer",
    "Experience": "Experiencia",
    "Education": "Educación",
    "About me": "Sobre mí",
    "User data": "Datos del usuario",
    "Users list": "Listado de usuarios",
    "Change password": "Cambiar contraseña",
    "Old password": "Contraseña antigua",
    "New Password": "Nueva contraseña",
    "Confirm new Password": "Confirmar nueva contraseña",
    "Strong": "Fuerte",
    "Weak": "Débil",
    "Password": "Contraseña",
    "Create user": "Crear usuario",
    "Policies": "Pólizas",
    "Policies list": "Listado de pólizas",
    "Filters": "Filtros",
    "Filter": "Filtrar",
    "Clean": "Limpiar",
    "Policy number": "Número de póliza",
    "Draft": "Borrador",
    "Completed": "Completada",
    "Completed without file": "Completada sin archivo",
    "Validation status": "Estado de validación",
    "Annual premium": "Prima anual",
    "Frequency": "Fraccionamiento",
    "Expiration date": "Fecha de vencimiento",
    "Dates": "Fechas",
    "First effect": "Primer efecto",
    "Expiration": "Vencimiento",
    "Policy type": "Tipo de poliza",
    "Adviser": "Asesor",
    "Policy": "Poliza",
    "History": "Historial",
    "Proposals": "Propuestas",
    "Supplements": "Suplementos",
    "Effective date": "Fecha de efecto",
    "Comments": "Comentarios",
    "Reference": "Referencia",
    "Initial effective date": "Fecha de efecto inicial",
    "Annual premium (€)": "Prima anual (€)",
    "Create insurance customer": "Crear atención al cliente",
    "File edition": "Edición de archivo",
    "Backoffice users": "Usuarios backoffice",
    "Create policy": "Crear poliza",
    "Create insurance": "Crear aseguradora",
    "Creation of": "Creación de",
    "Prefix": "Prefijo",
    "Logo": "Logo",
    "Login": "Inicio de sesión",
    "Select insurance": "Selecciona la aseguradora",
    "Select branch": "Selecciona el ramo",
    "Search insurance": "Busca la aseguradora",
    "ANNUAL": "Anual",
    "HALF_YEARLY": "Semestral",
    "FOUR_MONTHLY": "Cuatrimestral",
    "QUARTERLY": "Trimestral",
    "BIMONTHLY": "Bimestral",
    "MONTHLY": "Mensual",
    "FORTNIGHTLY": "Quincenal",
    "WEEKLY": "Semanal",
    "UNIQUE": "Única",
    "Fractional premium": "Prima fraccionada",
    "BANK": "Banco",
    "PARTNER": "Colaborador",
    "COMPANY": "Compañía",
    "BROKERAGE": "Correduría",
    "CREDIT_ACCOUNT": "Cuenta de crédito",
    "REFUND": "Devolución",
    "OFFICE": "Oficina",
    "FEES": "Tarifas",
    "TRANSFER": "Transferencia",
    "Payment channel": "Canal de cobro",
    "SECURED": "Asegurado",
    "COLLECTIVE": "Colectivo",
    "ADDRESS": "Dirección",
    "VEHICLE_NAMEPLATE": "Matrícula",
    "MODEL": "Modelo",
    "RISK": "Riesgo",
    "Reference value": "Valor de referencia",
    "Policy PDF": "PDF de poliza",
    "Mobile": "Móvil",
    "Date of birth": "Fecha de cumpleaños",
    "VIP": "VIP",
    "You are not VIP": "No eres VIP",
    "Notes": "Notas",
    "Flags": "Flags",
    "User": "Usuario",
    "User allow calls?": "¿El usuario permite llamadas?",
    "Retain user notifications?": "¿Retener las notificaciones del usuario?",
    "Notifications": "Notificaciones",
    "User wants to receive push notifications?": "¿El usuario quiere recibir notificaciones por push?",
    "User wants to receive push promotions?": "¿El usuario quiere recibir promociones por push?",
    "User wants to receive email notifications?": "¿El usuario quiere recibir notificaciones por email?",
    "User wants to receive phone notifications?": "¿El usuario quiere recibir notificaciones por teléfono?",
    "User wants to receive email promotions?": "¿El usuario quiere recibir promociones por email?",
    "User wants to receive phone promotions?": "¿El usuario quiere recibir promociones por teléfono?",
    "Advisor": "Tutor",
    "EMAIL": "Email",
    "PHONE": "Teléfono",
    "MOBILE": "Móvil",
    "Data type": "Tipo de dato",
    "Data": "Dato",
    "Create": "Crear",
    "User contact": "Contacto de usuario",
    "Create user contact": "Crear contacto de usuario",
    "Create user address": "Crear dirección de usuario",
    "User address": "Dirección de usuario",
    "Addresses": "Direcciones",
    "Street type": "Tipo de calle",
    "Build number": "Número de portal",
    "Postal code": "Código postal",
    "Floor": "Planta",
    "Door": "Puerta",
    "Additional data": "Datos adicionales",
    "Active address": "Direción activa",
    "User file": "Fichero de usuario",
    "Create user file": "Crear fichero de usuario",
    "DRIVING_LICENSE": "Carnet de conducir",
    "CIF_FRONT": "Frontal CIF",
    "CIF_BACK": "Trasera CIF",
    "OTHER": "Otro",
    "File": "Fichero",
    "No results found": "No se han encontrado resultados",
    "User document": "Documento de usuario",
    "Back image": "Imagen trasera",
    "Front image": "Imagen frontal",
    "Document data": "Datos del documento",
    "Images": "Imágenes",
    "Document number": "Número de documento",
    "Birth date": "Fecha de nacimiento",
    "Documents": "Documentos",
    "Create user document": "Crear documento de usuario",
    "Current": "Actual",
    "Valid": "Válido",
    "Number": "Número",
    "Municipality": "Municipio",
    "Active": "Activo",
    "Create backoffice user": "Crear usuario backoffice",
    "Backoffice users list": "Lista de usuarios backoffice",
    "Backoffice user": "Usuario backoffice",
    "Introduce a valid email": "Introduce un email válido",
    "Photo": "Foto",
    "ClaimsManager": "Gestor de Administración",
    "Administrator": "Manager",
    "Manager": "Asesor",
    "SinisterManager": "Gestor de Siniestros",
    "First surname": "Primer apellido",
    "Second surname": "Segundo apellido",
    'New proposal': 'Nueva propuesta',
    "Proposals list": "Lista de propuestas",
    "Proposal": "Propuesta",
    "Objective study": "Objetivo de estudio",
    "422": "Ha habido un problema con los datos",
    "500": "Ha habido un problema con la petición",
    "401": "No tienes los permisos suficientes",
    "Budgets": "Presupuestos",
    "Create budget": "Crear presupuesto",
    "Synchronize with Avant": "Sincronizar con Avant",
    "Proposal budget": "Presupuesto propuesto",
    "Send": "Enviar",
    "Log in your account to admin all information from Prudenzia environments": "Inicia sesión en tu cuenta para" +
        " administrar toda tu información del ecosistema Prudenzia",
    "Modality": "Modalidad",
    "Other bills": "Otros recibos",
    "Bills": "Recibos",
    "First bill": "Primer recibo",
    "Insurances allowed": "Aseguradoras permitidas",
    "Attach PDF": "Adjuntar PDF",
    'Password need to have at least one lowercase, one uppercase, one special character, one number and need to be exactly 8 characters': 'La contraseña tiene que tener al menos una minúscula, una mayúscula, un caracter especial, un número y tener exactamente 8 caracteres',
    'NEW': "Nueva",
    'EXPORTING': "Exportando",
    'EXPORTING_ERROR': "Error al exportar",
    'EXPORTING_FAILED': "Fallo al exportar",
    'STUDY_PENDING': "Pendiente de estudio",
    'OFFER_PENDING': "Pendiente de oferta",
    'CANCELED': "Cancelada",
    'USER_PENDING': "Pendiente de usuario",
    "REJECTED": "Rechazada",
    "ACCEPTED": "Aceptada",
    "POLICY_PENDING": "Pendiente de poliza",
    'FINISHED': "Finalizada",
    "Mediate": "Mediada",
    "No mediate": "No mediada",
    "Status": "Estado",
    "ACTIVE": "Activo",
    "INACTIVE": "Inactivo",
    "Policy status": "Estado de la póliza",
    "In effect": "En vigor",
    "Expired": "Expirada",
    "Claim": "Siniestro",
    "Reference number": "Número de referencia",
    "Require company acceptance": "Requiere aceptación de compañía",
    "Notification company": "Notificación de compañía",
    "Allow calls": "Permite llamadas",
    "Policy data": "Datos de poliza",
    "Expedient number": "Número de expediente",
    "Cancel": "Cancelar",
    "Cerrar": "Cerrar",
    "ALL": "Todos",
    "COMPENSATION": "Indemnización",
    "COMPANY_NOTICE": "Notificación de compañía",
    "REFUSE": "Rehuso",
    "REPARATION": "Reparación",
    "REPARATION_COMPENSATION": "Reparación e indemnización",
    "Close claim": "Cerrar siniestro",
    "Cancel claim": "Cancelar siniestro",
    "Cancellation reason": "Razón de cancelación",
    "Close reason": "Razón para cancelar",
    "Close date": "Fecha de cierre",
    "Observations": "Observaciones",
    "Payment amount": "Importe de pago",
    "Reason": "Motivo",
    "DUPLICATE": "Duplicada",
    "CLIENT_ERROR": "Error de cliente",
    "Assigned": "Asignado",
    "Closed date": "Fecha de cierre",
    "IN_PROGRESS": "En progreso",
    "Create claim": "Crear siniestro",
    "Only when role is Manager": "Solo cuando el rol es Asesor",
    "Close": "Cerrar",
    "NOTIFICATION": "Notificación",
    "REPAIR": "Reparación",
    "REPAIR_AND_COMPENSATION": "Reparación e indemnización",
    "Need to be 2 digits": "Necesita ser 2 dígitos",
    "Need to be 4 digits": "Necesita ser 4 dígitos",
    "Need to be 5 digits": "Necesita ser 5 dígitos",
    "Insurance file": "Ficheros de compañía",
    "Not avaible": "No disponible",
    "No results": "Sin datos",
    "Annual payment": "Precio anual",
    "Paid type": "Forma de pago",
    "Carry out":"Realizar",
    "Create proposal": "Crear propuesta",
    "Create offer": "Crear oferta",
    "Mark like best price": "Marcar como mejor precio",
    "Mark like best coverages": "Marcar como mejores coberturas",
    "Mark like best price and coverages": "Marcar como mejor precio y coberturas",
    "Offer type": "Tipo de oferta",
    "Best price": "Mejor precio",
    "Best coverages": "Mejores coberturas",
    "Best price and coverage": "Mejor precio y coberturas",
    "Base price": "Precio base",
    "Total": "Total",
    "CLOSED": "Cerrado",
    "Closed":"Cerrado",
    "Created":"Creado",
    "Claims list":"Listado de siniestros",
    "See file":"Ver archivo",
    "Annulation":"Anulación",
    "Creation date":"Fecha de creación",
    "Company": "Compañía",
    "Price payment": "Precio anual",
    "Select items":"Selecciona opciones",
    "Selected":"Seleccionados",
    "My insurances":"Mis seguros",
    "Search municipality":"Buscar municipio",
    "Select your province":"Selecciona tu provincia",
    "Portal number":"Número del portal",
    "Select your door":"Selecciona tu puerta",
    "Select your floor":"Selecciona tu planta",
    "Search address":"Buscar dirección",
    "No name":"Sin nombre",
    "Offers":"Ofertas",
    "You cannot edit this proposal cause of his status.":"No puedes editar esta propuesta debido a su estado. ",
    "Offer created":"Oferta creada",
    "Proposal sent successfully":"Propuesta enviada correctamente",
    "Confirmation":"Confirmación",
    "Are you sure to send proposal to user?":"¿Estás seguro que deseas enviar la propuesta al usuario?",
    "Confirm":"Confirmar",
    "Hire data":"Datos contratación",
    "Are you sure?":"¿Estás seguro?",
    "Offer confirmed successfully":"Oferta confirmada satisfactoriamente",
    "Proposal updated successfully":"Propuesta actualizada correctamente",
    "Emission":"Emisión",
    "Send authorization for another taker?":"¿Mandar autorización para distinto tomador?",
    "Select address":"Selecciona una dirección",
    "Create new address":"Crear nueva dirección",
    "Use created address":"Usar una dirección existente",
    "Data saved successfully":"Datos guardados satisfactoriamente",
    "Policy created":"Poliza creada",
    "See PDF":"Ver PDF",
    "Tickets":"Recibos",
    "Concept":"Concepto",
    "Effect date":"Fecha de efecto",
    "Final date":"Fecha final",
    "No date":"Sin fecha",
    "Wallet":"Cartera",
    "Net premium":"Prima neta",
    "Total premium":"Prima total",
    "Commisions":"Comisiones",
    "Brokerage":"Correduría",
    "Canceled":"Anulada",
    "Max number of budgets is":"El número máximo de presupuestos es",
    "Max number of offers is":"El número máximo de ofertas es",
    "Remove offer":"Eliminar oferta",
    "Offer removed":"Oferta eliminada",
    "Select proposal branch":"Seleccionar ramo de propuesta",
    "Project":"Proyecto",
    "Project ID":"Id de proyecto",
    "Search by nif, name, email or mobile":"Buscar por nif, nombre, email o móvil",
    "Tasks list":"Lista de tareas",
    "Create task":"Crear tarea",
    "Admin":"Administrador",
    "Task":"Tarea",
    "Alarm":"Alarma",
    "Attach files":"Adjuntar archivos",
    "User advisor":"Asesor del usuario",
    "Created at":"Creado",
    "Reassign tasks":"Reasignar tareas",
    "Completed tasks":"Tareas completadas",
    "PENDING":"Pendiente",
    "ERP_PENDING": "Pendiente Sinc ERP",
    "Attach file":"Adjuntar fichero",
    "Show my tasks":"Mostrar mis tareas",
    "Show all tasks":"Mostrar todas las tareas",
    "Select one":"Selecciona uno",
    "You are going to cancel this proposal. Continue?":"Vas a cancelar esta Propuesta. ¿Quiere Continuar?",
    "See policy":"Ver poliza",
    "See proposal":"Ver propuesta",
    "See claim":"Ver siniestro",
    "See user":"Ver usuario",
    "Task data":"Datos de la tarea",
    "Postpone":"Posponer",
    "Finish":"Finalizar",
    "Do you want to mark this task like finished?":"¿Quieres marcar esta tarea como terminada?",
    "Postpone alarm":"Posponer alarma",
    "New alarm date":"Nueva fecha de alarma",
    "See image":"Ver imagen",
    "Charging frequency":"Frecuencia de cobro",
    "Contact attempts":"Intentos de contacto",
    "Finish alarm":"Finalizar alarma",
    "Show comments":"Mostrar comentarios",
    "No comments":"Sin comentarios",
    "No files":"sin archivos",
    "Policy Number":"Número de poliza",
    "Create note":"Crear nota",
    "Note data":"Datos de la nota",
    "Note":"Nota",
    "Coverages":"Coberturas",
    "Compare coverages":"Comparar coberturas",
    "Coverage":"Cobertura",
    "No description":"Sin descripción",
    "Record":"Grabar",
    "Task updated successfully":"Tarea actualizada correctamente",
    "Record task":"Grabar tarea",
    "Original proposal":"Propuesta original",
    "Emission date":"Fecha de emisión",
    "Yes":"Si",
    "No":"No",
}
