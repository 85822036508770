/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 12/10/2023
 * @description The `ResponsiveStructure` component is responsible for rendering a responsive layout. It adjusts the menu width based on whether it's collapsed or expanded. It also toggles the menu's visibility based on user interaction. Additionally, it renders the menu and content area, with the content area dynamically adjusting its width based on the menu's state. Finally, it includes loading indicators and renders the application routes.
 */
import Menu from "../Menu/Menu";
import Loading from "../Loading/Loading";
import Routes from "../../pages/Routes";
import React, { useState } from "react";
import { useProSidebar } from "react-pro-sidebar";
import "./ResponsiveStructure.scss";
import { useLocation } from "react-router-dom";

export default function ResponsiveStructure () {
    const { collapsed } = useProSidebar();
    const [isShowingMenu, setIsShowingMenu] = useState(false);
    let location = useLocation();

    return <div
        className={"c-responsive-structure" + (isShowingMenu ? " c-responsive-structure--collapse" : "") + ((location.pathname === "/login" || location.pathname === "/register") ? " c-responsive-structure--full" : "")}>

        <span className={"c-responsive-structure__icon material-symbols-outlined"}
              onClick={() => setIsShowingMenu(!isShowingMenu)}>menu</span>


        <div className={"c-responsive-structure__menu"} style={{ width: collapsed ? "80px" : "250px" }}>
            <Menu setIsShowingMenu={setIsShowingMenu}/>
        </div>


        <div style={{ width: `calc(100% - ${collapsed ? 80 : 250}px)` }} className={"container-fluid p-8" +
        " c-responsive-structure__content"}>
            <Loading/>

            <Routes/>
        </div>

    </div>
}
