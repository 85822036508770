/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 29/09/2023
 * @description The `Proposal` component is used for managing proposals, allowing users to create or edit proposals associated with a policy. It consists of a form where users can input data related to the proposal, including project details if applicable. The form includes fields for the project ID and dynamically generated fields based on the policy type. Users can submit the form to save their changes. The component supports multiple languages through translation hooks (`useTranslation`) and utilizes form validation to ensure data integrity before submission.
 */
import {Button} from "primereact/button";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {dirtyValuesUtil} from "../../utils/dirtyValues.util";
import DynamicData from "../DynamicData/DynamicData";
import {transformArrayToObjUtil} from "../../utils/transformArrayToObjUtil";
import {useSearchParams} from "react-router-dom";
import FormInput from "../FormInput/FormInput";


/**
 *
 * @param data
 * @param readOnly used to show field like input or only just show data
 * @param onSubmit
 * @returns {JSX.Element}
 * @constructor
 */
export default function Proposal({
                                     data, readOnly = false, onSubmit
                                 }) {
    let [searchParams] = useSearchParams();
    const policyTypeId = searchParams.get("policyTypeId"); // this is only for creation from policy

    const {t} = useTranslation();
    const {
        control,
        setValue,
        formState: {errors, dirtyFields},
        handleSubmit,
        watch
    } = useForm(data && data.risks ? {
        defaultValues: {
            projectId: data.projectId,
            ...transformArrayToObjUtil([...data.risks])
        }
    } : {});

    const onHandleSubmit = (dataForm) => {
        handleSubmit(async (dataRisk) => {
                try {
                    //check we have data
                    let dataToSubmit = dirtyValuesUtil(dirtyFields, dataForm)

                    onSubmit({...dataToSubmit, ...dataRisk})
                } catch
                    (e) {
                    console.error(e)
                }

            }
        )
        ()

    }


    return <form onSubmit={handleSubmit(onHandleSubmit)}>
        {data.policyType && data.policyType.type && data.projectId && <fieldset>
            <legend className={"mt-6 mb-0 pt-8"}>{t("Project")}</legend>
            <div className="row">
                <div className="col-12 col-lg-4">
                    <FormInput readOnly={true} name="projectId" label={"Project ID"} control={control}
                               errors={errors}

                    />
                </div>

            </div>
        </fieldset>}

        <fieldset>
            <DynamicData control={control} errors={errors} readOnly={readOnly}
                         policyTypeId={data.policyTypeId ? data.policyTypeId : policyTypeId}
                         formData={watch()} setValue={setValue}/>
        </fieldset>


        {!readOnly && <div className={"d-flex justify-content-end" +
            " mt-8"}>
            <Button label={t("Save")}/>
        </div>}
    </form>

}

