export const generalConditionsConst = [
    {
        label: "GENERAL_CONDITION",
        value: "GENERAL_CONDITION"
    }, {
        label: "PRODUCT_FILE",
        value: "PRODUCT_FILE"
    }, {
        label: "REQUEST",
        value: "REQUEST"
    }
]



