/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 05/04/2023
 * @descriptionThe The `App` component serves as the entry point for the application. It manages the global state and handles user authentication. The component sets up Axios interceptors to handle requests and responses, including token refresh and error handling. Additionally, it initializes translations for the calendar.
 *
 * The `GlobalContext` is created using `React.createContext()` to provide global state management throughout the application.
 *
 * The component returns a structure wrapped in the `Router` component from `react-router-dom`. It provides the `GlobalContext.Provider` to pass down the global state to all components in the application. The `ResponsiveStructure`, `ToastContainer`, and `ConfirmDialog` components are also rendered within this structure.
 */
import './App.css';
import {BrowserRouter as Router} from "react-router-dom"
import React, {useEffect, useState} from "react"
import {getCookieUtil} from "./utils/getCookie.util";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ResponsiveStructure from "./components/ResponsiveStructure/ResponsiveStructure";
import ToastContent from "./components/ToastContent/ToastContent";
import {es} from "./assets/i18n/es";
import {API, refreshToken} from "./queries/api";
import {ConfirmDialog} from "primereact/confirmdialog";
import {addLocale} from 'primereact/api';


function App() {

    const jwtCookie = getCookieUtil("token");
    const unParsedUser = getCookieUtil("user");
    const [user, setUser] = useState(unParsedUser ? unParsedUser : {});
    const [isLoading, setIsLoading] = useState(true);
    const [isLogged, setIsLogged] = useState(jwtCookie ? jwtCookie : null);


    useEffect(() => {
        API.interceptors.request.use(
            config => {
                setIsLoading(true)

                const token = getCookieUtil('token')
                if (token) {
                    config.headers.Authorization = "Bearer " + token
                }
                return config
            },
            error => {
                return Promise.reject(error)
            }
        );

        API.interceptors.response.use(function (response) {
            setIsLoading(false)
            // Do something with response data
            return response;
        }, async function (error) {
            setIsLoading(false)

            const originalConfig = error.config;
            if (error.response) {
                // Access Token was expired
                if (error.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;

                    try {
                        // we need to avoid refresh of refresh
                        if (error.config.url !== "user/refresh") {
                            await refreshToken()
                        }
                        return API(originalConfig);
                    } catch (_error) {
                        if (_error.response && _error.response.data) {
                            return Promise.reject(_error.response.data);
                        }

                        return Promise.reject(_error);
                    }
                }
                if (error.response.status === 404) {
                    window.location = "/";
                } else {

                    toast.error(<ToastContent title={es[error.response.status]}
                                              text={Object.values(error.response.data).join("\n")}/>)

                }
            }

            return Promise.reject(error);
        });
    }, []);

    useEffect(() => {
        //translations for calendar
        addLocale('es', {
            firstDayOfWeek: 1,
            showMonthAfterYear: true,
            dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
            dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
            dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
            monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
            monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
            today: 'Hoy',
            clear: 'Limpiar'
        });
    }, [])


    return (
        <Router>
            <GlobalContext.Provider value={{user, setUser, isLogged, setIsLogged, isLoading, setIsLoading}}>
                <ResponsiveStructure/>
                <ToastContainer/>
                <ConfirmDialog/>

            </GlobalContext.Provider>
        </Router>
    );
}

export default App;


export const GlobalContext = React.createContext();
