/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 05/04/2023
 * @descriptionThe The HomePage component simply renders a message indicating that it is the home page
 */
export default function HomePage () {
    return (<div>
        Página de inicio
    </div>)
}
