/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 26/04/2023
 * @descriptionThe This component manages insurance details, allowing creation and editing. It retrieves data based on parameters from the URL, updates the insurance through API calls, and renders the appropriate components. It also displays tabs for managing contacts, customer services, and files related to the insurance.
 */
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Insurance from "../../components/Insurance/Insurance";
import {TabPanel, TabView} from "primereact/tabview";
import InsuranceContactsTable from "../../components/InsuranceContactsTable/InsuranceContactsTable";
import InsuranceCustomerServicesTable
    from "../../components/InsuranceCustomerServicesTable/InsuranceCustomerServicesTable";
import InsuranceFilesTable from "../../components/InsuranceFilesTable/InsuranceFilesTable";
import {useNavigate, useParams} from "react-router-dom";
import {API} from "../../queries/api";
import {hashFeature} from "../../utils/primereactTab.util";

export default function InsurancesDetailPage() {
    const [insurance, setInsurance] = useState();
    const {id, action} = useParams();
    const [activeIndex, setActiveIndex] = useState()
    const {t} = useTranslation();


    const navigate = useNavigate();

    const onSubmit = async (data) => {
        if (id === "new") {
            await API.post("company/company/create", {phonePrefix: 34, ...data});
        } else {
            await API.put("company/company/update/" + id, data);
        }
        navigate("/insurances")


    }

    const getInsurance = async (id) => {
        if (id && isFinite(id)) {
            const {
                data: insurance
            } = await API("company/company/view/" + id);
            setInsurance(insurance);
        } else {
            setInsurance({phonePrefix: 34, mobilePhonePrefix: 34, claimPhonePrefix: 34});
        }
    };


    useEffect(() => {
        getInsurance(id);
    }, []);

    return (
        <>
            {insurance && <>
                <div className={"b-box"}>
                    <h2 className={"mb-8"}>
                        {id === "new" && t("Creation of")} {t("Insurance")} {insurance.name}
                    </h2>

                    <Insurance readOnly={!(id === "new" || action === "edit")} data={insurance} onSubmit={onSubmit}/>
                </div>
                {id !== "new" && !action && <div className="mt-8 b-box">
                    <TabView  {...hashFeature(activeIndex, setActiveIndex)}>
                        <TabPanel header={t("Contacts")}>
                            <InsuranceContactsTable insuranceId={insurance.id}/>
                        </TabPanel>
                        <TabPanel header={t("Customer service")}>
                            <InsuranceCustomerServicesTable insuranceId={insurance.id}/>
                        </TabPanel>

                        <TabPanel header={t("Files")}>
                            <InsuranceFilesTable insuranceId={insurance.id}/>
                        </TabPanel>
                    </TabView>
                </div>}
            </>}
        </>
    );
}
