/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 06/10/2023
 * @descriptionThe This component represents a profile page where users can view their basic data such as first name, last name, email, and position. The form fields are pre-filled with the user's information obtained from the global context. The fields are read-only, indicating that users cannot modify their profile data from this page. The page is structured with a fieldset containing basic data, including first name, last name, email, and position. Each field is displayed within a row using the FormInput component, which is set to read-only mode.
 */
import { useTranslation } from "react-i18next";
import FormInput from "../../components/FormInput/FormInput";
import { useForm } from "react-hook-form";
import { useContext } from "react";
import { GlobalContext } from "../../App";

const readOnly = true;
export default function ProfilePage () {
    const { user } = useContext(GlobalContext)
    const { t } = useTranslation();
    const {
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            ...user,
        }
    });

    return <div className={"b-box"}>
        <h2>
            {t("My profile")}
        </h2>

        <form>
            <fieldset>
                <legend>{t("Basic data")}</legend>

                <div className={"py-4"}>
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <FormInput readOnly={readOnly} name="first_name" label={"First name"} control={control}
                                       errors={errors}
                                       rules={{ required: true }}/>
                        </div>

                        <div className="col-12 col-lg-4">
                            <FormInput readOnly={readOnly} name="last_name" label={"Second name"}
                                       control={control} errors={errors}
                                       rules={{ required: true }}/>
                        </div>

                        <div className="col-12 col-lg-4">
                            <FormInput readOnly={readOnly} name="email" label={"Email"}
                                       control={control} errors={errors} type={"email"}
                                       rules={{ required: true }}/>
                        </div>

                        <div className="col-12 col-lg-4">
                            <FormInput readOnly={readOnly} name="rol" label={"Position"}
                                       control={control} errors={errors}
                                       rules={{ required: true }}/>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>

    </div>
}
