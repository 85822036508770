/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 12/10/2023
 * @description The `PolicyProposalsTable` component is responsible for displaying a table of proposals associated with a specific insurance policy. It fetches proposal data based on the policy ID and renders it in a paginated table format. Users can view proposal details such as proposal number, effective date, expiration date, and status. The component includes functionality to fetch data lazily, ensuring efficient handling of large datasets. Additionally, it provides a button to create new proposals for the corresponding policy type.
 */
import {useEffect, useState} from "react";
import {API} from "../../queries/api";
import {createFilterParamsUtil} from "../../utils/createFilterParams.util";
import ProposalsTable from "../ProposalsTable/ProposalsTable";
import {Link} from "react-router-dom";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";
import {checkAndReturnCorrectLazyTableValues} from "../../utils/checkAndReturnCorrectLazyTableValues.util";

export default function PolicyProposalsTable({policyId, policyTypeId}) {
    const [proposals, setProposals] = useState([])
    const {t} = useTranslation()

    const [lazyTable, setLazyTable] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
    });

    const onLazy = (event) => {
        const newLazyTable = checkAndReturnCorrectLazyTableValues(lazyTable, event);
        setLazyTable(newLazyTable);
        getLazyData(newLazyTable);
    };

    const getLazyData = async (event) => {
        const {data: {data: proposals, count}} = await API(
            "policy/proposal/list" + createFilterParamsUtil(event) + "&filter[parentPolicyId]=" + policyId
        );
        setLazyTable({...event, totalRecords: count})
        setProposals(proposals.map((d) => ({
            ...d,
            effectiveDate: new Date(d.effectiveDate),
            expirationDate: new Date(d.expirationDate),
        })));
    };

    useEffect(() => {
        getLazyData(lazyTable);
    }, []);

    const tableHeader = () => (
        <div className={"d-flex justify-content-end"}>
            <Link to={"./proposals/new?policyTypeId=" + policyTypeId}>
                <Button label={t("Create proposal")}></Button>
            </Link>
        </div>
    );


    return <ProposalsTable data={proposals} onLazy={onLazy} lazyTable={lazyTable}
                           isCreationDisabled={false} tableHeader={tableHeader}/>

}
