/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 05/04/2023
 * @description The `Menu` component renders a sidebar menu based on the user's authentication status and current location. It includes options for user profile management, navigation to various sections of the application, and logout functionality. The sidebar can be collapsed or expanded.
 */
import {Menu as SideMenu, MenuItem, Sidebar, SubMenu, useProSidebar} from 'react-pro-sidebar';
import "./Menu.scss";
import {useTranslation} from "react-i18next";
import {Link, useLocation, useNavigate} from "react-router-dom"
import {useContext} from "react";
import {GlobalContext} from "../../App";
import {Avatar} from 'primereact/avatar';
import {logout} from "../../queries/api";

export default function Menu({setIsShowingMenu}) {
    let location = useLocation();
    const navigate = useNavigate();
    const {user} = useContext(GlobalContext)
    const {collapseSidebar, collapsed} = useProSidebar();
    const {t} = useTranslation();

    return ((location.pathname === "/login" || location.pathname === "/register") ? <></> :
            <div className={"c-menu" + (collapsed ? " c-menu--collapsed" : "")}>
                <button className={"c-menu__collapse material-symbols-outlined"} onClick={() => collapseSidebar()}>
                    chevron_left
                </button>
                <Sidebar>
                    <SideMenu>

                        <div className={"c-menu__header"}>
                            <img className={"c-menu__logo"}
                                 src={collapsed ? "/images/logotype.svg" : "/images/logo.svg"} alt="Logo"/>
                        </div>
                        <div className={"c-menu__profile"}>
                            <SubMenu
                                label={user.firstName === null || user.firstName === "" ? t("No name") : user.firstName + " " + user.lastName}
                                icon={<Avatar
                                    image={"https://definicion.de/wp-content/uploads/2019/07/perfil-de-usuario.png"}
                                    shape="circle"/>}>
                                <MenuItem active={location.pathname === "/change-password"}
                                          icon={<span className="material-symbols-outlined">lock_reset</span>}
                                          component={<Link onClick={() => setIsShowingMenu(false)}
                                                           to={"/change-password"}/>}>{t("Change password")}</MenuItem>
                                <MenuItem active={location.pathname === "/profile"}
                                          icon={<span className="material-symbols-outlined">account_circle</span>}
                                          component={<Link onClick={() => setIsShowingMenu(false)}
                                                           to={"/profile"}/>}>{t("My profile")}</MenuItem>
                                <MenuItem icon={<span className="material-symbols-outlined">logout</span>}
                                          component={<div onClick={() => {
                                              logout();
                                              navigate("/login")
                                          }}/>}>
                                    {t("Log out")}
                                </MenuItem>
                            </SubMenu>
                        </div>

                        <MenuItem active={location.pathname === "/backoffice-users"}
                                  icon={<span className="material-symbols-outlined">group</span>}
                                  component={<Link onClick={() => setIsShowingMenu(false)}
                                                   to={"/backoffice-users"}/>}>{t("Backoffice users")}</MenuItem>
                        <MenuItem active={location.pathname === "/backoffice-users"}
                                  icon={<span className="material-symbols-outlined">assignment</span>}
                                  component={<Link onClick={() => setIsShowingMenu(false)}
                                                   to={"/tasks"}/>}>{t("Tasks")}</MenuItem>
                        <SubMenu defaultOpen label={t("Insurances")} icon={<span
                            className="material-symbols-outlined">apartment</span>}>
                            <MenuItem active={location.pathname === "/insurances"}
                                      icon={<span className="material-symbols-outlined">domain</span>}
                                      component={<Link onClick={() => setIsShowingMenu(false)}
                                                       to={"/insurances"}/>}>{t("Insurances")}</MenuItem>
                        </SubMenu>

                        <SubMenu defaultOpen label={t("Users")} icon={<span
                            className="material-symbols-outlined">content_paste</span>}>

                            <MenuItem active={location.pathname === "/users"}
                                      icon={<span className="material-symbols-outlined">group</span>}
                                      component={<Link onClick={() => setIsShowingMenu(false)}
                                                       to={"/users"}/>}>{t("Users")}</MenuItem>
                            <MenuItem active={location.pathname === "/policies"}
                                      icon={<span className="material-symbols-outlined">description</span>}
                                      component={<Link onClick={() => setIsShowingMenu(false)}
                                                       to={"/policies"}/>}>{t("Policies")}</MenuItem>
                            <MenuItem active={location.pathname === "/proposals"}
                                      icon={<span className="material-symbols-outlined">sell</span>}
                                      component={<Link onClick={() => setIsShowingMenu(false)}
                                                       to={"/proposals"}/>}>{t("Proposals")}</MenuItem>
                            <MenuItem icon={<span className="material-symbols-outlined">report</span>}
                                      component={<Link onClick={() => setIsShowingMenu(false)}
                                                       to={"/claims"}/>}>{t("Claims")}</MenuItem>
                        </SubMenu>
                    </SideMenu>
                </Sidebar>
            </div>
    )
}
