/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 21/04/2023
 * @descriptionThe The `Routes` component defines all the routes for the application using the `react-router-dom` library. It includes routes for various pages such as login, profile, insurances, policies, proposals, users, backoffice users, change password, and tasks. Each route is wrapped in a `RequireAuth` component to ensure authentication before accessing the corresponding page. The routes handle different parameters and actions to navigate to specific sections or perform actions on entities. If the path doesn't match any defined routes, it redirects to the login page.
 */
import HomePage from "./HomePage/HomePage";
import {Navigate, Route, Routes as BRoutes} from "react-router-dom"
import InsurancesPage from "./InsurancesPage/InsurancesPage";
import InsurancesDetailPage from "./InsurancesDetailPage/InsurancesDetailPage";
import ChangePasswordPage from "./ChangePasswordPage/ChangePasswordPage";
import PoliciesPage from "./PoliciesPage/PoliciesPage";
import PoliciesDetailPage from "./PoliciesDetailPage/PoliciesDetailPage";
import LoginPage from "./LoginPage/LoginPage";
import BackofficeUsersPage from "./BackofficeUsersPage/BackofficeUsersPage";
import BackofficeUsersDetailPage from "./BackofficeUsersDetailPage/BackofficeUsersDetailPage";
import UsersPage from "./UsersPage/UsersPage";
import UsersDetailPage from "./UsersDetailPage/UsersDetailPage";
import InsurancesDetailCustomerServicePage
        from "./InsurancesDetailCustomerServicePage/InsurancesDetailCustomerServicePage";
import UsersDetailFilePage from "./UsersDetailFilePage/UsersDetailFilePage";
import UsersDetailAddressPage from "./UsersDetailAddressPage/UsersDetailAddressPage";
import UsersDetailContactPage from "./UsersDetailContactPage/UsersDetailContactPage";
import InsurancesDetailContactPage from "./InsurancesDetailContactPage/InsurancesDetailContactPage";
import InsurancesDetailFilePage from "./InsurancesDetailFilePage/InsurancesDetailFilePage";
import UsersDetailDocumentPage from "./UsersDetailDocumentPage/UsersDetailDocumentPage";
import RequireAuth from "../components/RequireAuth/RequireAuth";
import ProposalsPage from "./ProposalsPage/ProposalsPage";
import ProposalsDetailPage from "./ProposalsDetailPage/ProposalsDetailPage";
import ProposalsDetailBudgetPage from "./ProposalsDetailBudgetPage/ProposalsDetailBudgetPage";
import ProfilePage from "./ProfilePage/ProfilePage";
import ClaimsDetailPage from "./ClaimsDetailPage/ClaimsDetailPage";
import ClaimsPage from "./ClaimsPage/ClaimsPage";
import TasksPage from "./TasksPage/TasksPage";
import TasksDetailPage from "./TasksDetailPage/TasksDetailPage";


export default function Routes() {
    return (
        <BRoutes>

            <Route path={"/"} re element={<RequireAuth><HomePage/></RequireAuth>}/>
            <Route path={"/login"} element={<LoginPage/>}/>
            <Route path={"/profile"} element={<RequireAuth><ProfilePage/></RequireAuth>}/>
            <Route path={"/insurances"} element={<RequireAuth><InsurancesPage/></RequireAuth>}/>
            <Route path={"/insurances/:id"} element={<RequireAuth><InsurancesDetailPage/></RequireAuth>}/>
            <Route path={"/insurances/:id/:action"} element={<RequireAuth><InsurancesDetailPage/></RequireAuth>}/>
            <Route path={"/insurances/:id/contacts/:idContact"}
                   element={<RequireAuth><InsurancesDetailContactPage/></RequireAuth>}/>
            <Route path={"/insurances/:id/contacts/:idContact/:action"}
                   element={<RequireAuth><InsurancesDetailContactPage/></RequireAuth>}/>
            <Route path={"/insurances/:id/files/:idFile"}
                   element={<RequireAuth><InsurancesDetailFilePage/></RequireAuth>}/>
            <Route path={"/insurances/:id/files/:idFile/:action"}
                   element={<RequireAuth><InsurancesDetailFilePage/></RequireAuth>}/>
            <Route path={"/insurances/:id/customer-service/:idCustomerService"}
                   element={<RequireAuth><InsurancesDetailCustomerServicePage/></RequireAuth>}/>
            <Route path={"/insurances/:id/customer-service/:idCustomerService/:action"}
                   element={<RequireAuth><InsurancesDetailCustomerServicePage/></RequireAuth>}/>
            <Route path={"/policies"} element={<RequireAuth><PoliciesPage/></RequireAuth>}/>
                <Route path={"/policies/:id"} element={<RequireAuth><PoliciesDetailPage/></RequireAuth>}/>
                <Route path={"/policies/:id/tasks/:idTask"} element={<RequireAuth><TasksDetailPage/></RequireAuth>}/>
                <Route path={"/policies/:id/tasks/:idTask/:action"} element={<RequireAuth><TasksDetailPage/></RequireAuth>}/>
            <Route path={"/policies/:id/:action"} element={<RequireAuth><PoliciesDetailPage/></RequireAuth>}/>
            <Route path={"/policies/:id/proposals/:idProposal"}
                   element={<RequireAuth><ProposalsDetailPage/></RequireAuth>}/>
            <Route path={"/policies/:id/proposals/:idProposal/:action"}
                   element={<RequireAuth><ProposalsDetailPage/></RequireAuth>}/>
            <Route path={"/policies/:id/claims/:idClaim"}
                   element={<RequireAuth><ClaimsDetailPage/></RequireAuth>}/>
            <Route path={"/policies/:id/claims/:idClaim/:action"}
                   element={<RequireAuth><ClaimsDetailPage/></RequireAuth>}/>


            <Route path={"/claims"} element={<RequireAuth><ClaimsPage/></RequireAuth>}/>
            <Route path={"/claims/:idClaim"} element={<RequireAuth><ClaimsDetailPage/></RequireAuth>}/>
            <Route path={"/claims/:idClaim/:action"} element={<RequireAuth><ClaimsDetailPage/></RequireAuth>}/>
                <Route path={"/claims/:id/tasks/:idTask"} element={<RequireAuth><TasksDetailPage/></RequireAuth>}/>
                <Route path={"/claims/:id/tasks/:idTask/:action"} element={<RequireAuth><TasksDetailPage/></RequireAuth>}/>
            {/*<Route path={"/policies/:id"} element={<RequireAuth><Claims/></RequireAuth>}/>*/}

            <Route path={"/proposals"} element={<RequireAuth><ProposalsPage/></RequireAuth>}/>
            <Route path={"/proposals/:id"} element={<RequireAuth><ProposalsDetailPage/></RequireAuth>}/>
            <Route path={"/proposals/:id/:action"} element={<RequireAuth><ProposalsDetailPage/></RequireAuth>}/>
            <Route path={"/proposals/:id/budgets/:idBudget"}
                   element={<RequireAuth><ProposalsDetailBudgetPage/></RequireAuth>}/>
            <Route path={"/proposals/:id/budgets/:idBudget/:action"}
                   element={<RequireAuth><ProposalsDetailBudgetPage/></RequireAuth>}/>
                <Route path={"/proposals/:id/tasks/:idTask"} element={<RequireAuth><TasksDetailPage/></RequireAuth>}/>
                <Route path={"/proposals/:id/tasks/:idTask/:action"} element={<RequireAuth><TasksDetailPage/></RequireAuth>}/>

            <Route path={"/users"} element={<RequireAuth><UsersPage/></RequireAuth>}/>
            <Route path={"/users/:id"} element={<RequireAuth><UsersDetailPage/></RequireAuth>}/>
            <Route path={"/users/:id/:action"} element={<RequireAuth><UsersDetailPage/></RequireAuth>}/>
            <Route path={"/users/:userId/policies/:id"}
                   element={<RequireAuth><PoliciesDetailPage/></RequireAuth>}/>
            <Route path={"/users/:id/proposals/:idProposal"}
                   element={<RequireAuth><ProposalsDetailPage/></RequireAuth>}/>
            <Route path={"/users/:id/contacts/:idContact"}
                   element={<RequireAuth><UsersDetailContactPage/></RequireAuth>}/>
            <Route path={"/users/:id/contacts/:idContact/:action"}
                   element={<RequireAuth><UsersDetailContactPage/></RequireAuth>}/>
            <Route path={"/users/:id/addresses/:idAddress"}
                   element={<RequireAuth><UsersDetailAddressPage/></RequireAuth>}/>
            <Route path={"/users/:id/addresses/:idAddress/:action"}
                   element={<RequireAuth><UsersDetailAddressPage/></RequireAuth>}/>
            <Route path={"/users/:id/files/:idFile"} element={<RequireAuth><UsersDetailFilePage/></RequireAuth>}/>
            <Route path={"/users/:id/files/:idFile/:action"}
                   element={<RequireAuth><UsersDetailFilePage/></RequireAuth>}/>
            <Route path={"/users/:id/documents/:idDocument"}
                   element={<RequireAuth><UsersDetailDocumentPage/></RequireAuth>}/>
            <Route path={"/users/:id/documents/:idDocument/:action"}
                   element={<RequireAuth><UsersDetailDocumentPage/></RequireAuth>}/>
                <Route path={"/users/:id/tasks/:idTask"} element={<RequireAuth><TasksDetailPage/></RequireAuth>}/>
                <Route path={"/users/:id/tasks/:idTask/:action"} element={<RequireAuth><TasksDetailPage/></RequireAuth>}/>

            <Route path={"/backoffice-users"} element={<RequireAuth><BackofficeUsersPage/></RequireAuth>}/>
            <Route path={"/backoffice-users/:id"} element={<RequireAuth><BackofficeUsersDetailPage/></RequireAuth>}/>
            <Route path={"/backoffice-users/:id/:action"}
                   element={<RequireAuth><BackofficeUsersDetailPage/></RequireAuth>}/>

            <Route path={"/change-password"} element={<RequireAuth><ChangePasswordPage/></RequireAuth>}/>

            <Route path={"/tasks"} element={<RequireAuth><TasksPage/></RequireAuth>}/>
            <Route path={"/tasks/:idTask"} element={<RequireAuth><TasksDetailPage/></RequireAuth>}/>
            <Route path={"/tasks/:idTask/:action"} element={<RequireAuth><TasksDetailPage/></RequireAuth>}/>
            <Route
                path="*"
                element={<Navigate to="/login" replace/>}
            />
        </BRoutes>
    )
}
