/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 04/07/2023
 * @descriptionThe The `UserDocument` component is designed to handle user document data, including images of the document's front and back sides. It provides fields for users to input their name, surnames, gender, expiration date, document number, and birth date. Users can upload images for the front and back sides of the document, which are displayed using the `FilePondImageEditor` component. The component supports form submission and validation, with the ability to save data when not in read-only mode. Additionally, it utilizes state management to handle file uploads and form data.
 */
import FormInput from "../FormInput/FormInput";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { dirtyValuesUtil } from "../../utils/dirtyValues.util";
import { useState } from "react";
import { checkAndTransformDirtyFileUtil } from "../../utils/checkAndTransformDirtyFile.util";
import { gendersConst } from "../../consts/genders.const";
import { FilePondImageEditor } from "../FilePondImageEditor/FilePondImageEditor";

const fileKey = "frontPicture"
const fileBackKey = "backPicture"


export default function UserDocument ({ data, readOnly = false, onSubmit }) {
    const [files, setFiles] = useState(data[fileKey])
    const [backFiles, setBackFiles] = useState(data[fileBackKey]);
    const { t } = useTranslation();

    let maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 18);
    const actualDate = new Date();

    const {
        control,
        formState: { errors, dirtyFields },
        handleSubmit,
    } = useForm({
        defaultValues: {
            ...data,
            expirationDate: data.expirationDate ? new Date(data.expirationDate) : "",
            dateBirth: data.dateBirth ? new Date(data.dateBirth) : "",
        }
    });


    const onHandleSubmit = (dataForm) => {
        let dataToSubmit = dirtyValuesUtil(dirtyFields, dataForm)
        //if we have file we need to transform it to base64
        dataToSubmit = checkAndTransformDirtyFileUtil(dataForm[fileKey], files, dataToSubmit, fileKey);
        dataToSubmit = checkAndTransformDirtyFileUtil(dataForm[fileBackKey], files, dataToSubmit, fileBackKey);
        onSubmit(dataToSubmit)
    }

    return <form onSubmit={handleSubmit(onHandleSubmit)}>


        <fieldset>
            <legend>{t("Images")}</legend>
            <div className={"py-4"}>
                <div className="row">

                    <div className="col-6">
                        <FilePondImageEditor files={files} setFiles={setFiles} fileKey={fileKey} readOnly={readOnly}/>
                    </div>

                    <div className="col-6">
                        <FilePondImageEditor files={backFiles} setFiles={setBackFiles} fileKey={fileBackKey}
                                             readOnly={readOnly}/>
                    </div>
                </div>


            </div>


        </fieldset>

        <fieldset>
            <legend>{t("Document data")}</legend>

            <div className={"py-4"}>
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="name" label={"Name"} control={control} errors={errors}
                                   rules={{ required: true }}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="surname" label={"First surname"} control={control}
                                   errors={errors} rules={{ required: true }}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="secondSurname" label={"Second surname"} control={control}
                                   errors={errors} rules={{ required: true }}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="gender" label={"Gender"} control={control}
                                   errors={errors}
                                   rules={{ required: true }} options={gendersConst(t)} type={"select"}
                        />
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="expirationDate" label={"Expiration date"} control={control}
                                   errors={errors} rules={{ required: true, minDate: actualDate }} type={"calendar"}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="documentNumber" label={"Document number"} control={control}
                                   errors={errors} rules={{ required: true }}/>
                    </div>


                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="dateBirth" label={"Birth date"} control={control}
                                   errors={errors} rules={{ required: true, maxDate }} type={"calendar"}/>
                    </div>


                </div>
            </div>


        </fieldset>

        {!readOnly && <div className={"d-flex justify-content-end mt-8"}>
            <Button label={t("Save")}/>
        </div>}
    </form>
}
