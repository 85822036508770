/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 20/01/2024
 * @description The `PostponeAlarm` component is designed to handle the postponement of alarms associated with tasks. It presents a form where users can provide a description for the task and specify a new alarm date if the type is "postpone." The form includes input fields for the task description and, if applicable, the new alarm date. Users can submit the form to save their changes. The component utilizes translation hooks (`useTranslation`) to support multiple languages and form validation to ensure required fields are filled out before submission.
 */
import FormInput from "../FormInput/FormInput";
import {Button} from "primereact/button";
import React from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

export default function PostponeAlarm({ onSubmit, type}) {
    const {t} = useTranslation()
    const {
        control,
        formState: {errors},
        handleSubmit
    } = useForm({
        defaultValues: {
            alarmAt: new Date()
        }
    });

    return <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput readOnly={false} name="text" label={"Description"}
                   control={control} type={"textarea"}  rules={{required: true}}
                   errors={errors}/>
        {type === "postpone" &&<FormInput readOnly={false} name="alarmAt" label={"New alarm date"}
                   control={control} type={"calendar"} config={{hourFormat: "24"}} rules={{required: true}}
                   errors={errors}/>}
        <div className={"d-flex justify-content-end mt-6"}>
            <Button label={t("Save")}/>
        </div>
    </form>


}
