/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 05/04/2023
 * @descriptionThe This component represents a login page where users can log in to their accounts. It includes a form for entering username and password, with validation for required fields. Upon submission, it sends a POST request to the backend API for authentication. If successful, it updates user information and navigates to the home page.
 */
import FormInput from "../../components/FormInput/FormInput";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Button} from "primereact/button";
import {API} from "../../queries/api";
import {useNavigate} from "react-router-dom";
import {updateUserUtil} from "../../utils/updateUser.util";
import {GlobalContext} from "../../App";
import {useContext, useEffect} from "react";

export default function LoginPage() {
    const {t} = useTranslation();
    const {setUser, setIsLogged, setIsLoading} = useContext(GlobalContext)

    const {
        control,
        formState: {errors},
        handleSubmit,
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        const {data: resData} = await API.post("user-bo/login", data);
        updateUserUtil(resData, setUser, setIsLogged, resData.access_token, resData.expires_in, resData.refresh_token, resData.refresh_expires_in)
        const {data: resUser} = await API("user-bo/user/me");
        updateUserUtil(resUser, setUser)

        navigate("/")
    }
    useEffect(() => {
        setIsLoading(false)
    }, [])

    return (
        <div className={"b-container"}>
            <div className={"b-container b-container--center"}>
                <div>
                    <div className={"d-flex justify-content-center"}>
                        <img width={400} src="/images/logo.svg" alt="Logo"/>
                    </div>
                    <div className={"b-box p-10"}>
                        <h1 className={"mb-0"}>{t("Login")}</h1>

                        <p className={"b-text b-text--s"}>{t("Log in your account to admin all information from" +
                            " Prudenzia" +
                            " environments")}.</p>
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="row">
                                <div className="col-12">
                                    <FormInput name="username" label={"Name"} control={control} errors={errors}
                                               rules={{required: true}}/>
                                </div>

                                <div className="col-12">
                                    <FormInput name="password" label={"Password"} control={control} errors={errors}
                                               type={"password"} feedback={false}

                                               rules={{required: true}}/>
                                </div>
                            </div>

                            <div className={"d-flex mt-8"}>
                                <Button className={"p-button--full"} label={t("Send")}/>
                            </div>

                        </form>
                    </div>
                </div>
            </div>

        </div>
    )
}
