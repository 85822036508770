/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 15/01/2024
 * @description The `BranchDropdown` component renders a dropdown menu of policy types fetched from the server. It allows users to select a policy type and redirects them to the create proposal page with the selected policy type ID as a query parameter.
 *
 * The component consists of:
 * - A Dropdown component displaying policy types fetched from the server.
 * - A link button that redirects users to the create proposal page with the selected policy type ID as a query parameter.
 */
import React, {useEffect, useState} from "react";
import {API} from "../../queries/api";
import {Dropdown} from "primereact/dropdown";
import {useTranslation} from "react-i18next";
import {Button} from "primereact/button";
import {Link} from "react-router-dom";

export default function BranchDropdown() {
    const [policyTypes, setPolicyTypes] = useState()
    const [field, setField] = useState()
    const {t} = useTranslation()
    useEffect(() => {
        async function getPolicyTypes() {
            try {
                const {data} = await API("policy/policy-type/list");
                setPolicyTypes(data.map(item => ({label: item.name, value: item.id.toString()})))
            } catch (e) {
                console.log(e)
            }
        }

        getPolicyTypes();
    }, []);
    return <div>

        <Dropdown
            value={field}
            options={policyTypes}
            placeholder={t("Select")}
            emptyMessage={t("No results")}
            onChange={(e) => {
                setField(e.value)
            }}
        />

        <div className={"d-flex justify-content-end mt-6"}>
            <Link to={"./proposals/new?policyTypeId=" + field}>
                <Button label={t("Create proposal")}></Button>
            </Link>
        </div>
    </div>
}
