/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 17/01/2024
 * @descriptionThe This component represents a page for viewing and managing tasks. It allows users to create, edit, postpone, and finish tasks. Tasks can be associated with policies, users, proposals, or claims, and the component fetches relevant data accordingly. Additionally, it distinguishes between regular tasks and notes, providing appropriate functionalities for each type.
 */
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {API} from "../../queries/api";
import Task from "../../components/Task/Task";
import {GlobalContext} from "../../App";


export default function TasksDetailPage() {
    const [task, setTask] = useState()
    const {user} = useContext(GlobalContext);
    const [policyUser, setPolicyUser] = useState()
    const {t} = useTranslation();
    const location = useLocation()
    const {idTask, id, action} = useParams();
    const [searchParams] = useSearchParams();
    const type = searchParams.get("type"); // Task type. Specifically to create notes
    const isNote = task && task.id ? (task.taskType && task.taskType.type === "note_task"): type === "note";

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        if (idTask === "new") {
            await API.post("task/task/create", {
                ...data,
                taskTypeId: !type ?  9 : 11,
                status:  !type ?  "PENDING" : "CANCELLED",
                assignedToId:data.assignedToId ? data.assignedToId : user.id,
                ...createPostData()
            }); //seems like this id is by default for all tasks
            navigateTo()
        } else {
            await API.put("task/task/update/" + idTask, data);
            navigate("/tasks")
        }
    }

    const onPostpone = async (data) => {
        await API.put("task/task/update/" + idTask, data);
        navigate("/tasks")
    }

    const onFinish = async (data) => {
        try {

            await API.put("task/task/update/" + idTask, {...data, status: "CLOSED"});
            navigate("/tasks")

        } catch (e) {
            console.error(e)
        }
    }

    const navigateTo = () => {
        if (location.pathname.includes("policies")) {
            navigate("/policies/" + id)
        } else if (location.pathname.includes("users")) {
            navigate("/users/" + id)
        } else if (location.pathname.includes("proposals")) {
            navigate("/proposals/" + id)
        } else if (location.pathname.includes("claims")) {
            navigate("/claims/" + id)
        } else {
            navigate("/tasks")
        }
    }

    const createPostData = () => {
        if (location.pathname.includes("policies")) {
            return {policyId: Number(id)}
        } else if (location.pathname.includes("users")) {
            return {userId: id}
        } else if (location.pathname.includes("proposals")) {
            return {proposalId: Number(id)}
        } else if (location.pathname.includes("claims")) {
            return {claimId: Number(id)}
        } else {
            return {}
        }
    }


    const getTask = async (idTask) => {
        if (idTask && isFinite(idTask)) {

            const {
                data: task
            } = await API("task/task/view/" + idTask + "?extraFields=taskFiles,taskType,comments,assignedTo");

            // this is done only when user are seeing a global task
            if (!id) {
                if (task.userId) {
                    await getUser(task.userId);
                } else if (task.policyId) {
                    await getPolicy(task.policyId);
                } else if (task.proposalId) {
                    await getProposal(task.proposalId);
                } else if (task.claimId) {
                    await getClaim(task.claimId);
                }
            }

            setTask(task);
        } else {
            setTask({task});
            setPolicyUser({});
        }
    };


    const getPolicy = async (id) => {
        if (id && isFinite(id)) {
            const {
                data: policy
            } = await API("policy/policy/view/" + id + "?extraFields=policyType");
            await getUser(policy.userId)
        } else {
            await getUser(null)
        }


    };

    const getProposal = async (id) => {
        const {
            data: proposal
        } = await API("policy/proposal/view/" + id);
        await getUser(proposal.userId)
    };
    const getClaim = async (id) => {
        const {
            data: claim
        } = await API("policy/claim/view/" + id + "?extraFields=policy");
        await getUser(claim.policy.userId)
    };

    const getUser = async (userId) => {
        if (userId) {
            const {data} = await API(`user/user/view/${userId}`)
            setPolicyUser(data)
        } else {
            setPolicyUser({})
        }
    }

    useEffect(() => {
        getTask(idTask);

        if (location.pathname.includes("users")) {
            getUser(id);
        }
        if (location.pathname.includes("policies")) {
            getPolicy(id);
        }
        if (location.pathname.includes("proposals")) {
            getProposal(id);
        }

        if (location.pathname.includes("claims")) {
            getClaim(id);
        }
    }, []);

    return <>
        {task && policyUser && <>

            <div className={"b-box"}>
                <h2 className={"mb-8"}>{idTask === "new" && t("Creation of")} {isNote ? t("Note"): t("Task")} {idTask !== "new" && "(" + task.id + ")"}</h2>

                <Task data={task} user={policyUser}
                      readOnly={!(idTask === "new" || action === "edit")}
                      isNew={idTask === "new"}
                      onSubmit={onSubmit}
                      onPostpone={onPostpone}
                      onFinish={onFinish}
                      isNote={isNote}
                />

            </div>


        </>
        }
    </>
}

