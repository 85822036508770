export function dirtyValuesUtil (dirtyFields, allValues) {
    // If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
    // "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields))
        return allValues;

    //this is necessary to set checkbox values to false when user dont dirty the field
    dirtyFields = {...dirtyFields, ...convertFalseValuesToDirtyFields(allValues)}
    // Here, we have an object

    return Object.fromEntries(Object.keys(dirtyFields).map(key => [key, dirtyValuesUtil(dirtyFields[key], allValues[key])]));
}

const convertFalseValuesToDirtyFields = (values) =>{
    const falseValuesObj = {}
    for(const key in values) {
        if(values[key] === false){
            falseValuesObj[key] = true
        }

    }
    return falseValuesObj
}
