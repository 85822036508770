export const formErrorValidationsConst = (type, t) => {
    const formErrors = {
        phone: { value: /^[67][0-9]{8}$/i, message: t("Number need to have 9 digits and start with 6 or 7") },
        sms: { value: 4, message: t("Need to be 4 digits") },
        extension: { value: 99, message: t("Need to be 2 digits") },
        postalCode: { value: 5, message: t("Need to be 5 digits") },
        password: { value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8}$/, message: t("Password need to have at least one lowercase, one uppercase, one special character, one number and need to be exactly 8 characters") },
    }
    return formErrors[type];
}

