/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 29/06/2023
 * @description The `UserAddress` component renders a form for managing user addresses. It utilizes React Hook Form for form management. The form includes fields for basic address data and allows submission with validation. Additionally, it supports the `readOnly` prop to control editability.
 */
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {API} from "../../queries/api";
import React, {useEffect} from "react";
import AddressForm from "../AddressForm/AddressForm";
import {parseAddress} from "../../pages/UsersDetailAddressPage/UsersDetailAddressPage";

export default function UserAddress({data, readOnly = false, onSubmit}) {

    const {
        control,
        formState: {errors},
        setValue,
        handleSubmit,
        watch
    } = useForm({
        defaultValues: parseAddress(data, true)
    });
    const {t} = useTranslation();


    const onHandleSubmit = (dataForm) => {
        onSubmit(dataForm)
    }

    const getProvinces = async () => {
       await API("data/location/province/list");
    };


    useEffect(() => {
        getProvinces();
    }, []);


    return <form onSubmit={handleSubmit(onHandleSubmit)}>
        <fieldset>
            <legend>{t("Basic data")}</legend>

            <div className={"row"}>
                <AddressForm control={control} setValue={setValue} errors={errors}
                             formData={watch()}
                             readOnly={false}/>
            </div>


        </fieldset>

        {!readOnly && <div className={"d-flex justify-content-end mt-8"}>
            <Button label={t("Save")}/>
        </div>}
    </form>
}
