/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 21/04/2023
 * @descriptionThe This component manages a list of insurances, fetching data lazily and allowing deletion of individual insurance entries. It renders an InsurancesTable component with the retrieved data and provides callbacks for lazy loading and deletion operations.
 */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createFilterParamsUtil } from "../../utils/createFilterParams.util";
import { API } from "../../queries/api";
import InsurancesTable from "../../components/InsurancesTable/InsurancesTable";
import {checkAndReturnCorrectLazyTableValues} from "../../utils/checkAndReturnCorrectLazyTableValues.util";

export default function InsurancesPage () {
    const [insurances, setInsurances] = useState([]);
    const [lazyTable, setLazyTable] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
    });

    const { t } = useTranslation();

    const onLazy = (event) => {
        const newLazyTable = checkAndReturnCorrectLazyTableValues(lazyTable, event);
        setLazyTable(newLazyTable);
        getLazyData(newLazyTable);
    };

    const getLazyData = async (event) => {
        const { data: { data: insurances, count } } = await API(
            "company/company/list" + createFilterParamsUtil(event)
        );
        setLazyTable({ ...event, totalRecords: count })
        setInsurances(insurances);
    };


    const deleteInsurance = async (id) => {
        try {
            await API.delete("company/company/delete/" + id);
            removeInsuranceById(id)

            function removeInsuranceById () {
                const index = insurances.findIndex((insurance) => insurance.id === id);
                const copyInsurances = [...insurances];
                copyInsurances.splice(index, 1)
                setInsurances(copyInsurances)
            }
        } catch (e) {
            console.error(e);
        }
    }


    useEffect(() => {
        getLazyData(lazyTable);
    }, []);

    return (
        <div>
            <div className={"b-box"}>
                <h2>
                    {t("Insurances list")} ({lazyTable.totalRecords})
                </h2>
                <InsurancesTable data={insurances} lazyTable={lazyTable} onLazy={onLazy} onDelete={deleteInsurance}/>
            </div>
        </div>
    );
}
