/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 17/05/2023
 * @description The `User` component renders a form for managing user data. It accepts props like `data`, `readOnly`, and `onSubmit`. It includes inputs for attributes like name, email, mobile, and preferences. The form adjusts based on the `readOnly` prop and handles submission with validation.
 */
import FormInput from "../FormInput/FormInput";
import {Button} from "primereact/button";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {gendersConst} from "../../consts/genders.const";
import {vipsConst} from "../../consts/vips.const";
import {dirtyValuesUtil} from "../../utils/dirtyValues.util";
import { Link } from "react-router-dom";

/**
 *
 * @param data
 * @param readOnly used to show field like input or only just show data
 * @param isNew this parameter show new form fields like password
 * @returns {JSX.Element}
 * @constructor
 */
export default function User({data, readOnly = false, onSubmit}) {
    const {t} = useTranslation();

    const onHandleSubmit = (dataForm) => {
        // if is new data just get all form data...if is editing get only dirty data
        const dataToSubmit = data.id ? dirtyValuesUtil(dirtyFields, dataForm) : dataForm
        onSubmit(dataToSubmit)
    }

    let maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 18);

    const {
        control,
        formState: {errors, dirtyFields},
        handleSubmit,
    } = useForm({
        defaultValues: {
            vip: false,
            ...data,
            dateBirth: data.dateBirth ? new Date(data.dateBirth) : new Date()
        }
    });

    return <form onSubmit={handleSubmit(onHandleSubmit)}>
        <fieldset>
            <legend>{t("User data")}</legend>

            <div className={"py-4"}>
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="nif" label={"NIF"} control={control}
                                   errors={errors}
                                   rules={{required: true}}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="gender" label={"Gender"} control={control}
                                   errors={errors}
                                   rules={{required: true}} options={gendersConst(t)} type={"select"}
                        />
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="name" label={"Name"} control={control}
                                   errors={errors}
                                   rules={{required: true}}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="firstSurname" label={"First surname"} control={control}
                                   errors={errors}
                                   rules={{required: true}}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="secondSurname" label={"Second surname"} control={control}
                                   errors={errors}
                                   rules={{required: true}}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="email" label={"Email"} control={control}
                                   errors={errors} rules={{required: true}}/>
                    </div>


                    <div className="col-4 col-lg-1">
                        <FormInput readOnly={readOnly} disabled={true} name="mobilePrefix" label={"Prefix"}
                                   control={control}
                                   type={"number"}
                                   errors={errors}/>
                    </div>
                    <div className="col-8 col-lg-3">
                        <FormInput readOnly={readOnly} name="mobile" label={"Mobile"} control={control} type={"number"}
                                   errors={errors} rules={{required: true}}/>
                    </div>

                    <div className="col-4 col-lg-1">
                        <FormInput readOnly={readOnly} disabled={true} name="phonePrefix" label={"Prefix"}
                                   control={control}
                                   type={"number"}
                                   errors={errors}/>
                    </div>
                    <div className="col-8 col-lg-3">
                        <FormInput readOnly={readOnly} name="phone" label={"Phone"} control={control} type={"number"}
                                   errors={errors}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="dateBirth" label={"Date of birth"} control={control}
                                   errors={errors} type={"calendar"}
                                   rules={{required: true, maxDate}}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="vip" label={"VIP"} control={control}
                                   errors={errors} options={vipsConst} type={"select"}
                        />
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="postalCode" label={"Postal code"} control={control}
                                   errors={errors}
                                   rules={{required: true}}
                        />
                    </div>


                    <div className="col-12">
                        <FormInput readOnly={readOnly} name="notes" label={"Notes"} control={control}
                                   errors={errors} type={"textarea"}/>
                    </div>

                </div>
            </div>
        </fieldset>

        <fieldset>
            <legend>{t("Flags")}</legend>
            <div className={"py-4 row"}>
                <div className="col-12 col-lg-4">
                    <FormInput readOnly={readOnly} name="allowCall" label={"User allow calls?"} control={control}
                               errors={errors}
                               type={"checkbox"}
                    />
                </div>

                <div className="col-12 col-lg-4">
                    <FormInput readOnly={readOnly} name="retainNotification" label={"Retain user notifications?"}
                               control={control} errors={errors}
                               type={"checkbox"}
                    />
                </div>
            </div>
        </fieldset>


        <fieldset>
            <legend>{t("Notifications")}</legend>
            <div className={"py-4 row"}>


                <div className="col-12 col-lg-4">
                    <FormInput readOnly={readOnly} name="pushPromotion" label={"User wants to receive push" +
                        " promotions?"} control={control} errors={errors}
                               type={"checkbox"}/>
                </div>

                <div className="col-12 col-lg-4">
                    <FormInput readOnly={readOnly} name="emailPromotion" label={"User wants to receive email" +
                        " promotions?"} control={control} errors={errors}
                               type={"checkbox"}/>
                </div>

                <div className="col-12 col-lg-4">
                    <FormInput readOnly={readOnly} name="phonePromotion" label={"User wants to receive phone" +
                        " promotions?"} control={control} errors={errors}
                               type={"checkbox"}/>
                </div>


                <div className="col-12 col-lg-4">
                    <FormInput readOnly={readOnly} name="pushAlert" label={"User wants to receive push" +
                        " notifications?"} control={control} errors={errors}
                               type={"checkbox"}/>
                </div>


                <div className="col-12 col-lg-4">
                    <FormInput readOnly={readOnly} name="emailAlert" label={"User wants to receive email" +
                        " notifications?"} control={control} errors={errors}
                               type={"checkbox"}/>
                </div>


            </div>
        </fieldset>


        {data?.advisor?.id && <fieldset>
            <legend>{t("User advisor")}</legend>

            <div className={"py-4"}>
                <div className="row">
                <div className="col-12 col-lg-12">
                        <Link to={"/backoffice-users/" + data?.advisor?.id}>
                            <FormInput readOnly={true} name="advisor.id" label={"ID"} control={control}
                                    errors={errors}
                                    rules={{required: true}}/>
                        </Link>
                    </div>
                    <div className="col-6 col-lg-6">
                        <FormInput readOnly={true} name="advisor.firstName" label={"Name"} control={control}
                                   errors={errors}
                                   rules={{required: true}}/>
                    </div>

                    <div className="col-6 col-lg-6">
                        <FormInput readOnly={true} name="advisor.lastName" label={"First surname"} control={control}
                                   errors={errors}
                                   rules={{required: true}}/>
                    </div>
                    
                    <div className="col-6 col-lg-6">
                        <FormInput readOnly={true} name="advisor.email" label={"Email"} control={control}
                                   errors={errors} rules={{required: true}}/>
                    </div>

                    <div className="col-6 col-lg-6">
                        <FormInput readOnly={true} name="advisor.telephone" label={"Phone"} control={control} type={"number"}
                                   errors={errors}/>
                    </div>
                </div>
            </div>
        </fieldset>}

        
        {!readOnly && <div className={"d-flex justify-content-end mt-8"}>
            <Button label={t("Save")}/>
        </div>}
    </form>

}
