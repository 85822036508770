/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 29/06/2023
 * @descriptionThe The `UsersDetailContactPage` component manages user contacts, enabling creation and editing. It interacts with user contact data based on the user and contact IDs. Upon submission, it sends a POST request to create a new contact or a PUT request to update an existing one. The component renders a user-friendly form for contact management.
 */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../queries/api";
import UserContact from "../../components/UserContact/UserContact";


export default function UsersDetailContactPage () {
    const [userContact, setUserContact] = useState();
    const { id, idContact, action } = useParams();

    const { t } = useTranslation();

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        if (idContact === "new") {
            await API.post("user/contact/create", { ...data, userId: id });
        } else {
            await API.put("user/contact/update/" + idContact, { ...data, userId: id });
        }
        navigate("/users/" +id)


    }

    const getUserContact = async (idContact) => {
        if (idContact && idContact !== "new") {
            const {
                data
            } = await API("user/contact/view/" + idContact);
            setUserContact(data);
        } else {
            setUserContact({});
        }
    };


    useEffect(() => {
        getUserContact(idContact);
    }, []);

    return (
        <>
            {userContact && <>
                <div className={"b-box"}>
                    <h2 className={"mb-8"}>
                        {idContact === "new" && t("Creation of")} {t("User contact")} {userContact.name}
                    </h2>

                    <UserContact readOnly={!(idContact === "new" || action === "edit")} data={userContact}
                                 onSubmit={onSubmit}/>
                </div>

            </>}
        </>
    );
}
