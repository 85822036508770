/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 10/05/2023
 * @descriptionThe The `InsurancesDetailContactPage` manages insurance contact details, allowing creation or editing. It fetches contact data based on URL parameters, handles form submission, and renders the `InsuranceContact` component appropriately.
 */
import InsuranceContact from "../../components/InsuranceContact/InsuranceContact";
import { API } from "../../queries/api";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function InsurancesDetailContactPage () {
    const [insuranceContact, setInsuranceContact] = useState();
    const { id, idContact, action } = useParams();
    const navigate = useNavigate();


    const onSubmit = async (data) => {
        if (idContact === "new") {
            await API.post("company/contact/create", { ...data, companyId: Number(id) });
        } else {
            await API.put("company/contact/update/" + idContact, { ...data, companyId: Number(id) });
        }
        navigate("/insurances/" + id)
    }

    const getInsuranceContact = async (idContact) => {
        if (idContact && idContact !== "new") {
            const {
                data
            } = await API("company/contact/view/" + idContact);
            setInsuranceContact(data);
        } else {
            setInsuranceContact({});
        }
    };

    useEffect(() => {
        getInsuranceContact(idContact);
    }, []);

    return <div className={"b-box"}>
        {insuranceContact &&
        <InsuranceContact readOnly={!(idContact === "new" || action === "edit")} data={insuranceContact}
                          onSubmit={onSubmit}/>}

    </div>
}
