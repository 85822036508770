/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 14/12/2024
 * @description The `TasksTable` component is responsible for displaying a table of tasks with various functionalities like pagination, filtering, sorting, and selection. Users can perform actions such as viewing, removing, and reassigning tasks. It supports custom header templates and provides options for creating new tasks or notes. Additionally, it allows administrators to reassign tasks to different users and displays a dialog for managing comments associated with tasks.
 */
import {Column} from "primereact/column";
import {Link, useNavigate} from "react-router-dom";
import {DataTable} from "primereact/datatable";
import ActionsOverlayMenu from "../ActionsOverlayMenu/ActionsOverlayMenu";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState} from "react";
import {formatDateUtil} from "../../utils/formatDate.util";
import {GlobalContext} from "../../App";
import {Dialog} from "primereact/dialog";
import FormInput from "../FormInput/FormInput";
import {useForm} from "react-hook-form";
import {API} from "../../queries/api";
import {Dropdown} from "primereact/dropdown";
import {tasksStatusesConst} from "../../consts/tasksStatuses.const";
import {proposalsTypes} from "../../consts/proposalsTypes.const";
import {Toolbar} from "primereact/toolbar";
import FilterDropdownTemplate from "../../templates/FilterDropdownTemplate";

export default function TasksTable({
                                       data,
                                       onLazy,
                                       onRefresh,
                                       lazyTable,
                                       isCreationDisabled,
                                       tableHeader,
                                       tasksActions
                                   }) {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [selectedTasks, setSelectedTasks] = useState([])
    const {user} = useContext(GlobalContext)
    const [isModalVisible, setIsModalVisible] = useState()
    const [modalComments, setModalComments] = useState([])
    const [taskTypesOptions, setTaskTypesOptions] = useState([])

    const {
        control,
        formState: {errors},
        handleSubmit,
    } = useForm();


    const doReassignTasks = async ({assignedToId}) => {
        try {
            const {data} = await API.put(
                `task/task/bulk/assign/` + assignedToId, {taskIds: selectedTasks.map(selectedTask => selectedTask.id)}
            );
            setIsModalVisible(false)
            onRefresh()
        } catch (e) {
            console.error(e)
        }
    }

    const TasksActions = (rowData) => {
        const items = [

            {
                label: t("See"),
                icon: (
                    <span className="me-4 material-symbols-outlined">visibility</span>
                ),
                command: () => navigate("./tasks/" + rowData.id),
            },
            {
                label: t("Remove"),
                icon: <span className="me-4 material-symbols-outlined">delete</span>,
                command: () => onDeleteTask(rowData.id)
            }
        ];
        return <ActionsOverlayMenu items={rowData.proposalId ? [{
            label: t("Carry out"),
            icon: (
                <span className="me-4 material-symbols-outlined">shop</span>
            ),
            command: () => navigate("/proposals/" + rowData.proposalId + (rowData.taskType ? "#" + proposalsTypes[rowData.taskType.type] : "")),
        }, ...items] : items}/>;
    };

    const onDeleteTask = async (id) => {
        await API.delete("task/task/delete/" + id);
        onLazy(lazyTable)
    }

    useEffect(() => {
        const getTaskTypes = async () => {
            const {data: taskTypes} = await API("task/task-type/list");
            setTaskTypesOptions(taskTypes.map(taskType => ({label: taskType.title, value: taskType.id})))

        }

        getTaskTypes()
    }, []);

    const descriptionTemplate = ({title, comments, policyId, claimId, proposalId, userId, taskFiles, taskType}) => {


        return <>
            {title && <div>{title}</div>}

            {comments && <Link onClick={() => setModalComments(comments)}>{t("Show comments")}</Link>}
            {policyId && <div><Link to={"/policies/" + policyId}>
                {t("See policy")}
            </Link></div>}
            {claimId && <div><Link to={"/claims/" + claimId}>
                {t("See claim")}
            </Link></div>}
            {proposalId &&
                <div><Link to={"/proposals/" + proposalId + (taskType ? "#" + proposalsTypes[taskType.type] : "")}>
                    {t("See proposal")}
                </Link></div>}
            {userId && <div><Link to={"/users/" + userId}>
                {t("See user")}
            </Link></div>}
            {taskFiles.length > 0 && taskFiles.map((file, index) => <div><Link target="_blank"
                                                                               to={process.env.REACT_APP_IMAGE_URL + file.fileLocation}>
                {t("See image")} {index + 1}
            </Link></div>)}
        </>
    }

    const tasksHeaderTemplate = tableHeader ? tableHeader : () => (
        <div className={"d-flex justify-content-end"}>
            <Link className={"ms-6"} to={"./tasks/new?type=note"}>
                <Button label={t("Create note")}></Button>
            </Link>


            <Link className={"ms-6"} to={"./tasks/new"}>
                <Button label={t("Create task")}></Button>
            </Link>
        </div>
    );

    return <><DataTable emptyMessage={t("No results found")}
                        header={isCreationDisabled ? null : tasksHeaderTemplate}
                        value={data}
                        filterDisplay="row"
                        dataKey="id"
                        paginator
                        totalRecords={lazyTable.totalRecords}
                        rows={lazyTable.rows}
                        onPage={onLazy}
                        onFilter={onLazy}
                        onSort={onLazy}
                        lazy
                        first={lazyTable.first}
                        sortOrder={lazyTable.sortOrder}
                        rowClassName={(rowData) => {
                            let className = rowData.taskType.type === "note_task" ? "p-row-disable-selection" : ""
                            return className + (rowData.status === "PENDING" ? " p-row-priority" : "")
                        }}
                        selection={selectedTasks}
                        selectionMode={'checkbox'}
                        disable
                        onSelectionChange={(e) => setSelectedTasks(e.value.filter(row => !(row.taskType && row.taskType.type === "note_task")))}
                        filters={lazyTable.filters}

    >
        {(user.rol === "SuperAdmin" || user.rol === "Administrator") && <Column selectionMode="multiple"></Column>}


        <Column
            field="id"

            filter
            filterPlaceholder={t("Search")}
            header={t("ID")}
        />


        <Column
            field="comments"
            header={t("Description")}
            body={descriptionTemplate}
        />

        <Column
            field="taskType.title"

            filter
            filterPlaceholder={t("Search")}
            filterField={"taskTypeId"}
            filterElement={(filter) => <FilterDropdownTemplate filter={filter} options={taskTypesOptions}/>}
            header={t("Type")}
        />


        <Column
            field="user.name"


            body={({user}) => <Link
                to={"/users/" + user.id}>{user.name}{user.firstSurname ? " " + user.firstSurname : ""}{user.secondSurname ? " " + user.secondSurname : ""}</Link>}
            header={t("User")}
        />

        <Column
            field="user.advisor.firstName"
            header={t("Adviser")}
            body={({assignedTo}) => assignedTo ? <Link
                to={"/users/" + assignedTo.id}>{assignedTo.firstName + " " + assignedTo.lastName}</Link> : ""}

        />

        <Column
            field="status"

            filter
            header={t("Status")}
            body={({status}) => t(status)}
            filterElement={(options) => <Dropdown value={options.value} options={tasksStatusesConst(t)}
                                                  onChange={(e) => {
                                                      options.filterApplyCallback(e.value)
                                                  }}
                                                  placeholder={t("Select")}
                                                  className="p-column-filter" showClear/>}


        />

        <Column
            field="alarmAt"
            body={({alarmAt}) => formatDateUtil(alarmAt, true, t)}
            header={t("Alarm")}
        />


        <Column
            field="createdAt"
            body={({createdAt, createdBy}) => <>
                <div>{createdBy.firstName} {createdBy.lastName}</div>
                <div>{formatDateUtil(createdAt, true, t)}</div>
            </>}
            header={t("Created at")}
        />


        <Column header={t("Actions")} style={{width: "160px"}} body={tasksActions ? tasksActions : TasksActions}/>
    </DataTable>

        {(user.rol === "SuperAdmin" || user.rol === "Administrator") &&
            <Toolbar className="mb-4" start={() => <Button label={t("Reassign tasks")}
                                                           onClick={() => setIsModalVisible(true)}></Button>}></Toolbar>
        }

        <Dialog header={t("Reassign tasks")} visible={isModalVisible}
                onHide={() => setIsModalVisible(false)}>
            <form onSubmit={handleSubmit(doReassignTasks)}>
                <FormInput name="assignedToId" label={"Admin"} control={control}
                           errors={errors} type={"search"} service={"user-bo/user"} rules={{required: true}}
                           keys={["firstName", "lastName"]}/>
                <div className={"d-flex justify-content-end"}>
                    <Button className={"mt-6"} label={t("Reassign tasks")}></Button>
                </div>
            </form>
        </Dialog>
        <Dialog header={t("Comments")} visible={!!modalComments.length}
                onHide={() => setModalComments([])}>
            {modalComments.map(modalComment => <p className={"b-box b-box--sec"}>{modalComment.text}</p>)}
        </Dialog>

    </>
}

