export const cancellationPeriodsConst = (t) => {
    return [{
        label: 30 + " " + t("Days"),
        value: 30
    },{
        label: 60 + " " + t("Days"),
        value: 60
    },{
        label: 90 + " " + t("Days"),
        value: 90
    },]
}
