/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 29/06/2023
 * @descriptionThe The `UserFile` component is responsible for managing a user file, allowing users to provide basic data such as title and data type, and upload a file associated with it. The component utilizes state to manage the file, `files`, and provides a form for users to input the title, select the data type from predefined options, and upload the file using the `FilePond` component.
 *
 * Once the form is submitted, the component handles the submission by extracting dirty values, transforming the file to base64 if necessary, and passing the submitted data to the `onSubmit` function.
 *
 * The form is designed to be responsive to the `readOnly` prop, disabling input fields and file upload when `readOnly` is `true`. Additionally, a "Save" button is displayed at the bottom of the form when not in read-only mode, allowing users to save their changes.
 */
import FormInput from "../FormInput/FormInput";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { dirtyValuesUtil } from "../../utils/dirtyValues.util";
import { fileTypesConst } from "../../consts/fileTypes.const";
import { FilePond } from "react-filepond";
import filePonUtil from "../../utils/filePond.util";
import { useState } from "react";
import { checkAndTransformDirtyFileUtil } from "../../utils/checkAndTransformDirtyFile.util";

const fileKey = "fileLocation"

export default function UserFile ({ data, readOnly = false, onSubmit }) {
    const [files, setFiles] = useState(data[fileKey])
    const {
        control,
        formState: { errors, dirtyFields },
        handleSubmit,
        watch
    } = useForm({
        defaultValues: data
    });
    const { t } = useTranslation();


    const onHandleSubmit = (dataForm) => {
        let dataToSubmit = dirtyValuesUtil(dirtyFields, dataForm)
        //if we have file we need to transform it to base64
        dataToSubmit = checkAndTransformDirtyFileUtil(dataForm[fileKey], files, dataToSubmit, fileKey);
        onSubmit(dataToSubmit)
    }

    return <form onSubmit={handleSubmit(onHandleSubmit)}>
        <fieldset>
            <legend>{t("Basic data")}</legend>

            <div className={"py-4"}>
                <div className="row">


                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="title" label={"Title"} control={control} errors={errors}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="type" label={"Data type"} control={control}
                                   errors={errors}
                                   rules={{ required: true }} options={fileTypesConst} type={"select"}
                        />
                    </div>


                    <div className={"row"}>
                        <div className="col-12">

                            <FilePond
                                {...filePonUtil("fileLocation", files, setFiles, t)}
                                allowMultiple={false}
                                maxFiles={1}
                                disabled={readOnly}
                                labelIdle={t("File")}
                                acceptedFileTypes={watch("type") === "OTHER" ? [] : ["image/jpeg", "image/png"]}

                            />
                        </div>
                    </div>


                </div>
            </div>


        </fieldset>

        {!readOnly && <div className={"d-flex justify-content-end mt-8"}>
            <Button label={t("Save")}/>
        </div>}
    </form>
}
