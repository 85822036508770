/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 08/05/2023
 * @description The `Insurance` component renders a form for insurance-related data. It consists of several fieldsets, each containing various input fields like text inputs, dropdowns, checkboxes, and file uploads. The form is populated with initial data passed through props. Users can interact with the form fields, and upon submission, the data is processed and submitted via the `onSubmit` function. If the form is not in read-only mode, a "Save" button is displayed at the bottom for submitting the form.
 */
import FormInput from "../FormInput/FormInput";
import { paymentMethodsConst } from "../../consts/paymentMethods.const";
import { mediationsConst } from "../../consts/mediations.const";
import { cancellationPeriodsConst } from "../../consts/cancellationPeriods.const";
import { prioritiesOrderConst } from "../../consts/prioritiesOrder.const";
import { Button } from "primereact/button";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { FilePond } from "react-filepond";
import filePonUtil from "../../utils/filePond.util";
import { checkAndTransformDirtyFileUtil } from "../../utils/checkAndTransformDirtyFile.util";
import { dirtyValuesUtil } from "../../utils/dirtyValues.util";

const fileKey = "logo"

/**
 *
 * @param data
 * @param readOnly used to show field like input or only just show data
 * @param onSubmit
 * @returns {JSX.Element}
 * @constructor
 */
export default function Insurance ({ data, readOnly = false, onSubmit = () => {} }) {
    const { t } = useTranslation();
    const [files, setFiles] = useState(data[fileKey])

    const {
        control,
        formState: { errors, dirtyFields },
        handleSubmit,
        watch,
    } = useForm({ defaultValues: data });
    const onHandleSubmit = (dataForm) => {
        let dataToSubmit = dirtyValuesUtil(dirtyFields, dataForm)
        //if we have file we need to transform it to base64
        dataToSubmit = checkAndTransformDirtyFileUtil(dataForm[fileKey], files, dataToSubmit, fileKey);
        onSubmit(dataToSubmit)
    }
    return <form onSubmit={handleSubmit(onHandleSubmit)}>
        <fieldset>
            <legend>{t("Basic data")}</legend>
            <div className={"py-4"}>
                <div className="row">

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="name" label={"Name"} control={control} errors={errors}
                                   rules={{ required: true }}/>
                    </div>
                    <div className="col-12 col-lg-8">
                        <FormInput readOnly={readOnly} name="zipCode" label={"Zip code"} control={control}
                                   errors={errors}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="dgsCode" label={"DGS Code"} control={control}
                                   errors={errors}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="businessName" label={"Business name"} control={control}
                                   errors={errors}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="nif" label={"NIF"} control={control} errors={errors}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="fiscalName" label={"Specialty"} control={control}
                                   errors={errors}/>
                    </div>


                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="paymentMethod" label={"Payment method"} control={control}
                                   errors={errors} type={"select"} options={paymentMethodsConst}
                                   rules={{ required: true }}/>
                    </div>


                </div>
                <div className={"row"}>
                    <div className="col-12 col-lg-4">
                        <FilePond
                            {...filePonUtil("logo", files, setFiles, t)}
                            allowMultiple={false}
                            maxFiles={1}
                            disabled={readOnly}
                            labelIdle={t("Logo") + " *"}
                            required={true}
                            acceptedFileTypes={["image/jpeg", "image/png"]}

                        />
                    </div>
                </div>
            </div>
        </fieldset>

        <fieldset>
            <legend>{t("Address")}</legend>

            <div className={"py-4"}>
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="address" label={"Address"} control={control}
                                   errors={errors}
                        />
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="description" label={"Description"} control={control}
                                   errors={errors}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="city" label={"City"} control={control} errors={errors}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="province" label={"Province"} control={control}
                                   errors={errors}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="region" label={"Region"} control={control}
                                   errors={errors}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="country" label={"Country"} control={control}
                                   errors={errors}/>
                    </div>


                </div>
            </div>
        </fieldset>

        <fieldset>
            <legend>{t("Contact data")}</legend>

            <div className={"py-4"}>
                <div className="row">
                    <div className="col-4 col-lg-1">
                        <FormInput readOnly={readOnly} disabled={true}  name="phonePrefix" label={"Prefix"} control={control}
                                   errors={errors}
                                   rules={{ required: true }} type={"number"}
                        />
                    </div>
                    <div className="col-8 col-lg-3">
                        <FormInput readOnly={readOnly} name="phone" label={"Phone"} control={control} errors={errors}
                                   rules={{ required: true }} type={"number"}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="extension" label={"Extension"} control={control}
                                   type={"number"}
                                   errors={errors}/>
                    </div>
                    <div className="col-4 col-lg-1">
                        <FormInput readOnly={readOnly} disabled={true} name="mobilePhonePrefix" label={"Prefix"}
                                   control={control}
                                   errors={errors}
                                   type={"number"}
                        />
                    </div>
                    <div className="col-8 col-lg-3">
                        <FormInput readOnly={readOnly} name="mobilePhone" label={"Mobile phone"} control={control}
                                   errors={errors} type={"number"}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="email" label={"Email"} control={control} errors={errors}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="website" label={"Website"} control={control}
                                   errors={errors}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="saleEmail" label={"Sale email"} control={control}
                                   errors={errors}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="claimEmail" label={"Claim email"} control={control}
                                   errors={errors}/>
                    </div>
                    <div className="col-4 col-lg-1">
                        <FormInput readOnly={readOnly} disabled={true} name="claimPhonePrefix" label={"Prefix"}
                                   control={control}
                                   errors={errors}
                                   type={"number"}
                        />
                    </div>
                    <div className="col-8 col-lg-3">
                        <FormInput readOnly={readOnly} name="claimPhone" label={"Claim phone"} control={control}
                                   errors={errors}                                    type={"number"}
                        />
                    </div>

                </div>
            </div>
        </fieldset>

        <fieldset>
            <legend>{t("Configuration data")}</legend>

            <div className={"py-4"}>
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="mediateMode" label={"Mediation"} control={control}
                                   errors={errors}
                                   rules={{ required: true }} options={mediationsConst(t)} type={"select"}
                        />
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="mediateCode" label={"Mediator code"} control={control}
                                   errors={errors}
                                   disabled={watch("mediateMode") !== "YES"}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="cancellationPeriod" label={"Annulation"} control={control}
                                   errors={errors}
                                   rules={{ required: true }} options={cancellationPeriodsConst(t)} type={"select"}
                        />
                    </div>
                    {/*Checkbox can't be required cause of an option is set it to false...*/}
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="mediateProposalGeneration" label={"Generate proposition" +
                        " of" +
                        " improvement" +
                        " on mediation policy"} control={control} errors={errors}
                                   disabled={watch("mediateMode") !== "YES"}
                                   type={"checkbox"}
                        />
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="visible" label={"Visible on app"} control={control}
                                   errors={errors}
                                   type={"checkbox"}
                        />
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="noMediateProposalGeneration"
                                   label={"Generate proposition of improvement on no mediation policy"}
                                   control={control} errors={errors}
                                   type={"checkbox"}
                        />
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="retainInsurerEmails" label={"Retain insurer emails"}
                                   control={control}
                                   errors={errors}
                                   type={"checkbox"}
                        />
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="ordering" label={"Order priority"} control={control}
                                   errors={errors}
                                   rules={{ required: true }} options={prioritiesOrderConst} type={"select"}
                        />
                    </div>
                </div>
            </div>
        </fieldset>

        {!readOnly && <div className={"d-flex justify-content-end mt-8"}>
            <Button label={t("Save")}/>
        </div>}
    </form>

}

