/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 29/06/2023
 * @descriptionThe The `UsersDetailDocumentPage` component manages user documents, allowing for creation and editing. It handles document data based on the user and document IDs. When submitted, it sends a POST request to create a new document or a PUT request to update an existing one. The component renders a form for managing user documents with user-friendly interactions.
 */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../queries/api";
import UserDocument from "../../components/UserDocument/UserDocument";


export default function UsersDetailDocumentPage () {
    const [userDocument, setUserDocument] = useState();
    const { id, idDocument, action } = useParams();

    const { t } = useTranslation();

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        if (idDocument === "new") {
            await API.post("user/identity-document/create", { ...data, userId: id });
        } else {
            await API.put("user/identity-document/update/" + idDocument, { ...data, userId: id });
        }
        navigate("/users/" + id)


    }

    const getUserDocument = async (idDocument) => {
        if (idDocument && idDocument !== "new") {
            const {
                data
            } = await API("user/identity-document/view/" + idDocument);
            setUserDocument(data);
        } else {
            setUserDocument({});
        }
    };


    useEffect(() => {
        getUserDocument(idDocument);
    }, []);

    return (
        <>
            {userDocument && <>
                <div className={"b-box"}>
                    <h2 className={"mb-8"}>
                        {idDocument === "new" && t("Creation of")} {t("User document")} {userDocument.name}
                    </h2>

                    <UserDocument readOnly={!(idDocument === "new" || action === "edit")} data={userDocument}
                                  onSubmit={onSubmit}/>
                </div>

            </>}
        </>
    );
}
