/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 14/12/2023
 * @description The `DetailHeader` component is a simple header component commonly used in detail views. It consists of a title and optional action buttons displayed on the right side of the header.
 *
 * - Functionality:
 *   - Renders the title of the detail view on the left side.
 *   - Renders action buttons on the right side.
 *   - The `ButtonsList` component is used internally to render the action buttons.
 *
 * - Styling:
 *   - Uses flexbox to arrange the title and buttons horizontally.
 *   - Applies specific CSS classes for styling purposes.
 *
 * This component provides a reusable and customizable way to create headers for detail views with title and action buttons.
 */
import ButtonsList from "../ButtonsList/ButtonsList";
import "./DetailHeader.scss"
export default function DetailHeader({buttons = [], title}) {
    return <div className={"c-detail-header d-flex justify-content-between"}>
        <h2 className={"c-detail-header__title"}>{title}</h2>
        <div className={"d-flex"}>
            <ButtonsList data={buttons}></ButtonsList>
        </div>
    </div>
}
