/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 10/10/2023
 * @description The `PoliciesTable` component displays a table of policies with various attributes such as ID, company, policy number, user, branch, annual premium, frequency, expiration dates, policy type, adviser, policy status, and actions. Users can view, edit, and create proposals for each policy. The table supports pagination, filtering, and sorting.
 */
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Link, useNavigate} from "react-router-dom";
import ActionsOverlayMenu from "../ActionsOverlayMenu/ActionsOverlayMenu";
import {formatDateUtil} from "../../utils/formatDate.util";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";
import {API} from "../../queries/api";
import {truncateNumUtil} from "../../utils/truncateNum.util";
import React, {useEffect, useState} from "react";
import FilterDropdownTemplate from "../../templates/FilterDropdownTemplate";
import {paymentFrequencyConst} from "../../consts/paymentFrequency.const";
import {mediateOptions} from "../../consts/mediateOptions";

export default function PoliciesTable({data, onLazy, lazyTable, isCreationDisabled}) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [branches, setBranches] = useState()
    const [companies, setCompanies] = useState()
    const PoliciesActions = (rowData) => {
        const items = [
            {
                label: t("See"),
                icon: (
                    <span className="me-4 material-symbols-outlined">visibility</span>
                ),
                command: () => navigate("/policies/" + rowData.id),
            },
            {
                label: t("Edit"),
                icon: <span className="me-4 material-symbols-outlined">edit</span>,
                command: () => navigate("/policies/" + rowData.id + "/edit"),
            },
        ];
        return <ActionsOverlayMenu items={items}/>;
    };


    const dateBodyTemplate = (rowData) => {
        return (
            <>
                <p>
                    <b>{t("First effect")}</b>: {formatDateUtil(rowData.initialEffectiveDate)}
                </p>
                <p>
                    <b>{t("Expiration")}</b>: {formatDateUtil(rowData.expirationDate)}
                </p>
            </>
        );
    };

    const policyStatusTemplate = (rowData) => {
        return (
            <>
                <p>
                    {rowData.erpPolicyStatus === "CANCELLED" ? t("Canceled") : rowData.status === "ERP_PENDING" ? t("ERP_PENDING") : new Date(rowData.expirationDate) > new Date() ? t("In effect") : t("Expired")}
                </p>
            </>
        );
    };
    const proposalTemplate = (rowData) => {
        return (
            <>
                {/*<Link to={"/policies/" + rowData.id + "/proposals/new" }>*/}
                <Button onClick={() => createProposal(rowData.id, rowData.userId, rowData.policyTypeId)}
                        icon={<span className="me-2 material-symbols-outlined">add_circle</span>} label={t("New" +
                    " proposal")}></Button>
                {/*</Link>*/}
            </>
        );
    };

    const createProposal = async (policyId, userId, policyTypeId) => {
        const {data} = await API.post("policy/proposal/create", {parentPolicyId: policyId, userId, policyTypeId});
        navigate("/policies/" + policyId + "/proposals/" + data.id + "/edit")
    }

    const policyTemplate = (rowData) => {
        return (
            <>
                <Link to={"/policies/" + rowData.id}>{rowData.policyNumber}</Link>
                <p>
                    <b>{rowData.referenceValue}</b>
                </p>
            </>
        );
    };

    const tableHeader = () => (
        <div className={"d-flex justify-content-end"}>
            <Link to={"./policies/new"}>
                <Button label={t("Create policy")}></Button>
            </Link>
        </div>
    );

    useEffect(() => {
        const getBranches = async () => {
            const {data: branches} = await API(
                "policy/policy-type/list"
            );
            setBranches(branches.map(branch => ({label: branch.name, value: branch.id})))
        }
        const getCompanies = async () => {
            const {data: companies} = await API(
                "company/company/all"
            );
            companies.sort((a, b) => a.name.localeCompare(b.name));

            setCompanies(companies.map(company => ({label: company.name, value: company.id})))
        }
        getBranches()
        getCompanies()
    }, []);


    return <DataTable
        emptyMessage={t("No results found")}
        header={isCreationDisabled ? null : tableHeader}
        value={data}
        filterDisplay="row"
        dataKey="id"
        paginator
        totalRecords={lazyTable.totalRecords}
        rows={lazyTable.rows}
        onPage={onLazy}
        onFilter={onLazy}
        onSort={onLazy}
        lazy
        first={lazyTable.first}
        sortOrder={lazyTable.sortOrder}
    >
        <Column
            field="id"

            filter
            filterPlaceholder={t("Search")}
            header={t("ID")}
        />

        <Column filter filterMatchMode="equals" filterPlaceholder={t("Search")}
                header={t("Company")}
                filterField={"companyId"}
                filterElement={(filter) => <FilterDropdownTemplate filter={filter} options={companies}/>}
                body={({company}) => company && company.logo ?
                    <img width="100" src={process.env.REACT_APP_IMAGE_URL + company.logo}
                         alt={t("Company")}/> : company ? company.name : ""}/>
        <Column
            field="policyNumber"

            filter
            filterPlaceholder={t("Search")}
            body={policyTemplate}
            header={t("Policy" + " number")}
        />
        <Column
            header={t("User")}
            body={({user}) => user ?
                <Link to={"/users/" + user.id}>{user.name} {user.firstSurname} {user.secondSurname}</Link> : ""}
        />
        <Column
            field="policyType.name"
            filterField="policyTypeId"

            filter
            filterPlaceholder={t("Search")}
            filterElement={(filter) => <FilterDropdownTemplate filter={filter} options={branches}/>}
            header={t("Branch")}
        />
        <Column
            field="annualPayment"

            filter
            filterPlaceholder={t("Search")}
            header={t("Annual premium")}
            body={({annualPayment}) => truncateNumUtil(annualPayment) + "€"}
        />
        <Column
            field="frequency"
            filterElement={(filter) => <FilterDropdownTemplate filter={filter} options={paymentFrequencyConst}/>}

            filter
            filterPlaceholder={t("Search")}
            header={t("Frequency")}
            body={({frequency}) => t(frequency)}
        />
        <Column
            field="expirationDate"
            dataType="date"
            body={dateBodyTemplate}
            header={t("Dates")}
            style={{minWidth: "180px"}}
        />


        <Column
            field="mediate"
            header={t("Policy type")}
            filterElement={(filter) => <FilterDropdownTemplate filter={filter} options={mediateOptions}/>}
            filter

            body={(({mediate}) => mediate ? t("Mediate") : t("No mediate"))}
        />
        <Column
            header={t("Adviser")}
            body={({user}) => user ? <Link
                to={"/users/" + user.id}>{user.advisor ? (user.advisor.firstName + " " + user.advisor.lastName) : ""}</Link> : ""}

        />

        <Column

            header={t("Policy status")}
            body={policyStatusTemplate}
        />
        <Column
            body={proposalTemplate}
            header={t("Proposals")}
            style={{width: '230px'}}

        />
        <Column header={t("Actions")} style={{width: "160px"}} body={PoliciesActions}/>
    </DataTable>
}
