/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 10/10/2023
 * @descriptionThe This `UserPoliciesTable` component manages policies for a specific user. It utilizes lazy loading for efficient data retrieval, supporting pagination and sorting. It fetches policies with additional user information and transforms date fields. Finally, it renders a `PoliciesTable` component with the fetched data and lazy loading functionality.
 */
import PoliciesTable from "../PoliciesTable/PoliciesTable";
import { useEffect, useState } from "react";
import { API } from "../../queries/api";
import { createFilterParamsUtil } from "../../utils/createFilterParams.util";
import {getUserInfoBy} from "../../pages/ProposalsPage/ProposalsPage";
import {checkAndReturnCorrectLazyTableValues} from "../../utils/checkAndReturnCorrectLazyTableValues.util";

export default function UserPoliciesTable ({ userId }) {
    const [policies, setPolicies] = useState([])

    const [lazyTable, setLazyTable] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
    });

    const onLazy = (event) => {
        const newLazyTable = checkAndReturnCorrectLazyTableValues(lazyTable, event);
        setLazyTable(newLazyTable);
        getLazyData(newLazyTable);
    };

    const getLazyData = async (event) => {
        const { data: { data: policies, count } } = await API(
            "policy/policy/list" + createFilterParamsUtil(event) + "&extraFields=policyType,proposals,company&filter[userId]=" + userId
        );
        setLazyTable({ ...event, totalRecords: count })

        const policiesWithUsers = await getUserInfoBy(policies, "extraFields=advisor")
        setPolicies(policiesWithUsers.map((d) => ({
            ...d,
            effectiveDate: new Date(d.effectiveDate),
            expirationDate: new Date(d.expirationDate),
            initialEffectiveDate: new Date(d.initialEffectiveDate),
        })));
    };

    useEffect(() => {
        getLazyData(lazyTable);
    }, []);


    return <PoliciesTable data={policies} onLazy={onLazy} lazyTable={lazyTable}/>

}
