export const referencesConst = [
    {
        label: "SECURED",
        value: "SECURED"
    },   {
        label: "COLLECTIVE",
        value: "COLLECTIVE"
    },  {
        label: "ADDRESS",
        value: "ADDRESS"
    },  {
        label: "COMPANY",
        value: "COMPANY"
    },  {
        label: "VEHICLE_NAMEPLATE",
        value: "VEHICLE_NAMEPLATE"
    },  {
        label: "MODEL",
        value: "MODEL"
    },  {
        label: "RISK",
        value: "RISK"
    },
]
