export const checkAndTransformDirtyFileUtil = (fileUrlName, filePondFiles, toTransformData, key, toTransformDataKey = "uploadFiles", isArray = false) => {
    if (!fileUrlName || (fileUrlName && filePondFiles && checkDirtyFile(fileUrlName, filePondFiles))) {
        toTransformData = transformFileToBase64Util(toTransformData, key, filePondFiles, toTransformDataKey, isArray)
        delete toTransformData[key]
    }
    return toTransformData;
}

export const checkDirtyFile = (fileUrlName, filePondFiles) => {
    if (fileUrlName && typeof fileUrlName === "string" && filePondFiles) {
        let filePondName = filePondFiles[0].filename;
        const filename = fileUrlName.substring(fileUrlName.lastIndexOf("/"), fileUrlName.length)

        return filePondName !== filename
    } else {
        return false;
    }
}

export const transformFileToBase64Util = (toTransformData, key, filePondFiles, toTransformDataKey, isArray) => {
    if (toTransformData && filePondFiles && filePondFiles[0]) {
        if (isArray) {
            const newDataList = []
            for (const filePondFile of filePondFiles) {
                newDataList.push({ [key]: filePondFile.getFileEncodeDataURL()})
                toTransformData[toTransformDataKey] = newDataList
            }
        } else {
            toTransformData[toTransformDataKey] = {
                ...toTransformData[toTransformDataKey],
                [key]: filePondFiles[0].getFileEncodeDataURL()
            }
            // toTransformData[toTransformDataKey] = isArray ? [newData] : newData
            // delete toTransformData[key];
        }

    }
    return toTransformData;
}
