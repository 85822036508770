/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 17/05/2023
 * @descriptionThe The `UsersDetailPage` component is responsible for managing user details, allowing both creation and editing. It retrieves user data based on the provided ID and renders a form for user information. Upon submission, it sends a POST request to create a new user or a PUT request to update an existing one. Additionally, it displays user-related information in separate tabs, such as policies, proposals, contacts, addresses, files, documents, and history.
 */
import {useTranslation} from "react-i18next";
import User from "../../components/User/User";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {API} from "../../queries/api";
import {TabPanel, TabView} from "primereact/tabview";
import UserContactsTable from "../../components/UserContactsTable/UserContactsTable";
import UserAddressesTable from "../../components/UserAddressesTable/UserAddressesTable";
import UserFilesTable from "../../components/UserFilesTable/UserFilesTable";
import UserDocumentsTable from "../../components/UserDocumentsTable/UserDocumentsTable";
import UserPoliciesTable from "../../components/UserPoliciesTable/UserPoliciesTable";
import UserProposalsTable from "../../components/UserProposalsTable/UserProposalsTable";
import UserTasksTable from "../../components/UserTasksTable/UserTasksTable";
import {hashFeature} from "../../utils/primereactTab.util";

export default function UsersDetailPage() {
    const {t} = useTranslation();
    const [user, setUser] = useState()
    const {id, action} = useParams();
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState()

    const onSubmit = async (data) => {
        if (id === "new") {
            await API.post("user/user/create", data);
        } else {
            await API.put("user/user/update/" + id, data);
        }
        navigate("/users")
    }


    const getUser = async (id) => {
        if (id && id !== "new") {
            const {
                data: user
            } = await API("user/user/view/" + id + "?extraFields=advisor");
            setUser(user);
        } else {
            setUser({mobilePrefix: 34, phonePrefix: 34});
        }
    };


    useEffect(() => {
        getUser(id);
    }, []);

    return <>
        {user && <>

            <div className={"b-box"}>
                <h2 className={"mb-8"}>
                    {t("User")} {id !== "new" && (user.name ? user.name : "") + " " + (user.firstSurname ? user.firstSurname : "") + " " + (user.secondSurname ? user.secondSurname : "")}
                </h2>

                <User data={user} readOnly={!(id === "new" || action === "edit")} onSubmit={onSubmit}/>
            </div>

            {id !== "new" && !action && <div className="mt-8 b-box">
                <TabView  {...hashFeature(activeIndex, setActiveIndex)}>
                    <TabPanel header={t("Policies")}>
                        <UserPoliciesTable userId={user.id}/>
                    </TabPanel>
                    <TabPanel header={t("Proposals")}>
                        <UserProposalsTable userId={user.id}/>
                    </TabPanel>

                    <TabPanel header={t("Contacts")}>
                        <UserContactsTable userId={user.id}/>
                    </TabPanel>

                    <TabPanel header={t("Addresses")}>
                        <UserAddressesTable userId={user.id}/>
                    </TabPanel>

                    <TabPanel header={t("Files")}>
                        <UserFilesTable userId={user.id}/>
                    </TabPanel>

                    <TabPanel header={t("Documents")}>
                        <UserDocumentsTable userId={user.id}/>
                    </TabPanel>


                    <TabPanel header={t("History")}>
                        <UserTasksTable user={user}></UserTasksTable>
                    </TabPanel>

                </TabView>
            </div>}
        </>}
    </>
}
