/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 29/09/2023
 * @description The `ToastContent` component is a simple functional component used to display the content of a toast notification. It accepts two props: `title` and `text`, which are optional. If provided, the `title` prop renders as a heading element (`h5`), and the `text` prop renders as a paragraph element (`p`). The component ensures that if either prop is present, it will be displayed in the toast notification.
 */
import "./ToastContent.scss";

export default function ToastContent({title, text}){
    return <div>
        {title && <h5 className={"m-0 p-0 b-text b-text--toast-title"}>{title}</h5>}
        {text && <p className={"b-text b-text--toast"}>{text}</p>}
    </div>
}
