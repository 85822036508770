export const hashFeature = (activeIndex, setActiveIndex) => {
    const finalActiveIndex = activeIndex ? activeIndex : Number(window.location.hash.substring(1))
    return {
        activeIndex: finalActiveIndex ? finalActiveIndex : 0,
        onTabChange: function (e) {
            setActiveIndex(e.index)
            window.location.hash = e.index
        }
    }
}
