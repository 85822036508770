/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 10/05/2023
 * @description This component `InsuranceContactsTable` is responsible for displaying a table of contacts related to an insurance company. Here's a breakdown of its functionality:
 *
 * 1. **State Management**: It maintains state for `contacts`, which holds the list of contacts fetched from the API, and `lazyTable`, which manages pagination and sorting for the table.
 *
 * 2. **Data Fetching**: It fetches the list of contacts related to the insurance company using the `getLazyData` function. This function is called initially when the component mounts and also whenever there is a change in pagination, filtering, or sorting.
 *
 * 3. **Table Display**: It utilizes the `DataTable` component from PrimeReact to display the list of contacts in a tabular format. Columns include fields like name, contact area, department, position, email, phone, mobile phone, fax, and URL. Each column supports filtering and sorting.
 *
 * 4. **Actions**: For each contact row, it provides actions like "See", "Edit", and "Remove". These actions allow users to navigate to view or edit the contact details, as well as delete the contact.
 *
 * 5. **Table Header**: It includes a button to create a new contact. Clicking on this button navigates the user to the page for creating a new contact.
 *
 * Overall, this component provides a comprehensive interface for managing contacts associated with an insurance company, including viewing, editing, and deleting contacts.
 */
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {useTranslation} from "react-i18next";
import ActionsOverlayMenu from "../ActionsOverlayMenu/ActionsOverlayMenu";
import {Link, useNavigate} from "react-router-dom";
import {Button} from "primereact/button";
import {useEffect, useState} from "react";
import {API} from "../../queries/api";
import {createFilterParamsUtil} from "../../utils/createFilterParams.util";
import {deleteTableRowUtil} from "../../utils/deleteTableRow.util";
import {checkAndReturnCorrectLazyTableValues} from "../../utils/checkAndReturnCorrectLazyTableValues.util";

export default function InsuranceContactsTable ({ insuranceId }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [contacts, setContacts] = useState([])

    const [lazyTable, setLazyTable] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
    });

    const onLazy = (event) => {
        const newLazyTable = checkAndReturnCorrectLazyTableValues(lazyTable, event);
        setLazyTable(newLazyTable);
        getLazyData(newLazyTable);
    };

    const getLazyData = async (event) => {
        const { data: { data: contacts, count } } = await API(
            "company/contact/list/" + createFilterParamsUtil(event) + "&filter[companyId]=" + insuranceId
        );
        setLazyTable({ ...event, totalRecords: count })
        setContacts(contacts.map((d) => ({
            ...d,
            effectiveDate: new Date(d.effectiveDate),
            expirationDate: new Date(d.expirationDate),
        })));
    };

    useEffect(() => {
        getLazyData(lazyTable);
    }, []);


    const InsuranceContactsActions = (rowData) => {
        const items = [
            {
                label: t("See"),
                icon: <span className="me-4 material-symbols-outlined">delete</span>,
                command: () =>  navigate("./contacts/" + rowData.id)
            },
            {
                label: t("Edit"),
                icon: <span className="me-4 material-symbols-outlined">edit</span>,
                command: () => navigate("./contacts/" + rowData.id + "/edit")
            }, {
                label: t("Remove"),
                icon: <span className="me-4 material-symbols-outlined">delete</span>,
                command: () => deleteTableRowUtil(rowData.id, "company/contact/delete/" + rowData.id, contacts, setContacts),
            }
        ];
        return <ActionsOverlayMenu items={items}/>
    }


    const tableHeader = () => <div className={"d-flex justify-content-end"}>
        <Link to={"./contacts/new"}>
            <Button label={t("Create contact")}></Button>
        </Link>
    </div>

    return <div>
        <DataTable
            emptyMessage={t("No results found")}
            header={tableHeader}
            value={contacts}
            filterDisplay="row"
            dataKey="id"
            paginator
            totalRecords={lazyTable.totalRecords}
            rows={lazyTable.rows}
            onPage={onLazy}
            onFilter={onLazy}
            onSort={onLazy}
            lazy
            first={lazyTable.first}
            sortOrder={lazyTable.sortOrder}>
            <Column field="firstName"  filter filterPlaceholder={t("Search")}
                    header={t("Name")} body={(rowData) => rowData.firstName + " " + rowData.lastName}/>
            <Column field="contactArea"  filter filterPlaceholder={t("Search")}
                    header={t("Type")} body={({ contactArea }) => t(contactArea)}/>
            <Column field="department"  filter filterPlaceholder={t("Search")}
                    header={t("Department")} />
            <Column field="position"  filter filterPlaceholder={t("Search")}
                    header={t("Position")}/>
            <Column field="email"  filter filterPlaceholder={t("Search")}
                    header={t("Email")}/>
            <Column field="phone"  filter filterPlaceholder={t("Search")}
                    header={t("Phone")}/>
            <Column field="mobilePhone"  filter filterPlaceholder={t("Search")}
                    header={t("Mobile phone")}
            />
            <Column field="fax"  filter filterPlaceholder={t("Search")}
                    header={t("Fax")}/>
            <Column field="url"  filter filterPlaceholder={t("Search")}
                    header={t("Url")}/>


            <Column header={t("Actions")} body={InsuranceContactsActions}/>
        </DataTable>
    </div>
}
