/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 18/01/2024
 * @description The `ClaimTasksTable` component displays a table of tasks associated with a specific claim. It retrieves the tasks data using pagination and filtering options. Users can refresh the data and navigate through the pages of tasks.
 */
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {API} from "../../queries/api";
import {createFilterParamsUtil} from "../../utils/createFilterParams.util";
import TasksTable from "../../components/TasksTable/TasksTable";
import {getUserInfoBy} from "../../pages/ProposalsPage/ProposalsPage";
import {checkAndReturnCorrectLazyTableValues} from "../../utils/checkAndReturnCorrectLazyTableValues.util";

export default function ClaimTasksTable({claim}) {
    const [tasks, setTasks] = useState([]);
    const [lazyTable, setLazyTable] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
    });

    const {t} = useTranslation();

    const getLazyData = async (event) => {
        const {data: {data: tasks, count}} = await API(
            "task/task/list" + createFilterParamsUtil(event) + "&filter[claimId]=" + claim.id + "&extraFields=taskType,taskFiles,comments,assignedTo,createdBy"
        );
        setLazyTable({...event, totalRecords: count})


        const tasksWithUsers = await getUserInfoBy(tasks, "extraFields=advisor")
        setTasks(tasksWithUsers.map((d) => ({
            ...d,
            alarmAt: d.alarmAt ? new Date(d.alarmAt) : t("No date"),
            createdAt: d.createdAt ? new Date(d.createdAt) : t("No date"),
        })));
    };
    const onLazy = (event) => {
        const newLazyTable = checkAndReturnCorrectLazyTableValues(lazyTable, event);
        setLazyTable(newLazyTable);
        getLazyData(newLazyTable);
    };


    useEffect(() => {
        getLazyData(lazyTable);
    }, []);
    return (
        <div>

            <TasksTable data={tasks} onRefresh={() => getLazyData(lazyTable)} onLazy={onLazy} lazyTable={lazyTable}
                        isCreationDisabled={false}/>
        </div>
    );
}
