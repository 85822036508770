/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 24/10/2023
 * @description `DynamicData` dynamically renders form fields based on policy type & vehicle data. It manages context for vehicle details, fetches fields & options, sets default values, & updates fields. It optimizes data collection for varied scenarios.
 */
import React, {useCallback, useEffect, useState} from "react";
import {API} from "../../queries/api";
import DynamicForm from "../DynamicForm/DynamicForm";

let typeOfVehicle;
let fields;
export const VehicleContext = React.createContext(null);
export default function DynamicData({policyTypeId, readOnly, control, errors, formData, type = "RISK", setValue}) {
    // const [fields, setFields] = useState();
    const [finalFields, setFinalFields] = useState();
    const [vehicle, setVehicle] = useState();

    const getCarModels = useCallback(async () => {
        if (fields && vehicle && typeOfVehicle) {
            if (formData["engine"] && vehicle[typeOfVehicle + "_brand"] && vehicle[typeOfVehicle + "_model"]) {
                const capitalizeEngine = formData["engine"].charAt(0).toUpperCase() + formData["engine"].slice(1);
                const {data} = await API(
                    `data/${typeOfVehicle}/list/${vehicle[typeOfVehicle + "_brand"]}/${vehicle[typeOfVehicle + "_model"]}/${capitalizeEngine}`
                );
                const copyFields = [...fields];

                const index = copyFields.findIndex(field => field.type === typeOfVehicle + "_version")

                //map is necessary for FormInput type select structure. This will be modify on this component html too
                copyFields[index] = {
                    ...copyFields[index],
                    options: data.map(item => ({value: item.name, key: item.code}))
                }
                setFinalFields(copyFields)

            }
        }
    }, [formData["engine"], vehicle])

    useEffect(() => {
        async function getForm() {

            if (policyTypeId) {
                const {data} = await API(`policy/policy-type/field/list/${policyTypeId}/${type}?extraFields=options,relations,relations.rules`)
                typeOfVehicle = knowTypeVehicle(data)
                fields = data
                if(typeOfVehicle){
                    await getCarModels()
                } else{
                    setVehicle({});
                }

                createDefaultValues(data, formData, setValue, setVehicle, typeOfVehicle)
                setFinalFields(fields)
            }
        }

        const knowTypeVehicle = (fields) => {
            const car = fields.find(item => item.type === "car_model")
            if (car) {
                return "car"
            } else {
                const motorbike = fields.find(item => item.type === "motorbike_model")
                return motorbike ? "motorbike" : car ? car : null
            }
        }

        getForm();

    }, [policyTypeId])

    useEffect(() => {getCarModels()}

        , [formData["engine"], vehicle])
    return <div>
        {/*Is required to wait vehicle cause of  edition. We need to create default vehicle data to collect codescopic data with defaults data in VehicleBrandSearch component*/}
        {finalFields && vehicle && <VehicleContext.Provider value={{vehicle, setVehicle, engine: formData["engine"]}}>
            <div className={"row"}>
                <DynamicForm fields={finalFields} control={control} errors={errors} formData={formData}
                             readOnly={readOnly} setValue={setValue}/>
            </div>
        </VehicleContext.Provider>}
    </div>
}


// we need to create defaultValue for boolean type to insert like false
const createDefaultValues = (fieldsList, formData, setValue, setVehicle, typeOfVehicle) => {
        let defaultVehicle = {};
        for (const field of fieldsList) {
            if (field.type === "boolean" && !formData[field.key]) {
                setValue(field.key, false)
            } else if (field.type === typeOfVehicle + "_brand" || field.type === typeOfVehicle + "_model" || field.type === typeOfVehicle + "_version") {
                defaultVehicle[field.type] = formData[field.key]
            }
        }

        if(Object.keys(defaultVehicle).length !== 0){
            setVehicle(defaultVehicle)
        }

}
