export const vipsConst = [
     {
        label: "You are not VIP",
        value: false
    },
    {
        label: "VIP",
        value: true
    }
]
