/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 29/06/2023
 * @description The `UserAddressesTable` component displays a table of user addresses. It fetches user addresses based on the provided `userId` prop and provides options to edit or delete each address. It uses DataTable from PrimeReact for table rendering and supports pagination and filtering. Additionally, it includes a button to create a new user address.
 */
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";
import ActionsOverlayMenu from "../ActionsOverlayMenu/ActionsOverlayMenu";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { API } from "../../queries/api";
import { useEffect, useState } from "react";
import { BodyTemplateCheckbox } from "../BodyTemplateCheckbox/BodyTemplateCheckbox";

export default function UserAddressesTable ({userId}) {
    const [userAddresses, setUserAddresses] = useState([])

    const { t } = useTranslation();
    const navigate = useNavigate();


    const deleteUserAddresses = async (id) => {
        try {
            await API.delete("user/address/delete/" + id);
            localRemove(id)

            function localRemove () {
                const index = userAddresses.findIndex((userContact) => userContact.id === id);
                const copyUserContacts = [...userAddresses];
                copyUserContacts.splice(index, 1)
                setUserAddresses(copyUserContacts)
            }
        } catch (e) {
            console.error(e);
        }
    }

    const getUserAddresses = async () => {
        const {
            data: { data }
        } = await API("user/address/list?filter[userId]=" +userId);
        setUserAddresses(data);

    };


    const UserAddressesTable = (rowData) => {
        const items = [

            {
                label: t("Edit"),
                icon: <span className="me-4 material-symbols-outlined">edit</span>,
                command: () => navigate("./addresses/" + rowData.id + "/edit")

            }, {
                label: t("Remove"),
                icon: <span className="me-4 material-symbols-outlined">delete</span>,
                command: () => deleteUserAddresses(rowData.id),
            }
        ];
        return <ActionsOverlayMenu items={items}/>
    }

    const tableHeader = () => <div className={"d-flex justify-content-end"}>
        <Link to={"./addresses/new"}>
            <Button label={t("Create user address")}></Button>
        </Link>
    </div>

    useEffect(() => {
        getUserAddresses()
    }, []);

    return <div>
        <DataTable emptyMessage={t("No results found")} header={tableHeader} value={userAddresses} filterDisplay="row"
                   dataKey="id" paginator rows={20}>

            <Column field="id"  filter filterPlaceholder={t("Search")}
                    header={t("ID")}/>
            <Column field="title"  filter filterPlaceholder={t("Search")}
                    header={t("Title")}/>
            <Column field="streetType"  filter filterPlaceholder={t("Search")}
                    header={t("Street type")} body={rowData => t(rowData.type)}/>

            <Column field="street"  filter filterPlaceholder={t("Search")}
                    header={t("Address")}/>
            <Column field="buildNumber"  filter filterPlaceholder={t("Search")}
                    header={t("Number")}/>
            <Column field="floor"  filter filterPlaceholder={t("Search")}
                    header={t("Floor")}/>
            <Column field="door"  filter filterPlaceholder={t("Search")}
                    header={t("Door")}/>
            <Column field="city"  filter filterPlaceholder={t("Search")}
                    header={t("Municipality")}/>
            <Column field="province"  filter filterPlaceholder={t("Search")}
                    header={t("Province")}/>
            <Column field="postalCode"  filter filterPlaceholder={t("Search")}
                    header={t("Postal code")}/>

            <Column field="postalCode"  filter filterPlaceholder={t("Search")}
                    header={t("Postal code")}/>

            <Column header={t("Active")} body={(rowData) => <BodyTemplateCheckbox isOk={rowData.active}/>}/>

            <Column field="comment"  filter filterPlaceholder={t("Search")}
                    header={t("Additional data")}/>

            <Column header={t("Actions")} body={UserAddressesTable}/>
        </DataTable>
    </div>
}
