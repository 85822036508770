/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 11/10/2023
 * @description The `DynamicForm` component generates form fields dynamically based on given `fields` data, handling conditions for disabled, required, and blocked fields. It organizes fields into sections and renders them accordingly, optimizing user interaction and data entry.
 */

import FormInput from "../FormInput/FormInput";
import { formTypesConst } from "../../consts/formTypes.const";
import React from "react";
import AddressForm from "../AddressForm/AddressForm";

export default  function DynamicForm({fields, control, errors, formData, readOnly, setValue}){
    return fields && fields.map((field, index) =>  {
        let htmlToPrint = !checkBlocks(field, formData) ? <React.Fragment key={index}>
            {field.type !== "address" && <div  className={"col-12 col-lg-4"}><FormInput readOnly={readOnly}
                       control={control}
                       errors={errors}
                       name={field.key}
                       type={formTypesConst[field.type]}
                       options={field.options ? field.options.map(option => ({
                           label: option.value,
                           value: option.key
                       })) : null}
                       disabled={checkDisabled(field.key, formData)}
                       label={field.name}
                       rules={checkRequired(field, formData)}
            /></div>}

            {field.type === "address" && field.key === "address" &&
                <AddressForm control={control} setValue={setValue} errors={errors} formData={formData} readOnly={readOnly}/>}
        </React.Fragment>: <></>;
        if(index === 0 || field.section !== fields[index - 1].section){
            htmlToPrint = <><h3  key={index} className={"mb-0 mt-6"}>{field.section}</h3>{htmlToPrint}</>
        }

        return htmlToPrint
    })
}


const checkDisabled = (fieldKey, formData) => {
    if (fieldKey === "model" && !formData["brand"]) {
        return true;
    } else if (fieldKey === "engine" && (!formData["brand"] || !formData["model"])) {
        return true;
    } else if (fieldKey === "version" && (!formData["brand"] || !formData["model"] || !formData["engine"])) {
        return true;
    } else {
        return false
    }
}

const checkRequired = ({ required, validation, relations, key, type }, formData) => {
    return required ? validation ? {
        required: formTypesConst[type] !== "checkbox",
        pattern: new RegExp(validation)
    } : { required: formTypesConst[type] !== "checkbox" } : checkRelations(key, relations, formData)
}

const checkRelations = (key, relations, formData) => {
    if (relations) {
        let isRequired;
        for (const relation of relations) {
            isRequired = true;
            //only have effect with 2 or more rules
            for (const rule of relation.rules) {
                const regex = new RegExp(rule.fieldValue);

                if ((relation.rulesType === "AND" && (!formData[rule.fieldKey] || formData[rule.fieldKey] === "" || (rule.fieldRegexp && !regex.test(formData[rule.fieldKey]))))) {
                    isRequired = false;
                    break;
                }
                if (relation.rulesType === "OR") {
                    if ((formData[rule.fieldKey] && formData[rule.fieldKey] !== "" && (!rule.fieldRegexp || (rule.fieldRegexp && regex.test(formData[rule.fieldKey]))))) {
                        isRequired = true;
                        break;
                    } else {
                        isRequired = false;
                    }
                }

            }
        }
        return { required: isRequired }
    } else {
        return {}
    }
}


const checkBlocks = ({key, relations}, formData) => {
    if (relations) {
        let isBlocked;
        for (const relation of relations) {
            if (relation.relationType === "blocks") {

                isBlocked = true;
                //only have effect with 2 or more rules
                for (const rule of relation.rules) {
                    const regex = new RegExp(rule.fieldValue);
                    if ((relation.rulesType === "AND" && ((!rule.fieldRegexp && formData[rule.fieldKey] !== rule.fieldValue) || (rule.fieldRegexp && !regex.test(formData[rule.fieldKey]))))) {
                        isBlocked = false;
                        break;
                    }
                    if (relation.rulesType === "OR") {
                        if (((!rule.fieldRegexp && formData[rule.fieldKey] && formData[rule.fieldKey] === rule.fieldValue) || ((rule.fieldRegexp && regex.test(formData[rule.fieldKey]))))) {
                            isBlocked = true;
                            break;
                        } else {
                            isBlocked = false;
                        }
                    }
                }

            }
        }
        return isBlocked
    } else {
        return false
    }
}
