/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 11/10/2023
 * @descriptionThe The `ClaimsDetailPage` component manages the details of a claim, including the associated policy, user, and company. It allows users to create or edit claims. The page provides functionality to submit and update claims, as well as view claim history and associated tasks. The user can navigate to the policy associated with the claim.
 */
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {API} from "../../queries/api";
import Claim from "../../components/Claim/Claim";
import {transformDynamicData} from "../../utils/transformDynamicDataUtil";
import ClaimActions from "../../components/ClaimActions/ClaimActions";
import {TabPanel, TabView} from "primereact/tabview";
import ClaimTasksTable from "../../components/ClaimTasksTable/ClaimTasksTable";
import {hashFeature} from "../../utils/primereactTab.util";


export default function ClaimsDetailPage() {
    const [claim, setClaim] = useState()
    const [policy, setPolicy] = useState()
    const [company, setCompany] = useState()
    const [policyUser, setPolicyUser] = useState()
    const {t} = useTranslation();
    const {idClaim, id, action, userId} = useParams();
    const [activeIndex, setActiveIndex] = useState()

    const navigate = useNavigate();

    const onSubmit = async (data, dataRiskForm, policyTypeId) => {
        if (idClaim === "new") {
            const {data: claim} = await API.post("policy/claim/create", {...data, policyId: policy.id});
            await saveRiskForm(claim.id, dataRiskForm);
            navigate("/claims" )

        } else {
            const {data: claim} = await API.put("policy/claim/update/" + idClaim, data);
            await saveRiskForm(claim.id, dataRiskForm);
            navigate("/claims")

        }

    }


    const getClaim = async (id) => {
        if (id && isFinite(id)) {
            const {
                data: claim
            } = await API("policy/claim/view/" + id + "?extraFields=risks");
            setClaim(claim);
        } else {
            setClaim({claim});
        }
    };


    const getPolicy = async (id) => {
        if (id && isFinite(id)) {
            const {
                data: policy
            } = await API("policy/policy/view/" + id + "?extraFields=policyType");
            setPolicy(policy);
            await getUser(policy.userId)
            await getCompany(policy.companyId)
        } else {
            setPolicy(policy ? policy : {});
            await getUser(policy ? policy.userId: null)
            await getCompany(policy ? policy.companyId: null)
        }


    };

    const getUser = async (userId) => {
        if (userId) {
            const {data} = await API(`user/user/view/${userId}`)
            setPolicyUser(data)
        } else {
            setPolicyUser({})
        }
    }
    const getCompany = async (companyId) => {
        if (companyId) {
            const {data} = await API(`policy/company/view/${companyId}`)
            setCompany(data)
        } else {
            setCompany({})
        }
    }

    const saveRiskForm = async (claimId, dataRiskForm) => {
        const transformedDynamicData = transformDynamicData(dataRiskForm)
        if (Object.keys(transformedDynamicData).length !== 0) {
            await API.put(`policy/claim/risk/update/${claimId}`, transformedDynamicData);
        }
    }
    const goToPolicy = () => {
        navigate("/policies/" + policy.id)
    }


    useEffect(() => {
        getClaim(idClaim);
        getPolicy(id);
    }, []);

    return <>
        {claim && policy && policyUser && company && <>

            <div className={"b-box"}>
                <h2 className={"mb-8"}>{idClaim === "new" && t("Creation of")} {t("Claim")} {idClaim !== "new" && "(" + claim.id + ")"}</h2>

                {idClaim !== "new" && <ClaimActions claimId={claim.id} status={claim.status} onSubmit={goToPolicy}/>}


                <Claim data={claim} policy={policy} readOnly={!(idClaim === "new" || action === "edit")}
                       isNew={idClaim === "new"} policyUser={policyUser} company={company}
                       onSubmit={onSubmit}
                />

            </div>

            {id !== "new" && !action  && policyUser && <div className="mt-8 b-box">

                <TabView {...hashFeature(activeIndex, setActiveIndex)}>

                    <TabPanel header={t("History")}>
                        <ClaimTasksTable claim={claim}></ClaimTasksTable>
                    </TabPanel>

                </TabView>
            </div>}

        </>
        }
    </>
}

