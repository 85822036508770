/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 28/06/2023
 * @description The `InlineSearch` component provides an inline search functionality, fetching data from a service based on user input. It supports displaying search results and selecting items. Internally, it utilizes the `CommonSearch` component for rendering the search input and results list. The `createShowingText` function generates the text to display based on the selected item's properties.
 */
import { useEffect, useState } from "react";
import { API } from "../../queries/api";
import CommonSearch from "../CommonSearch/CommonSearch";

export default function InlineSearch ({ value, service, id, onChange, readOnly, disabled, className, label, keys = ["name"], rules, searchKey }) {
    const [showingText, setShowingText] = useState("");
    const [showingList, setShowingList] = useState([]);
    const onSearch = async (e) => {
        const targetValue = e.target.value;
        setShowingText(targetValue)
        const { data: { data } } = await API(
            `${service}/list?likeFilter[${searchKey}]=` + targetValue
        );
        setShowingList(data)
    }

    const selectItem = (item) => {
        onChange(typeof item.id ===  'number' ? item.id.toString(): item.id); // cause of codescopic all data need to
        // be string
        setShowingText(createShowingText(item, keys))
        setShowingList([])
    }



    useEffect(() => {
        async function getDetail () {
            if (value) {
                const { data } = await API(
                    `${service}/view/${value}`
                );
                setShowingText(createShowingText(data, keys))
            }
        }

        getDetail();
    }, [])

    return <CommonSearch showingList={showingList} onSearch={onSearch} label={label} showingText={showingText} selectItem={selectItem}
                         className={className} disabled={disabled} id={id} readOnly={readOnly} keys={keys} rules={rules}/>

}
export const createShowingText = (item, keys) => {
    return keys.reduce((textToShow, key) => textToShow === "" ? item[key] ? item[key] : "": textToShow + (item[key] ? " " + item[key] : ""), "")
}
