/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 29/06/2023
 * @descriptionThe The `UserContactsTable` component displays a table of user contacts, including their ID, type, data value, description, and actions. Users can see, edit, or remove individual contacts. The table supports pagination, filtering, and searching. Additionally, users can create new contacts through a button in the table header. The component utilizes state management to fetch and update user contacts asynchronously.
 */
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";
import ActionsOverlayMenu from "../ActionsOverlayMenu/ActionsOverlayMenu";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { API } from "../../queries/api";
import { useEffect, useState } from "react";

export default function UserContactsTable ({userId}) {
    const [userContacts, setUserContacts] = useState([])

    const { t } = useTranslation();
    const navigate = useNavigate();


    const deleteUserContact = async (id) => {
        try {
            await API.delete("user/contact/delete/" + id);
            localRemove(id)

            function localRemove () {
                const index = userContacts.findIndex((userContact) => userContact.id === id);
                const copyUserContacts = [...userContacts];
                copyUserContacts.splice(index, 1)
                setUserContacts(copyUserContacts)
            }
        } catch (e) {
            console.error(e);
        }
    }

    const getUserContacts = async () => {
        const {
            data: { data }
        } = await API("user/contact/list?filter[userId]=" +userId);
        setUserContacts(data);

    };


    const InsuranceContactsActions = (rowData) => {
        const items = [
            {
                label: t("See"),
                icon: <span className="me-4 material-symbols-outlined">visibility</span>,
                command: () => navigate("./contacts/" + rowData.id)

            },
            {
                label: t("Edit"),
                icon: <span className="me-4 material-symbols-outlined">edit</span>,
                command: () => navigate("./contacts/" + rowData.id + "/edit")

            }, {
                label: t("Remove"),
                icon: <span className="me-4 material-symbols-outlined">delete</span>,
                command: () => deleteUserContact(rowData.id),
            }
        ];
        return <ActionsOverlayMenu items={items}/>
    }

    const tableHeader = () => <div className={"d-flex justify-content-end"}>
        <Link to={"./contacts/new"}>
            <Button label={t("Create user contact")}></Button>
        </Link>
    </div>

    useEffect(() => {
        getUserContacts()
    }, []);

    return <div>
        <DataTable  emptyMessage={t("No results found")} header={tableHeader} value={userContacts} filterDisplay="row" dataKey="id" paginator rows={20}>

            <Column field="id"  filter filterPlaceholder={t("Search")}
                    header={t("ID")}/>
            <Column field="type"  filter filterPlaceholder={t("Search")}
                    header={t("Type")} body={rowData => t(rowData.type)}/>
            <Column field="value"  filter filterPlaceholder={t("Search")}
                    header={t("data")}/>
            <Column field="description"  filter filterPlaceholder={t("Search")}
                    header={t("Description")}/>
            <Column header={t("Actions")} body={InsuranceContactsActions}/>
        </DataTable>
    </div>
}
