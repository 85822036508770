import {API} from "../queries/api";

export const deleteTableRowUtil = async (id, url, list, setList) => {
    try {
        await API.delete(url);
        localRemove(id)

        function localRemove() {
            const index = list.findIndex((item) => item.id === id);
            const copyList = [...list];
            copyList.splice(index, 1)
            setList(copyList)
        }
    } catch (e) {
        console.error(e);
    }
}
