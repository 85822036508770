/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 11/10/2023
 * @description The `ClaimActionsClose` component offers a form to specify details for closing a claim. Upon submission, it sends a PUT request to update the claim status to "CLOSED" and triggers the `onSubmit` callback.
 */
import { useForm } from "react-hook-form";
import FormInput from "../FormInput/FormInput";
import { useTranslation } from "react-i18next";
import { closeClaimConst } from "../../consts/closeClaim.const";
import { API } from "../../queries/api";
import { dirtyValuesUtil } from "../../utils/dirtyValues.util";
import { Button } from "primereact/button";

export default function ClaimActionsClose ({onSubmit, claimId}) {
    const { t } = useTranslation();

    const {
        control,
        formState: { errors, dirtyFields },
        handleSubmit,
    } = useForm();

    const doSubmit = async (dataForm) => {
        let dataToSubmit = dirtyValuesUtil(dirtyFields, dataForm)
        await API.put(`policy/claim/update/${claimId}`, {...dataToSubmit, status: "CLOSED"})

        onSubmit()
    }

    return <form onSubmit={handleSubmit(doSubmit)}>
        <fieldset>
            <legend className={"u-hide-accessibility"}>{t("Close claim")}</legend>

            <div className={"py-4"}>
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <FormInput name="closeDate" label={"Close date"}
                                   control={control}
                                   type={"calendar"}
                                   errors={errors} rules={{ required: true }}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput name="closeReason" label={"Reason"}
                                   control={control}
                                   type={"select"} options={closeClaimConst}
                                   errors={errors} rules={{ required: true }}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput name="paymentAmount" label={"Payment amount"}
                                   control={control}
                                   type={"number"}
                                   errors={errors} rules={{ required: true }}/>
                    </div>


                    <div className="col-12">
                        <FormInput name="comment" label={"Observations"}
                                   control={control}
                                   type={"textarea"}
                                   errors={errors} rules={{ required: true }}/>
                    </div>

                </div>
            </div>
        </fieldset>

        <div className={"d-flex justify-content-end"}>
            <Button label={t("Close")}/>
        </div>

    </form>
}
