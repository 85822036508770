/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 28/06/2023
 * @descriptionThe The `BackofficeUsersDetailPage` component handles the creation and editing of backoffice users. It retrieves user data based on the `id` parameter from the URL and determines whether the user is being created or edited. Upon submission, it sends a POST request to create a new user or a PUT request to update an existing user. The component renders a form for user details, allowing users to input or modify information.
 */
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {API} from "../../queries/api";
import BackofficeUser from "../../components/BackofficeUser/BackofficeUser";

export default function BackofficeUsersDetailPage() {
    const {t} = useTranslation();
    const [user, setUser] = useState();
    const {id, action} = useParams();
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        data = {...data, brokerage: 1}
        if (id === "new") {
            await API.post("user-bo/user/create", data);
        } else {
            await API.put("user-bo/user/update/" + id, data);
        }
        navigate("/backoffice-users")
    }


    const getBackofficeUser = async (id) => {
        if (id && id !== "new") {
            const {
                data: user
            } = await API("user-bo/user/view/" + id);
            setUser(user);
        } else {
            setUser({});
        }
    };


    useEffect(() => {
        getBackofficeUser(id);
    }, []);


    return (<div className={"b-box"}>
        {user && <>
            <h2 className={"mb-8"}>
                {t("Backoffice user")} {id !== "new" && user.firstName + " " + user.lastName}
            </h2>
            <BackofficeUser data={user} readOnly={!(id === "new" || action === "edit")} onSubmit={onSubmit}/>
        </>}
    </div>)
}
