/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 16/05/2023
 * @description The `InsuranceCustomerService` component is used for editing customer service information for insurance. It allows users to select a branch, enter an email address, and input a phone number with a prefix. The component fetches policy types from an API and populates the branch select input. Users can submit changes, and it supports validation for required fields and phone number format.
 */
import FormInput from "../FormInput/FormInput";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {API} from "../../queries/api";
import {dirtyValuesUtil} from "../../utils/dirtyValues.util";
import {formErrorValidationsConst} from "../../consts/formErrorValidations.const";

export default function InsuranceCustomerService({data, readOnly = false, onSubmit}) {
    const {
        control,
        formState: {errors, dirtyFields},
        handleSubmit,
    } = useForm(data && Object.keys(data).length !== 0? {defaultValues: {...data, policyTypeId: data.policyTypeId.toString()}}: {});
    const {t} = useTranslation();
    const [policyTypes, setPolicyTypes] = useState()

    useEffect(() => {
        async function getPolicyTypes() {
            try {
                const {data} = await API("policy/policy-type/list");
                setPolicyTypes(data.map(item => ({label: item.name, value: item.id.toString()})))
            } catch (e) {
                console.log(e)
            }
        }

        getPolicyTypes();
    }, []);

    const onHandleSubmit = (dataForm) => {
        let dataToSubmit = {...dirtyValuesUtil(dirtyFields, dataForm), phonePrefix: 34}
        //if we have file we need to transform it to base64
        onSubmit(dataToSubmit.policyTypeId ? {
            ...dataToSubmit,
            policyTypeId: Number(dataToSubmit.policyTypeId)
        } : dataToSubmit)

    }

    return <form onSubmit={handleSubmit(onHandleSubmit)}>
        <fieldset>
            <legend>{t("Customer service edition")}</legend>

            <div className={"py-4"}>
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <FormInput readOnly={readOnly} name="policyTypeId" label={"Branch"} control={control}
                                   errors={errors}
                                   rules={{required: true}} options={policyTypes} type={"select"}
                        />
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="email" label={"Email"} control={control}
                                   errors={errors} rules={{required: true}}/>
                    </div>


                    <div className="col-4 col-lg-3">
                        <FormInput readOnly={readOnly} disabled={true} name="phonePrefix" label={"Prefix"}
                                   control={control}
                                   errors={errors} type={"number"}
                        />
                    </div>
                    <div className="col-8 col-lg-5">
                        <FormInput readOnly={readOnly} name="phone" label={"Phone"} control={control} type={"number"}
                                   errors={errors}
                                   rules={{required: true, pattern: formErrorValidationsConst("phone", t)}}/>
                    </div>


                </div>
            </div>


        </fieldset>

        {!readOnly &&<div className={"d-flex justify-content-end mt-8"}>
            <Button label={t("Save")}/>
        </div>}
    </form>
}
