/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 03/01/2024
 * @description The `ProposalBudgetsEmissionForm` component manages the emission form for proposal budgets. It handles form submission, file uploads, and dynamic billing details. Users can enter policy numbers, select emission dates, and upload PDF files. The component calculates fractional premiums based on billing frequency and updates annual prices accordingly.
 */
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {FilePondImageEditor} from "../FilePondImageEditor/FilePondImageEditor";
import FormInput from "../FormInput/FormInput";
import {Button} from "primereact/button";
import {dirtyValuesUtil} from "../../utils/dirtyValues.util";
import {checkAndTransformDirtyFileUtil} from "../../utils/checkAndTransformDirtyFile.util";
import {API} from "../../queries/api";
import {toast} from "react-toastify";
import ToastContent from "../ToastContent/ToastContent";
import {paymentFrequencyConst} from "../../consts/paymentFrequency.const";
import {InputNumber} from "primereact/inputnumber";
import {useTranslation} from "react-i18next";

const fileKey = "fileLocation"

export default function ProposalBudgetsEmissionForm({proposal, setProposal, readOnly}) {
    const [files, setFiles] = useState()
    const [annualPrice, setAnnualPrice] = useState()

    const {t} = useTranslation()

    const {
        control,
        formState: {errors, dirtyFields},
        handleSubmit,
        watch,
        setValue
    } = useForm();

    const onHandleSubmit = async (dataForm) => {
        let dataToSubmit = dirtyValuesUtil(dirtyFields, dataForm)
        //if we have file we need to transform it to base64
        dataToSubmit = checkAndTransformDirtyFileUtil(dataForm[fileKey], files, dataToSubmit, fileKey, "uploadFiles", false);
        await onSubmitEmissionForm(dataToSubmit)
    }

    const onSubmitEmissionForm = async (dataForm) => {
        try {
            delete dataForm.firstBill; // is not stored on backend
            delete dataForm.otherBills; // is not stored on backend
            await API.post(`policy/policy/create`, {
                ...dataForm,
                annualPayment: annualPrice,
                mediate:true,
                proposalId: proposal.id,
                paymentMethod: "BANK"
            });
            setProposal({...proposal, status: "FINISHED"})
            toast.info(<ToastContent title={t("Policy created")}
            />)
        } catch (e) {
            console.error(e)
        }

    }

    useEffect(() => {
        const frequency = watch("frequency");
        const firstBill = watch("firstBill");
        const otherBills = watch("otherBills");
        if (frequency) {
            const fractional = paymentFrequencyConst.find(paymentFrequency => paymentFrequency.value === frequency).fractional;
            if (fractional === 1 && firstBill) {
                setAnnualPrice(firstBill)
            } else if (firstBill && otherBills) {
                setAnnualPrice((otherBills * fractional) + firstBill)
            } else {
                setAnnualPrice(null)
            }
        }
    }, [watch("otherBills"), watch("firstBill"), watch("frequency")])

    useEffect(() => {
        const getPolicyFilteredByProposal = async () => {
            const {data: {data}} = await API("policy/policy/list?filter[proposalId]=" + proposal.id);
            const policy = data[0];
            setFiles(policy.fileLocation)
            setValue("frequency", policy.frequency)
            setValue("policyNumber", policy.policyNumber)
            setValue("expirationDate", new Date(policy.expirationDate))
            setAnnualPrice(policy.annualPayment)
        }

        getPolicyFilteredByProposal()
    }, []);

    return <form onSubmit={handleSubmit(onHandleSubmit)}>
        <fieldset>
            <legend>{t("Bills")}</legend>

            <div className={"py-4"}>
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="policyNumber" label={"Policy Number"} control={control}
                                   errors={errors}
                                   rules={{required: true}}/>
                    </div>
                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="expirationDate" label={"Emission date"} control={control}
                                   errors={errors} type={"calendar"}
                                   rules={{required: true}}/>
                    </div>

                    <div className="col-12 col-lg-4">
                        <FormInput readOnly={readOnly} name="frequency" label={"Frequency"} control={control}
                                   errors={errors}
                                   rules={{required: true}} type={"select"} options={paymentFrequencyConst}/>
                    </div>


                    <div className="col-12 col-lg-4">
                        <span className="p-float-label">
                             <InputNumber value={annualPrice}
                                          disabled={true}
                                          readOnly={readOnly}
                                          useGrouping={false}/>
                             <label>{t("Fractional premium")}</label>
                        </span>

                    </div>
                    {!readOnly && <>
                        <div className="col-12 col-lg-4">
                            <FormInput readOnly={readOnly} name="firstBill" label={"First bill"}
                                       control={control} errors={errors} type={"number"}
                                       rules={{required: true}}/>
                        </div>

                        {(watch("frequency") !== "ANNUAL" && watch("frequency") !== "UNIQUE") &&
                            <div className="col-12 col-lg-4">
                                <FormInput readOnly={readOnly} name="otherBills" label={"Other bills"}
                                           control={control} errors={errors} type={"number"}
                                           rules={{required: true}}/>
                            </div>}
                    </>}


                </div>
            </div>
        </fieldset>

        <fieldset>
            <legend>{t("Attach PDF")}</legend>

            <div className={"py-4"}>
                <div className="row">
                    <div className="col-12">
                        <FilePondImageEditor files={files} setFiles={setFiles} fileKey={fileKey} readOnly={readOnly}
                                             acceptedFileTypes={["application/pdf"]}/>
                    </div>
                </div>
            </div>
        </fieldset>


        {!readOnly && <div className={"d-flex justify-content-end mt-8"}>
            <Button label={t("Save")}/>
        </div>}
    </form>
}
