/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 11/10/2023
 * @description The `PolicyClaimsTable` component is responsible for displaying a table of claims associated with a specific insurance policy. It fetches claim data based on the policy ID and renders it in a paginated table format. Users can view claim details such as claim number, effective date, expiration date, and status. The component includes functionality to fetch data lazily, ensuring efficient handling of large datasets. Additionally, it passes down information about the policy, insurance, and user associated with the claims to the `ClaimsTable` component for further processing and display.
 */
import {useEffect, useState} from "react";
import {API} from "../../queries/api";
import {createFilterParamsUtil} from "../../utils/createFilterParams.util";
import ClaimsTable from "../ClaimsTable/ClaimsTable";
import {checkAndReturnCorrectLazyTableValues} from "../../utils/checkAndReturnCorrectLazyTableValues.util";

export default function PolicyClaimsTable({policy, policyInsurance, policyUser}) {
    const [claims, setClaims] = useState([])

    const [lazyTable, setLazyTable] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
    });

    const onLazy = (event) => {
        const newLazyTable = checkAndReturnCorrectLazyTableValues(lazyTable, event);
        setLazyTable(newLazyTable);
        getLazyData(newLazyTable);
    };

    const getLazyData = async (event) => {
        const {data: {data: claims, count}} = await API(
            "policy/claim/list" + createFilterParamsUtil(event) + "&filter[policyId]=" + policy.id
        );
        setLazyTable({...event, totalRecords: count})
        setClaims(claims.map((d) => ({
            ...d,
            effectiveDate: new Date(d.effectiveDate),
            expirationDate: new Date(d.expirationDate),
        })));
    };

    useEffect(() => {
        getLazyData(lazyTable);
    }, []);


    return <ClaimsTable data={claims} policy={policy}  onLazy={onLazy} lazyTable={lazyTable}
                        isCreationDisabled={false} policyInsurance={policyInsurance} policyUser={policyUser}/>

}
