/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 04/01/2024
 * @description The `BillsTable` component renders a PrimeReact DataTable of bills with columns for ID, concept, dates, type, payment channel, status, premiums, commissions, and charging frequency. It supports filtering, sorting, pagination, and new policy creation.
 */
import {Column} from "primereact/column";
import {Link} from "react-router-dom";
import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";
import React from "react";
import {paymentFrequencyConst} from "../../consts/paymentFrequency.const";
import {formatDateUtil} from "../../utils/formatDate.util";

export default function BillsTable({data, policy, onLazy, lazyTable, isCreationDisabled, tableHeader}) {
    const {t} = useTranslation();


    const proposalTableHeader = tableHeader ? tableHeader : () => (
        <div className={"d-flex justify-content-end"}>
            <Link to={"./proposals/new"}>
                <Button label={t("Create policy")}></Button>
            </Link>
        </div>
    );

    return <DataTable emptyMessage={t("No results found")}
                      header={isCreationDisabled ? null : proposalTableHeader}
                      value={data}
                      filterDisplay="row"
                      dataKey="id"
                      paginator
                      totalRecords={lazyTable.totalRecords}
                      rows={lazyTable.rows}
                      onPage={onLazy}
                      onFilter={onLazy}
                      onSort={onLazy}
                      lazy
                      first={lazyTable.first}
                      sortOrder={lazyTable.sortOrder}
    >
        <Column
            field="id"

            filter
            filterPlaceholder={t("Search")}
            header={t("ID")}
        />

        <Column
            field="number"

            filter
            filterPlaceholder={t("Search")}
            header={t("Concept")}
        />

        <Column
            field="date.effectiveDate"

            filter
            filterPlaceholder={t("Search")}
            header={t("Effect date")}/>

        <Column

            header={t("Final date")}
            body={({date, other: {paymentMethod}}) => {
                var splittedDate = date.effectiveDate.split('/');
                const effectiveDate = Date.parse(`${splittedDate[1]}/${splittedDate[0]}/${splittedDate[2]}`);
                const paymentFrequency = paymentFrequencyConst.find(paymentFrequency => paymentFrequency.alt === paymentMethod);
                return paymentFrequency ? formatDateUtil(new Date(effectiveDate + (31556952000 / paymentFrequency.fractional))) : t("No date");
            }}
        />

        <Column

            header={t("Type")}
            body={({date}) => {
                const policyCreatedDateParsed = Date.parse(policy.createdAt)
                const policyExpirationDateParsed = Date.parse(policy.expirationDate)
                var splittedDate = date.effectiveDate.split('/');
                const billDateParsed = Date.parse(`${splittedDate[1]}/${splittedDate[0]}/${splittedDate[2]}`);
                return billDateParsed >= policyCreatedDateParsed && billDateParsed <= policyExpirationDateParsed ? t("Production") : t("Wallet");
            }}
        />


        <Column
            field="other.state"

            filter
            filterPlaceholder={t("Search")}
            body={(rowData) => rowData.other && rowData.other.state === t("Canceled") ? "" : rowData.other.state && rowData.other.state.includes("Correduria") ? t("Brokerage") : t("Company")}
            header={t("Payment channel")}/>


        <Column
            field="other.state"

            filter
            filterPlaceholder={t("Search")}
            header={t("Status")}/>


        <Column

            filter
            filterPlaceholder={t("Search")}
            body={(rowData) => rowData.amount.bill.total + "€"}
            header={t("Total premium")}/>


        <Column

            filter
            filterPlaceholder={t("Search")}
            body={(rowData) => rowData.amount.bill.netPrice + "€"}
            header={t("Net premium")}/>


        <Column
            field="amount.broker.commision"

            filter
            filterPlaceholder={t("Search")}
            body={(rowData) => ((rowData && rowData.amount && rowData.amount.broker && rowData.amount.broker.commission ? rowData.amount.broker.commission : 0) + "€")}
            header={t("Commisions")}/>

        <Column
            field="other.paymentMethod"

            filter
            filterPlaceholder={t("Search")}
            header={t("Charging frequency")}/>


    </DataTable>
}

