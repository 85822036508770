/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 20/09/2023
 * @descriptionThe This component represents a page for creating or editing proposals. It retrieves the proposal details based on the provided ID and displays them along with associated actions and tabs for different sections of the proposal. Depending on the proposal's status, certain actions may be restricted. The page includes functionality for canceling proposals, editing proposals (if available), and recording tasks related to the proposal. Additionally, it displays error messages if there are any errors associated with the proposal.
 */
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TabPanel, TabView} from "primereact/tabview";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {API} from "../../queries/api";
import Proposal from "../../components/Proposal/Proposal";
import {transformDynamicData} from "../../utils/transformDynamicDataUtil";
import ProposalObjectiveStudy from "../../components/ProposalObjectiveStudy/ProposalObjectiveStudy";
import ProposalBudgetsTable from "../../components/ProposalBudgetsTable/ProposalBudgetsTable";
import ProposalBudgetsOffersTable from "../../components/ProposalBudgetsOffersTable/ProposalBudgetsOffersTable";
import {Message} from "primereact/message";
import DetailHeader from "../../components/DetailHeader/DetailHeader";
import ProposalBudgetsHireForm from "../../components/ProposalBudgetsHireForm/ProposalBudgetsHireForm";
import ProposalBudgetsEmissionForm from "../../components/ProposalBudgetsEmissionForm/ProposalBudgetsEmissionForm";
import ProposalTasksTable from "../../components/ProposalTasksTable/ProposalTasksTable";
import {confirmDialog} from "primereact/confirmdialog";
import {hashFeature} from "../../utils/primereactTab.util";
import {Dialog} from "primereact/dialog";
import PostponeAlarm from "../../components/PostponeAlarm/PostponeAlarm";
import {toast} from "react-toastify";
import ToastContent from "../../components/ToastContent/ToastContent";

const statusWithAvailableEdit = ["NEW", "EXPORTING_ERROR", "EXPORTING_FAILED"]


export default function ProposalsDetailPage() {
    const [proposal, setProposal] = useState()
    const [activeIndex, setActiveIndex] = useState()
    const [policy, setPolicy] = useState() //not always. Only if you create a proposal from a policy
    const {t} = useTranslation();
    const {id: idMutant, idProposal, action} = useParams();
    const id = idProposal ? idProposal : idMutant
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const policyTypeId = searchParams.get('policyTypeId');
    const taskId = searchParams.get('taskId');
    const [selectedDialog, setSelectedDialog] = useState("")

    const onSubmit = async (data) => {
        if (id === "new") {
            const {data: savedProposal} = await API.post(`policy/proposal/create`, {
                policyTypeId: policyTypeId ? Number(policyTypeId) : policy.policyTypeId,
                parentPolicyId: policy.id,
                userId: policyTypeId ? idMutant : policy.userId
            }); //policy User
            await API.put(`policy/proposal/risk/update/${savedProposal.policyTypeId}/${savedProposal.id}`, transformDynamicData(data));

        } else {
            await API.put(`policy/proposal/risk/update/${proposal.policyTypeId}/${proposal.id}`, transformDynamicData(data));

        }
        navigate("/proposals")
    }


    const getProposal = async (id) => {
        if (id && isFinite(id)) {
            const {
                data: proposal
            } = await API("policy/proposal/view/" + id + "?extraFields=risks,proposalFiles,policyType,proposalErrors");
            setProposal(proposal);
        } else {
            setProposal({});
        }
    };

    const getPolicy = async (id) => {
        const isUsersUrl = location.pathname.substring(0, 6) === "/users"
        if (isUsersUrl) {
            setPolicy({});
        } else {
            if (policyTypeId) {
                const {
                    data: policy
                } = await API("policy/policy/view/" + id);
                setPolicy(policy);
            }
        }

    };

    const cancelProposal = async () => {
        try {
            confirmDialog({
                message: t('You are going to cancel this proposal. Continue?'),
                acceptLabel: t("Confirm"),
                rejectLabel: t("Cancel"),
                accept: async () => {
                    await API.post(`policy/proposal/cancel/${proposal.id}`);

                },
            });
        } catch (e) {
            console.error(e)
        }
    }

    const onFinish = async (data) => {
        try {

            await API.put("task/task/update/" + taskId, {...data, status: "CLOSED"});
            navigate("/proposals/" + id + "#0")
            setSelectedDialog("")
            toast.info(<ToastContent title={t("Task updated successfully")}/>)

        } catch (e) {
            console.error(e)
        }
    }

    const buttonsHeader =  [
        {
            text: t("Cancel"),
            onClick: cancelProposal,
            iconName: "block",
        }
    ]
    if((id !== "new" && action !== "edit")){
        buttonsHeader.push( {
            text: t("Edit"),
            onClick: () => navigate(`edit`),
            iconName: "edit",
        })
    }
    const buttonsHeaderTask = taskId ? [{
        text: t("Record"),
        onClick: () => setSelectedDialog("finish"),
        iconName: "save",
    }] : []

    useEffect(() => {
        getProposal(id);
        getPolicy(idMutant)
    }, []);
    return <>
        {proposal && <>

            <div className={"b-box"}>
                <DetailHeader
                    title={<>{id === "new" && t("Creation of")} {t("Proposal")} {proposal.proposalNumber} {id !== "new" && "(" + proposal.id + ")"}</>}
                    buttons={[...buttonsHeaderTask, ...buttonsHeader]}
                />


                {proposal.status !== undefined && !statusWithAvailableEdit.includes(proposal.status) &&
                    <Message text={t("You cannot edit this proposal cause of his status.") + t(proposal.status)}/>}

                {proposal.proposalErrors && proposal.proposalErrors.map((error, index) => <div
                    className={"d-inline-block ms-3 mb-3"} key={index}><Message severity="error"
                                                                                text={error.message}/></div>)
                }

                <Proposal data={proposal}
                          readOnly={(!(id === "new" || action === "edit") || (id !== "new" && action !== "edit")) || (proposal.status !== undefined && !statusWithAvailableEdit.includes(proposal.status))}
                          isNew={id === "new"}
                          onSubmit={onSubmit}
                />


            </div>

            {id !== "new" && !action && <div className="mt-8 b-box">

                <TabView  {...hashFeature(activeIndex, setActiveIndex)}>
                    <TabPanel header={t("Objective study")}
                              className={proposal.status === "STUDY_PENDING" ? "p-attention" : ""}>
                        <ProposalObjectiveStudy readOnly={proposal.status !== "STUDY_PENDING"}
                                                data={proposal.proposalFiles && {
                                                    ...proposal.proposalFiles[0],
                                                    comment: proposal.comment
                                                }}
                                                proposal={proposal} setProposal={setProposal}/>
                    </TabPanel>
                    <TabPanel header={t("Budgets")}
                              className={proposal.status === "OFFER_PENDING" ? "p-attention" : ""}>
                        <ProposalBudgetsTable proposal={proposal}/>
                    </TabPanel>
                    <TabPanel header={t("Offers")}
                              className={proposal.status === "OFFER_PENDING" || proposal.status === "USER_PENDING" ? "p-attention" : ""}>

                        <ProposalBudgetsOffersTable proposal={proposal} setProposal={setProposal}
                        />
                    </TabPanel>

                    <TabPanel header={t("Hire data")}
                              className={proposal.status === "ACCEPTED" || proposal.status === "REJECTED" || proposal.status === "CANCELED" ? "p-attention" : ""}>

                        <ProposalBudgetsHireForm data={{}} proposal={proposal}
                                                 readOnly={proposal.status !== "ACCEPTED" && proposal.status !== "REJECTED" && proposal.status !== "CANCELED"}
                                                 setProposal={setProposal}/>
                    </TabPanel>

                    <TabPanel header={t("Emission")}
                              className={proposal.status === "POLICY_PENDING" ? "p-attention" : ""}>

                        <ProposalBudgetsEmissionForm proposal={proposal} setProposal={setProposal}
                                                     readOnly={proposal.status !== "POLICY_PENDING"}/>
                    </TabPanel>

                    <TabPanel header={t("History")}>
                        <ProposalTasksTable proposal={proposal}></ProposalTasksTable>
                    </TabPanel>

                </TabView>
            </div>}

            <Dialog header={t("Record task")}
                    visible={selectedDialog !== ""}
                    onHide={() => setSelectedDialog("")}>
                <PostponeAlarm
                    onSubmit={(fData) => onFinish({text: fData.text})}
                    type={selectedDialog}/>
            </Dialog>


        </>
        }
    </>
}

