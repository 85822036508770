export const prioritiesOrderConst = [{
    label: "Very high",
    value: 1
}, {
    label: "High",
    value: 2
}, {
    label: "Medium",
    value: 3
}, {
    label: "Low",
    value: 4
}, {
    label: "Very low",
    value: 5
}, {
    label: "Complementary",
    value: 6
}]

