/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 04/07/2023
 * @descriptionThe The `UserFilesTable` component displays user files, allowing deletion and viewing details. It fetches files by user ID, supports pagination, and provides a search filter. It's internationalized and utilizes `react-i18next`.
 */
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {useTranslation} from "react-i18next";
import ActionsOverlayMenu from "../ActionsOverlayMenu/ActionsOverlayMenu";
import {Link, useNavigate} from "react-router-dom";
import {Button} from "primereact/button";
import {API} from "../../queries/api";
import {useEffect, useState} from "react";

export default function UserFilesTable({userId}) {
    const [userFiles, setUserFiles] = useState([])

    const {t} = useTranslation();
    const navigate = useNavigate();


    const deleteUserFile = async (id) => {
        try {
            await API.delete("user/file/delete/" + id);
            localRemove(id)

            function localRemove() {
                const index = userFiles.findIndex((userContact) => userContact.id === id);
                const copyUserFiles = [...userFiles];
                copyUserFiles.splice(index, 1)
                setUserFiles(copyUserFiles)
            }
        } catch (e) {
            console.error(e);
        }
    }

    const getUserFiles = async () => {
        const {
            data: {data}
        } = await API("user/file/list?filter[userId]=" + userId);
        setUserFiles(data);

    };


    const InsuranceContactsActions = (rowData) => {
        const items = [
            {
                label: t("See"),
                icon: <span className="me-4 material-symbols-outlined">visibility</span>,
                command: () => navigate("./files/" + rowData.id)

            },
            {
                label: t("Remove"),
                icon: <span className="me-4 material-symbols-outlined">delete</span>,
                command: () => deleteUserFile(rowData.id),
            }
        ];
        return <ActionsOverlayMenu items={items}/>
    }

    const tableHeader = () => <div className={"d-flex justify-content-end"}>
        <Link to={"./files/new"}>
            <Button label={t("Create user file")}></Button>
        </Link>
    </div>

    useEffect(() => {
        getUserFiles()
    }, []);

    return <div>
        <DataTable emptyMessage={t("No results found")} header={tableHeader} value={userFiles} filterDisplay="row"
                   dataKey="id" paginator rows={20}>

            <Column field="id" filter filterPlaceholder={t("Search")}
                    header={t("ID")}/>
            <Column field="title" filter filterPlaceholder={t("Search")}
                    header={t("Title")}/>
            <Column field="type" filter filterPlaceholder={t("Search")}
                    header={t("Type")} body={rowData => t(rowData.type)}/>

            <Column header={t("Actions")} body={InsuranceContactsActions}/>
        </DataTable>
    </div>
}
